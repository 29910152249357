import React from "react";
import { Box, Stack, Text, Button } from "@chakra-ui/react";

interface IProps {
	items: Array<{
		label: string;
		value: string | string[];
	}>;
}

export const DetailsRowMultiple: React.FC<IProps> = (props) => {
	const { items } = props;
	return (
		<>
			{items.map((item)=>(
				<Box w="100%" key={item.label} >
					{!Array.isArray(item.value) ?
						(
							<Stack spacing={1}>
								<Text fontSize="md" fontWeight="bold">{item.label}</Text>
								<Text
									fontSize="md"
									bg="gray.100"
									color="gray.500"
									p={4}
									borderRadius="lg"
								>
									{item.value}
								</Text>
							</Stack>
						) : (
							<Stack spacing={1}>
								<Text fontSize="md" fontWeight="bold">{item.label}</Text>
								<Box>
									{Array.isArray(item.value) && item.value.map((name) => (
										<Button
											m={1}
											fontWeight="initial"
											key={name}
											cursor="initial"
										>
											{name}
										</Button>
									))}
								</Box>
							</Stack>
						)}
				</Box>
			))}
		</>
	);
};
