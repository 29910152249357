import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Professional> {

	public loader = new LoaderShelf();
	public totalProfessionals = new AttributeShelf(0);

	constructor() {
		super();
		this.fetchPage(0);
		this.getTotalProfessionals();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Professional[]> {
		return api.getAllProfessionals(page);
	}

	public getTotalProfessionals = async() => {
		try {
			const professionals = await api.getTotalNumberOfUsers();
			this.totalProfessionals.setValue(professionals);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public deleteProfessional = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedProfessional = await api.deleteProfessional(id);

			showSuccessToast(strings.professionals.table.delete(deletedProfessional.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public changeProfessionalBlockStatus = async (id: string, blockedAt: Date | null) => {
		this.loader.tryStart();
		try {
			if (blockedAt) {
				await api.changeProfessionalBlockStatus(id, false);
				showSuccessToast(strings.professionals.table.statusProfessional(false));
			} else {
				await api.changeProfessionalBlockStatus(id, true);
				showSuccessToast(strings.professionals.table.statusProfessional(true));
			}
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
