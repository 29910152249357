import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Text,
	Box,
	FlexProps,
} from "@chakra-ui/react";

interface IProps extends FlexProps {
	total: number;
	text: string;
}

export const TotalInfo: React.FC<IProps> = observer((props) => {
	const {
		total,
		text,
		...rest
	} = props;

	return (
		<Flex
			alignSelf="center"
			maxW={{ base:"100%", md:"75%" }}
			mb={5}
			{...rest}
		>
			<Box
				w={{ base: "100%", md: "initial" }}
				p={3}
				bgColor="gray.200"
				color="gray.800"
				borderWidth={1}
				borderColor="primary.500"
				rounded="lg"
				boxShadow="rgba(0, 0, 0, 0.35) 0px 2px 6px"
			>
				<Text fontSize="lg" textAlign="center" >{`${total} ${text}`}</Text>
			</Box>
		</Flex>
	);
});
