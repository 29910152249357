import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button, Text, useDisclosure,
} from "@chakra-ui/react";
import {
	AutoCompleteModal,
	CentralizedCard,
	MoneyInput,
	TextInput,
} from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import Store from "./store";
import { useDebounce } from "~/hooks/useDebounce";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {

	const pageStrings = strings.clinicPackages.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { authStore } = useGlobalStore();

	const doesNotHaveBankAccount = authStore.currentAdminUser?.clinic && !authStore.currentAdminUser.clinic.bankAccount;

	const history = useHistory();
	const debounce = useDebounce();
	const store = useLocalObservable(() => new Store(id));

	const onSuccess = () => {
		history.goBack();
	};

	const onSubmit = () => {
		store.onCreateOrEditClinicPackage(onSuccess);
	};

	const onGoToClinicProfile = () => {
		if (authStore.currentAdminUser) {
			history.push(`/dashboard/clinicProfile/${authStore.currentAdminUser.clinic?.id}`);
		}
	};

	React.useEffect(() => {
		if (authStore.currentAdminUser && authStore.currentAdminUser.clinic){
			store.clinicId.setValue(authStore.currentAdminUser.clinic.id);
		}
	}, [authStore.currentAdminUser]);

	return (
		<>
			<CentralizedCard
				title={{
					text: pageStrings.title(id),
				}}
				button={(
					<Button
						w="100%"
						maxW={280}
						size="lg"
						mt={10}
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{commonStrings.buttons.confirmButton(!!id)}
					</Button>
				)}
			>
				<TextInput
					labelText={pageStrings.autoCompleteLabel(id)}
					onClick={onOpen}
					labelProps={{ fontWeight: "bold" }}
					value={store.package.value?.name || ""}
					isReadOnly
				/>
				<MoneyInput
					value={store.price.value.toString()}
					onChange={(value: string) => store.price.setValue(parseInt(value))}
				/>
				<AutoCompleteModal
					isOpen={isOpen}
					onClose={onClose}
					header={
						<TextInput
							placeholder={pageStrings.packages}
							my={2}
							onKeyUp={(e) => {
								const input = e.target as HTMLInputElement;
								debounce.clearTimer();
								debounce.setTimer(setTimeout(() => store.searchPackages.setValue(input.value), 500));
							}}
						/>
					}
					listProps={{
						data: store.autoCompletePackages.items,
						loading: store.autoCompletePackages.loader.isLoading,
						renderItem: (item) => (
							<Text
								key={item.id}
								onClick={() => {
									store.package.setValue(item);
									onClose();
								}}
								cursor="pointer"
							>
								{item.name}
							</Text>
						),
						paginantionProps: {
							currentPage: store.autoCompletePackages.page,
							nextPage: store.autoCompletePackages.nextPage,
							prevPage: store.autoCompletePackages.previousPage,
							hasNextPage: store.autoCompletePackages.hasNextPage,
						},
					}}
				/>
			</CentralizedCard>

			{doesNotHaveBankAccount && (
				<Text
					mt={5}
					p={5}
					maxW={500}
					mx="auto"
					bg="orange.100"
					borderRadius={5}
				>
					{pageStrings.bankAccount}
					<Button ml={1} variant="link" onClick={onGoToClinicProfile}>
						{pageStrings.redirectToClinicProfile}
					</Button>
				</Text>
			)}
		</>
	);
};

export default observer(CreateOrEdit);
