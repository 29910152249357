import React from "react";
import {
	Radio,
	RadioGroup,
	Stack,
} from "@chakra-ui/react";

interface IProps {
	onSelect: (value: string) => void;
}

export const SelectRadio: React.FC<IProps> = (props) => {

	const { onSelect } = props;
	const items = ["Exame",  "Procedimento", "Cirurgia", "Consulta", "Outro"];

	return (
		<RadioGroup defaultValue={items[0]}>
			<Stack>
				{items.map((value, index)=>(
					<Radio
						key={index}
						value={value}
						bgColor="white"
						onChange={
							(e: React.ChangeEvent<HTMLInputElement>)=>onSelect(e.currentTarget.value)
						}
					>
						{value}
					</Radio>
				))}
			</Stack>
		</RadioGroup>

	);
};
