import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { components } from "./components";

export const theme = extendTheme(
	{
		colors,
		components,
	},
);
