import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface PagarmeAddress {
    zipcode: string;
    street: string;
    streetNumber: string;
    neighborhood: string;
    complementary: string | null;
}

export interface Phone {
    ddd: string;
    number: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng;
}

export interface AddressWithoutLocation {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
}

export interface Location {
    lat: number;
    lon: number;
}

export interface Transaction {
    id: string;
    boletoUrl: string | null;
    boletoBarcode: string | null;
    boletoExpirationDate: string | null;
    paymentMethod: PaymentMethods;
    status: TransactionStatus;
    statusReason: string;
    metadata: string;
    pixQRCode: string | null;
    qrExpirationDate: string | null;
    createdAt: Date;
    amount: number;
    lastFourDigits: string | null;
    cardBrand: string | null;
}

export interface PeriodFilterOptions {
    startDate: Date | null;
    endDate: Date | null;
}

export interface NotNullPeriodFilterOptions {
    startDate: Date;
    endDate: Date;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface FilterResultsOptions {
    name: string | null;
    specialities: Speciality[] | null;
    distance: number;
    userLocation: LatLng | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface NewBankAccount {
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    documentNumber: string;
}

export interface PagarmeRecipient {
    id: string;
    documentNumber: string;
}

export interface BankAccount {
    id: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    documentNumber: string;
}

export interface Professional {
    id: string;
    name: string;
    image: Image | null;
    ccmOrIss: string | null;
    cnes: string | null;
    resume: string | null;
    identificationNumber: string;
    documentNumber: string;
    description: string;
    specialities: Speciality[];
    blockedAt: Date | null;
}

export interface NewProfessional {
    name: string;
    image: UncertainImage | null;
    ccmOrIss: UncertainFile | null;
    cnes: UncertainFile | null;
    resume: UncertainFile | null;
    identificationNumber: string;
    documentNumber: string;
    description: string;
    specialities: Speciality[];
}

export interface Clinic {
    id: string;
    name: string;
    description: string;
    specialities: Speciality[];
    serviceDays: string;
    address: Address;
    bankAccount: BankAccount | null;
    blockedAt: Date | null;
    phone: string;
    whatsapp: string;
    technicalManager: string;
    crm: string;
    image: Image | null;
    createdAt: Date;
}

export interface EditClinic {
    name: string;
    description: string;
    specialities: Speciality[];
    serviceDays: string;
    address: Address;
    phone: string;
    whatsapp: string;
    technicalManager: string;
    crm: string;
    image: UncertainImage | null;
}

export interface EditMyClinic {
    name: string;
    description: string;
    specialities: Speciality[];
    serviceDays: string;
    address: Address;
    phone: string;
    whatsapp: string;
    image: UncertainImage | null;
}

export interface NewClinic {
    name: string;
    description: string;
    specialities: Speciality[];
    serviceDays: string;
    address: Address;
    phone: string;
    whatsapp: string;
    technicalManager: string;
    crm: string;
    image: UncertainImage | null;
}

export interface RawClinic {
    id: string;
    name: string;
    description: string;
    specialities: Speciality[];
    serviceDays: string;
    phone: string;
    whatsapp: string;
    technicalManager: string;
    crm: string;
    image: Image | null;
    createdAt: Date;
}

export interface Balance {
    waiting: number;
    available: number;
    transferred: number;
}

export interface AdminUser {
    id: string;
    clinic: Clinic | null;
    createdAt: Date;
    name: string;
    email: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
}

export interface NewAdminUser {
    clinicId: string | null;
    password: string;
    name: string;
    email: string;
}

export interface MedicalProcedure {
    id: string;
    name: string;
    description: string;
}

export interface NewMedicalProcedure {
    name: string;
    description: string;
}

export interface NewPackage {
    name: string;
    image: UncertainImage | null;
    speciality: Speciality;
    medicalProcedures: MedicalProcedure[];
}

export interface EditPackage {
    name: string;
    image: UncertainImage | null;
    speciality: Speciality;
    medicalProcedures: MedicalProcedure[];
}

export interface Package {
    id: string;
    name: string;
    image: Image | null;
    speciality: Speciality;
    medicalProcedures: MedicalProcedure[];
    createdAt: Date;
}

export interface RawPackage {
    id: string;
    name: string;
    speciality: Speciality;
    createdAt: Date;
}

export interface ClinicPackage {
    id: string;
    package: Package;
    clinic: Clinic;
    price: number;
}

export interface NewClinicPackage {
    clinicId: string;
    packageId: string;
    price: number;
}

export interface EditClinicPackage {
    package: Package;
    clinic: Clinic;
    price: number;
}

export interface RawClinicPackage {
    id: string;
    price: number;
    createdAt: Date;
}

export interface PackageWithCount {
    package: RawPackage;
    count: number;
}

export interface ClinicWithHighestSales {
    clinic: RawClinic;
    amountOfSales: number;
}

export interface ClinicWithHighestBilling {
    clinic: RawClinic;
    revenue: number;
}

export interface HighestBillingPackages {
    package: RawPackage;
    revenue: number;
}

export interface HighestBillingClinicPackages {
    package: RawPackage;
    revenue: number;
    id: string;
    price: number;
    createdAt: Date;
}

export interface GraphicData {
    date: Date;
    hour: number;
    dateHour: Date;
    count: number;
    price: number;
}

export interface GraphicDateReport {
    value: number;
    date: Date;
}

export interface GraphicDateHourReport {
    value: number;
    dateHour: Date;
}

export interface GraphicHourReport {
    value: number;
    hour: number;
}

export interface GraphicDataPerDate {
    sales: GraphicDateReport[];
}

export interface GraphicDataPerDateHour {
    sales: GraphicDateHourReport[];
}

export interface GraphicDataPerHour {
    sales: GraphicHourReport[];
}

export interface HomeData {
    totalAmountOfClinicPackages: number | null;
    totalAmountOfClinics: number | null;
    totalAmountOfProfessionalsByClinicId: number | null;
    totalAmountOfPackages: number | null;
    topTenPackages: PackageWithCount[] | null;
    topTenHighestBillingClinicPackages: HighestBillingClinicPackages[] | null;
    topTenClinicPackages: PackageWithCount[] | null;
    topTenHighestBillingPackages: HighestBillingPackages[] | null;
    topTenClinicsWithTheHighestSales: ClinicWithHighestSales[] | null;
    topTenClinicsWithHighestBilling: ClinicWithHighestBilling[] | null;
    totalBilling: number;
    averageBilling: number;
    totalAmountOfUsers: number;
}

export interface EditMedicalProcedure {
    name: string;
    description: string;
}

export interface UserAddress {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
}

export interface User {
    id: string;
    email: string;
    documentNumber: string | null;
    address: UserAddress | null;
    name: string;
    phone: string;
    image: Image | null;
    blockedAt: Date | null;
    createdAt: Date;
}

export interface EditUser {
    email: string;
    name: string;
    phone: string;
    image: UncertainImage | null;
    documentNumber: string | null;
    address: UserAddress | null;
}

export interface NewUser {
    email: string;
    password: string;
    address: UserAddress | null;
    documentNumber: string | null;
    name: string;
    phone: string;
    image: UncertainImage | null;
}

export interface EditProfessional {
    name: string;
    image: UncertainImage | null;
    ccmOrIss: UncertainFile | null;
    cnes: UncertainFile | null;
    resume: UncertainFile | null;
    identificationNumber: string;
    documentNumber: string;
    description: string;
    specialities: Speciality[];
}

export interface Order {
    id: string;
    user: User;
    clinicPackage: ClinicPackage;
    professional: Professional | null;
    suggestedDates: Date[];
    confirmedDate: Date | null;
    suggestedHours: Date[];
    confirmedHour: Date | null;
    transactionId: string | null;
    paymentMethod: PaymentMethods;
    paymentStatus: OrderPaymentStatus;
    createdAt: Date;
}

export interface EditOrder {
    professional: Professional | null;
    confirmedDate: Date | null;
    confirmedHour: Date | null;
}

export enum PaymentMethods {
    creditCard = "creditCard",
    boleto = "boleto",
    pix = "pix",
}

export function translatePaymentMethods(enumPaymentMethods: PaymentMethods): string {
    switch (enumPaymentMethods) {
        case PaymentMethods.creditCard: {
            return strings ? strings["enum"]["PaymentMethods"]["creditCard"] || PaymentMethods.creditCard : PaymentMethods.creditCard;
        }
        case PaymentMethods.boleto: {
            return strings ? strings["enum"]["PaymentMethods"]["boleto"] || PaymentMethods.boleto : PaymentMethods.boleto;
        }
        case PaymentMethods.pix: {
            return strings ? strings["enum"]["PaymentMethods"]["pix"] || PaymentMethods.pix : PaymentMethods.pix;
        }
    }
    return "";
}

export function allValuesPaymentMethods(): PaymentMethods[] {
    return [
        PaymentMethods.creditCard,
        PaymentMethods.boleto,
        PaymentMethods.pix,
    ];
}

export function allTranslatedValuesPaymentMethods(): string[] {
    return [
        translatePaymentMethods(PaymentMethods.creditCard),
        translatePaymentMethods(PaymentMethods.boleto),
        translatePaymentMethods(PaymentMethods.pix),
    ];
}

export function allDisplayableValuesPaymentMethods(): string[] {
    return allTranslatedValuesPaymentMethods().sort();
}

export function valueFromTranslationPaymentMethods(translation: string): PaymentMethods {
    const index = allTranslatedValuesPaymentMethods().indexOf(translation);
    return allValuesPaymentMethods()[index] || PaymentMethods.creditCard;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum TransactionStatus {
    processing = "processing",
    authorized = "authorized",
    paid = "paid",
    refunded = "refunded",
    waitingPayment = "waitingPayment",
    pendingRefund = "pendingRefund",
    refused = "refused",
    chargedback = "chargedback",
}

export function translateTransactionStatus(enumTransactionStatus: TransactionStatus): string {
    switch (enumTransactionStatus) {
        case TransactionStatus.processing: {
            return strings ? strings["enum"]["TransactionStatus"]["processing"] || TransactionStatus.processing : TransactionStatus.processing;
        }
        case TransactionStatus.authorized: {
            return strings ? strings["enum"]["TransactionStatus"]["authorized"] || TransactionStatus.authorized : TransactionStatus.authorized;
        }
        case TransactionStatus.paid: {
            return strings ? strings["enum"]["TransactionStatus"]["paid"] || TransactionStatus.paid : TransactionStatus.paid;
        }
        case TransactionStatus.refunded: {
            return strings ? strings["enum"]["TransactionStatus"]["refunded"] || TransactionStatus.refunded : TransactionStatus.refunded;
        }
        case TransactionStatus.waitingPayment: {
            return strings ? strings["enum"]["TransactionStatus"]["waitingPayment"] || TransactionStatus.waitingPayment : TransactionStatus.waitingPayment;
        }
        case TransactionStatus.pendingRefund: {
            return strings ? strings["enum"]["TransactionStatus"]["pendingRefund"] || TransactionStatus.pendingRefund : TransactionStatus.pendingRefund;
        }
        case TransactionStatus.refused: {
            return strings ? strings["enum"]["TransactionStatus"]["refused"] || TransactionStatus.refused : TransactionStatus.refused;
        }
        case TransactionStatus.chargedback: {
            return strings ? strings["enum"]["TransactionStatus"]["chargedback"] || TransactionStatus.chargedback : TransactionStatus.chargedback;
        }
    }
    return "";
}

export function allValuesTransactionStatus(): TransactionStatus[] {
    return [
        TransactionStatus.processing,
        TransactionStatus.authorized,
        TransactionStatus.paid,
        TransactionStatus.refunded,
        TransactionStatus.waitingPayment,
        TransactionStatus.pendingRefund,
        TransactionStatus.refused,
        TransactionStatus.chargedback,
    ];
}

export function allTranslatedValuesTransactionStatus(): string[] {
    return [
        translateTransactionStatus(TransactionStatus.processing),
        translateTransactionStatus(TransactionStatus.authorized),
        translateTransactionStatus(TransactionStatus.paid),
        translateTransactionStatus(TransactionStatus.refunded),
        translateTransactionStatus(TransactionStatus.waitingPayment),
        translateTransactionStatus(TransactionStatus.pendingRefund),
        translateTransactionStatus(TransactionStatus.refused),
        translateTransactionStatus(TransactionStatus.chargedback),
    ];
}

export function allDisplayableValuesTransactionStatus(): string[] {
    return allTranslatedValuesTransactionStatus().sort();
}

export function valueFromTranslationTransactionStatus(translation: string): TransactionStatus {
    const index = allTranslatedValuesTransactionStatus().indexOf(translation);
    return allValuesTransactionStatus()[index] || TransactionStatus.processing;
}

export enum Speciality {
    cardiology = "cardiology",
    generalSurgery = "generalSurgery",
    vascularSurgery = "vascularSurgery",
    coloproctologist = "coloproctologist",
    dermatology = "dermatology",
    endocrinology = "endocrinology",
    gastroenterology = "gastroenterology",
    gynecology = "gynecology",
    mastology = "mastology",
    obstetrics = "obstetrics",
    orthopedics = "orthopedics",
    pneumology = "pneumology",
    urology = "urology",
}

export function translateSpeciality(enumSpeciality: Speciality): string {
    switch (enumSpeciality) {
        case Speciality.cardiology: {
            return strings ? strings["enum"]["Speciality"]["cardiology"] || Speciality.cardiology : Speciality.cardiology;
        }
        case Speciality.generalSurgery: {
            return strings ? strings["enum"]["Speciality"]["generalSurgery"] || Speciality.generalSurgery : Speciality.generalSurgery;
        }
        case Speciality.vascularSurgery: {
            return strings ? strings["enum"]["Speciality"]["vascularSurgery"] || Speciality.vascularSurgery : Speciality.vascularSurgery;
        }
        case Speciality.coloproctologist: {
            return strings ? strings["enum"]["Speciality"]["coloproctologist"] || Speciality.coloproctologist : Speciality.coloproctologist;
        }
        case Speciality.dermatology: {
            return strings ? strings["enum"]["Speciality"]["dermatology"] || Speciality.dermatology : Speciality.dermatology;
        }
        case Speciality.endocrinology: {
            return strings ? strings["enum"]["Speciality"]["endocrinology"] || Speciality.endocrinology : Speciality.endocrinology;
        }
        case Speciality.gastroenterology: {
            return strings ? strings["enum"]["Speciality"]["gastroenterology"] || Speciality.gastroenterology : Speciality.gastroenterology;
        }
        case Speciality.gynecology: {
            return strings ? strings["enum"]["Speciality"]["gynecology"] || Speciality.gynecology : Speciality.gynecology;
        }
        case Speciality.mastology: {
            return strings ? strings["enum"]["Speciality"]["mastology"] || Speciality.mastology : Speciality.mastology;
        }
        case Speciality.obstetrics: {
            return strings ? strings["enum"]["Speciality"]["obstetrics"] || Speciality.obstetrics : Speciality.obstetrics;
        }
        case Speciality.orthopedics: {
            return strings ? strings["enum"]["Speciality"]["orthopedics"] || Speciality.orthopedics : Speciality.orthopedics;
        }
        case Speciality.pneumology: {
            return strings ? strings["enum"]["Speciality"]["pneumology"] || Speciality.pneumology : Speciality.pneumology;
        }
        case Speciality.urology: {
            return strings ? strings["enum"]["Speciality"]["urology"] || Speciality.urology : Speciality.urology;
        }
    }
    return "";
}

export function allValuesSpeciality(): Speciality[] {
    return [
        Speciality.cardiology,
        Speciality.generalSurgery,
        Speciality.vascularSurgery,
        Speciality.coloproctologist,
        Speciality.dermatology,
        Speciality.endocrinology,
        Speciality.gastroenterology,
        Speciality.gynecology,
        Speciality.mastology,
        Speciality.obstetrics,
        Speciality.orthopedics,
        Speciality.pneumology,
        Speciality.urology,
    ];
}

export function allTranslatedValuesSpeciality(): string[] {
    return [
        translateSpeciality(Speciality.cardiology),
        translateSpeciality(Speciality.generalSurgery),
        translateSpeciality(Speciality.vascularSurgery),
        translateSpeciality(Speciality.coloproctologist),
        translateSpeciality(Speciality.dermatology),
        translateSpeciality(Speciality.endocrinology),
        translateSpeciality(Speciality.gastroenterology),
        translateSpeciality(Speciality.gynecology),
        translateSpeciality(Speciality.mastology),
        translateSpeciality(Speciality.obstetrics),
        translateSpeciality(Speciality.orthopedics),
        translateSpeciality(Speciality.pneumology),
        translateSpeciality(Speciality.urology),
    ];
}

export function allDisplayableValuesSpeciality(): string[] {
    return allTranslatedValuesSpeciality().sort();
}

export function valueFromTranslationSpeciality(translation: string): Speciality {
    const index = allTranslatedValuesSpeciality().indexOf(translation);
    return allValuesSpeciality()[index] || Speciality.cardiology;
}

export enum OrderPaymentStatus {
    waitingPayment = "waitingPayment",
    pending = "pending",
    scheduled = "scheduled",
    finished = "finished",
    canceled = "canceled",
}

export function translateOrderPaymentStatus(enumOrderPaymentStatus: OrderPaymentStatus): string {
    switch (enumOrderPaymentStatus) {
        case OrderPaymentStatus.waitingPayment: {
            return strings ? strings["enum"]["OrderPaymentStatus"]["waitingPayment"] || OrderPaymentStatus.waitingPayment : OrderPaymentStatus.waitingPayment;
        }
        case OrderPaymentStatus.pending: {
            return strings ? strings["enum"]["OrderPaymentStatus"]["pending"] || OrderPaymentStatus.pending : OrderPaymentStatus.pending;
        }
        case OrderPaymentStatus.scheduled: {
            return strings ? strings["enum"]["OrderPaymentStatus"]["scheduled"] || OrderPaymentStatus.scheduled : OrderPaymentStatus.scheduled;
        }
        case OrderPaymentStatus.finished: {
            return strings ? strings["enum"]["OrderPaymentStatus"]["finished"] || OrderPaymentStatus.finished : OrderPaymentStatus.finished;
        }
        case OrderPaymentStatus.canceled: {
            return strings ? strings["enum"]["OrderPaymentStatus"]["canceled"] || OrderPaymentStatus.canceled : OrderPaymentStatus.canceled;
        }
    }
    return "";
}

export function allValuesOrderPaymentStatus(): OrderPaymentStatus[] {
    return [
        OrderPaymentStatus.waitingPayment,
        OrderPaymentStatus.pending,
        OrderPaymentStatus.scheduled,
        OrderPaymentStatus.finished,
        OrderPaymentStatus.canceled,
    ];
}

export function allTranslatedValuesOrderPaymentStatus(): string[] {
    return [
        translateOrderPaymentStatus(OrderPaymentStatus.waitingPayment),
        translateOrderPaymentStatus(OrderPaymentStatus.pending),
        translateOrderPaymentStatus(OrderPaymentStatus.scheduled),
        translateOrderPaymentStatus(OrderPaymentStatus.finished),
        translateOrderPaymentStatus(OrderPaymentStatus.canceled),
    ];
}

export function allDisplayableValuesOrderPaymentStatus(): string[] {
    return allTranslatedValuesOrderPaymentStatus().sort();
}

export function valueFromTranslationOrderPaymentStatus(translation: string): OrderPaymentStatus {
    const index = allTranslatedValuesOrderPaymentStatus().indexOf(translation);
    return allValuesOrderPaymentStatus()[index] || OrderPaymentStatus.waitingPayment;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum HomeGraphicType {
    hour = "hour",
    dateHour = "dateHour",
    date = "date",
}

export function translateHomeGraphicType(enumHomeGraphicType: HomeGraphicType): string {
    switch (enumHomeGraphicType) {
        case HomeGraphicType.hour: {
            return strings ? strings["enum"]["HomeGraphicType"]["hour"] || HomeGraphicType.hour : HomeGraphicType.hour;
        }
        case HomeGraphicType.dateHour: {
            return strings ? strings["enum"]["HomeGraphicType"]["dateHour"] || HomeGraphicType.dateHour : HomeGraphicType.dateHour;
        }
        case HomeGraphicType.date: {
            return strings ? strings["enum"]["HomeGraphicType"]["date"] || HomeGraphicType.date : HomeGraphicType.date;
        }
    }
    return "";
}

export function allValuesHomeGraphicType(): HomeGraphicType[] {
    return [
        HomeGraphicType.hour,
        HomeGraphicType.dateHour,
        HomeGraphicType.date,
    ];
}

export function allTranslatedValuesHomeGraphicType(): string[] {
    return [
        translateHomeGraphicType(HomeGraphicType.hour),
        translateHomeGraphicType(HomeGraphicType.dateHour),
        translateHomeGraphicType(HomeGraphicType.date),
    ];
}

export function allDisplayableValuesHomeGraphicType(): string[] {
    return allTranslatedValuesHomeGraphicType().sort();
}

export function valueFromTranslationHomeGraphicType(translation: string): HomeGraphicType {
    const index = allTranslatedValuesHomeGraphicType().indexOf(translation);
    return allValuesHomeGraphicType()[index] || HomeGraphicType.hour;
}

export enum GraphicType {
    price = "price",
    amount = "amount",
}

export function translateGraphicType(enumGraphicType: GraphicType): string {
    switch (enumGraphicType) {
        case GraphicType.price: {
            return strings ? strings["enum"]["GraphicType"]["price"] || GraphicType.price : GraphicType.price;
        }
        case GraphicType.amount: {
            return strings ? strings["enum"]["GraphicType"]["amount"] || GraphicType.amount : GraphicType.amount;
        }
    }
    return "";
}

export function allValuesGraphicType(): GraphicType[] {
    return [
        GraphicType.price,
        GraphicType.amount,
    ];
}

export function allTranslatedValuesGraphicType(): string[] {
    return [
        translateGraphicType(GraphicType.price),
        translateGraphicType(GraphicType.amount),
    ];
}

export function allDisplayableValuesGraphicType(): string[] {
    return allTranslatedValuesGraphicType().sort();
}

export function valueFromTranslationGraphicType(translation: string): GraphicType {
    const index = allTranslatedValuesGraphicType().indexOf(translation);
    return allValuesGraphicType()[index] || GraphicType.price;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    CNPJAlreadyRegistered = "CNPJAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    InvalidAccessToken = "InvalidAccessToken",
    AlreadyBlocked = "AlreadyBlocked",
    AlreadyUnblocked = "AlreadyUnblocked",
    AlreadyFinished = "AlreadyFinished",
    AlreadyCanceled = "AlreadyCanceled",
    PackageAlreadyRegistered = "PackageAlreadyRegistered",
    PagarMeBankAccount = "PagarMeBankAccount",
    PagarMeCreditCard = "PagarMeCreditCard",
    PagarMeTransaction = "PagarMeTransaction",
    PagarMeNotPaid = "PagarMeNotPaid",
    InvalidCEP = "InvalidCEP",
    NeedProfessional = "NeedProfessional",
    WithoutBankAccount = "WithoutBankAccount",
    WithoutBankAccountRecipient = "WithoutBankAccountRecipient",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.CNPJAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CNPJAlreadyRegistered"] || ErrorType.CNPJAlreadyRegistered : ErrorType.CNPJAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.InvalidAccessToken: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccessToken"] || ErrorType.InvalidAccessToken : ErrorType.InvalidAccessToken;
        }
        case ErrorType.AlreadyBlocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyBlocked"] || ErrorType.AlreadyBlocked : ErrorType.AlreadyBlocked;
        }
        case ErrorType.AlreadyUnblocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyUnblocked"] || ErrorType.AlreadyUnblocked : ErrorType.AlreadyUnblocked;
        }
        case ErrorType.AlreadyFinished: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyFinished"] || ErrorType.AlreadyFinished : ErrorType.AlreadyFinished;
        }
        case ErrorType.AlreadyCanceled: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyCanceled"] || ErrorType.AlreadyCanceled : ErrorType.AlreadyCanceled;
        }
        case ErrorType.PackageAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["PackageAlreadyRegistered"] || ErrorType.PackageAlreadyRegistered : ErrorType.PackageAlreadyRegistered;
        }
        case ErrorType.PagarMeBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeBankAccount"] || ErrorType.PagarMeBankAccount : ErrorType.PagarMeBankAccount;
        }
        case ErrorType.PagarMeCreditCard: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeCreditCard"] || ErrorType.PagarMeCreditCard : ErrorType.PagarMeCreditCard;
        }
        case ErrorType.PagarMeTransaction: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeTransaction"] || ErrorType.PagarMeTransaction : ErrorType.PagarMeTransaction;
        }
        case ErrorType.PagarMeNotPaid: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeNotPaid"] || ErrorType.PagarMeNotPaid : ErrorType.PagarMeNotPaid;
        }
        case ErrorType.InvalidCEP: {
            return strings ? strings["enum"]["ErrorType"]["InvalidCEP"] || ErrorType.InvalidCEP : ErrorType.InvalidCEP;
        }
        case ErrorType.NeedProfessional: {
            return strings ? strings["enum"]["ErrorType"]["NeedProfessional"] || ErrorType.NeedProfessional : ErrorType.NeedProfessional;
        }
        case ErrorType.WithoutBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["WithoutBankAccount"] || ErrorType.WithoutBankAccount : ErrorType.WithoutBankAccount;
        }
        case ErrorType.WithoutBankAccountRecipient: {
            return strings ? strings["enum"]["ErrorType"]["WithoutBankAccountRecipient"] || ErrorType.WithoutBankAccountRecipient : ErrorType.WithoutBankAccountRecipient;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.CNPJAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.InvalidAccessToken,
        ErrorType.AlreadyBlocked,
        ErrorType.AlreadyUnblocked,
        ErrorType.AlreadyFinished,
        ErrorType.AlreadyCanceled,
        ErrorType.PackageAlreadyRegistered,
        ErrorType.PagarMeBankAccount,
        ErrorType.PagarMeCreditCard,
        ErrorType.PagarMeTransaction,
        ErrorType.PagarMeNotPaid,
        ErrorType.InvalidCEP,
        ErrorType.NeedProfessional,
        ErrorType.WithoutBankAccount,
        ErrorType.WithoutBankAccountRecipient,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.CNPJAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.InvalidAccessToken),
        translateErrorType(ErrorType.AlreadyBlocked),
        translateErrorType(ErrorType.AlreadyUnblocked),
        translateErrorType(ErrorType.AlreadyFinished),
        translateErrorType(ErrorType.AlreadyCanceled),
        translateErrorType(ErrorType.PackageAlreadyRegistered),
        translateErrorType(ErrorType.PagarMeBankAccount),
        translateErrorType(ErrorType.PagarMeCreditCard),
        translateErrorType(ErrorType.PagarMeTransaction),
        translateErrorType(ErrorType.PagarMeNotPaid),
        translateErrorType(ErrorType.InvalidCEP),
        translateErrorType(ErrorType.NeedProfessional),
        translateErrorType(ErrorType.WithoutBankAccount),
        translateErrorType(ErrorType.WithoutBankAccountRecipient),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getClinic(id: string, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllClinics(pageOffset: number, progress?: (progress: number) => void): Promise<Clinic[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClinics", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        specialities: e.specialities.map((e: any) => e),
        serviceDays: e.serviceDays,
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        bankAccount: e.bankAccount === null || e.bankAccount === undefined ? null : {
            id: e.bankAccount.id,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            documentNumber: e.bankAccount.documentNumber,
        },
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
        phone: e.phone,
        whatsapp: e.whatsapp,
        technicalManager: e.technicalManager,
        crm: e.crm,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getTotalAmountOfClinics(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalAmountOfClinics", args: {}, progress});
    return ret || 0;
}

export async function getClinicForAdminClinicUser(clinicId: string, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "getClinicForAdminClinicUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getClinicBalance(progress?: (progress: number) => void): Promise<Balance> {
    const ret = await makeRequest({name: "getClinicBalance", args: {}, progress});
    return {
        waiting: ret.waiting || 0,
        available: ret.available || 0,
        transferred: ret.transferred || 0,
    };
}

export async function addProfessionalIntoClinic(id: string, professionalId: string, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
        professionalId: professionalId,
    };
    const ret = await makeRequest({name: "addProfessionalIntoClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function removeProfessionalFromClinic(id: string, professionalId: string, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
        professionalId: professionalId,
    };
    const ret = await makeRequest({name: "removeProfessionalFromClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function editClinic(id: string, editClinic: EditClinic, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        id: id,
        editClinic: {
            name: editClinic.name,
            description: editClinic.description,
            specialities: editClinic.specialities.map(e => e),
            serviceDays: editClinic.serviceDays,
            address: {
                zipcode: editClinic.address.zipcode,
                street: editClinic.address.street,
                streetNumber: editClinic.address.streetNumber,
                complementary: editClinic.address.complementary === null || editClinic.address.complementary === undefined ? null : editClinic.address.complementary,
                neighborhood: editClinic.address.neighborhood,
                city: editClinic.address.city,
                state: editClinic.address.state,
                countryCode: editClinic.address.countryCode,
                latLng: {
                    lat: editClinic.address.latLng.lat,
                    lng: editClinic.address.latLng.lng,
                },
            },
            phone: editClinic.phone,
            whatsapp: editClinic.whatsapp,
            technicalManager: editClinic.technicalManager,
            crm: editClinic.crm,
            image: editClinic.image === null || editClinic.image === undefined ? null : {
                bytes: editClinic.image.bytes === null || editClinic.image.bytes === undefined ? null : editClinic.image.bytes.toString("base64"),
                image: editClinic.image.image === null || editClinic.image.image === undefined ? null : {
                    thumb: {
                        width: editClinic.image.image.thumb.width || 0,
                        height: editClinic.image.image.thumb.height || 0,
                        url: editClinic.image.image.thumb.url,
                    },
                    width: editClinic.image.image.width || 0,
                    height: editClinic.image.image.height || 0,
                    url: editClinic.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editMyClinic(editClinic: EditMyClinic, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        editClinic: {
            name: editClinic.name,
            description: editClinic.description,
            specialities: editClinic.specialities.map(e => e),
            serviceDays: editClinic.serviceDays,
            address: {
                zipcode: editClinic.address.zipcode,
                street: editClinic.address.street,
                streetNumber: editClinic.address.streetNumber,
                complementary: editClinic.address.complementary === null || editClinic.address.complementary === undefined ? null : editClinic.address.complementary,
                neighborhood: editClinic.address.neighborhood,
                city: editClinic.address.city,
                state: editClinic.address.state,
                countryCode: editClinic.address.countryCode,
                latLng: {
                    lat: editClinic.address.latLng.lat,
                    lng: editClinic.address.latLng.lng,
                },
            },
            phone: editClinic.phone,
            whatsapp: editClinic.whatsapp,
            image: editClinic.image === null || editClinic.image === undefined ? null : {
                bytes: editClinic.image.bytes === null || editClinic.image.bytes === undefined ? null : editClinic.image.bytes.toString("base64"),
                image: editClinic.image.image === null || editClinic.image.image === undefined ? null : {
                    thumb: {
                        width: editClinic.image.image.thumb.width || 0,
                        height: editClinic.image.image.thumb.height || 0,
                        url: editClinic.image.image.thumb.url,
                    },
                    width: editClinic.image.image.width || 0,
                    height: editClinic.image.image.height || 0,
                    url: editClinic.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editMyClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function changeClinicBlockStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeClinicBlockStatus", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createClinic(newClinic: NewClinic, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        newClinic: {
            name: newClinic.name,
            description: newClinic.description,
            specialities: newClinic.specialities.map(e => e),
            serviceDays: newClinic.serviceDays,
            address: {
                zipcode: newClinic.address.zipcode,
                street: newClinic.address.street,
                streetNumber: newClinic.address.streetNumber,
                complementary: newClinic.address.complementary === null || newClinic.address.complementary === undefined ? null : newClinic.address.complementary,
                neighborhood: newClinic.address.neighborhood,
                city: newClinic.address.city,
                state: newClinic.address.state,
                countryCode: newClinic.address.countryCode,
                latLng: {
                    lat: newClinic.address.latLng.lat,
                    lng: newClinic.address.latLng.lng,
                },
            },
            phone: newClinic.phone,
            whatsapp: newClinic.whatsapp,
            technicalManager: newClinic.technicalManager,
            crm: newClinic.crm,
            image: newClinic.image === null || newClinic.image === undefined ? null : {
                bytes: newClinic.image.bytes === null || newClinic.image.bytes === undefined ? null : newClinic.image.bytes.toString("base64"),
                image: newClinic.image.image === null || newClinic.image.image === undefined ? null : {
                    thumb: {
                        width: newClinic.image.image.thumb.width || 0,
                        height: newClinic.image.image.thumb.height || 0,
                        url: newClinic.image.image.thumb.url,
                    },
                    width: newClinic.image.image.width || 0,
                    height: newClinic.image.image.height || 0,
                    url: newClinic.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteClinic(id: string, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        serviceDays: ret.serviceDays,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        phone: ret.phone,
        whatsapp: ret.whatsapp,
        technicalManager: ret.technicalManager,
        crm: ret.crm,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function autocompleteClinic(pageOffSet: number, name: string, progress?: (progress: number) => void): Promise<Clinic[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autocompleteClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        specialities: e.specialities.map((e: any) => e),
        serviceDays: e.serviceDays,
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        bankAccount: e.bankAccount === null || e.bankAccount === undefined ? null : {
            id: e.bankAccount.id,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            documentNumber: e.bankAccount.documentNumber,
        },
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
        phone: e.phone,
        whatsapp: e.whatsapp,
        technicalManager: e.technicalManager,
        crm: e.crm,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function createTransfer(amount: number, progress?: (progress: number) => void): Promise<Balance> {
    const args = {
        amount: amount || 0,
    };
    const ret = await makeRequest({name: "createTransfer", args, progress});
    return {
        waiting: ret.waiting || 0,
        available: ret.available || 0,
        transferred: ret.transferred || 0,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminClinicUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminClinicUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAllAdminClinicUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminClinicUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function getAllAdminClinicUsersByClinicId(pageOffset: number, clinicId: string, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "getAllAdminClinicUsersByClinicId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function getTotalAmountOfAdminClinicsUsersWithFilter(clinicId: string | null, progress?: (progress: number) => void): Promise<number> {
    const args = {
        clinicId: clinicId === null || clinicId === undefined ? null : clinicId,
    };
    const ret = await makeRequest({name: "getTotalAmountOfAdminClinicsUsersWithFilter", args, progress});
    return ret || 0;
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            clinicId: newAdminUser.clinicId === null || newAdminUser.clinicId === undefined ? null : newAdminUser.clinicId,
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function createOrEditBankAccount(newBankAccount: NewBankAccount, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        newBankAccount: {
            bankCode: newBankAccount.bankCode,
            agency: newBankAccount.agency,
            agencyDv: newBankAccount.agencyDv === null || newBankAccount.agencyDv === undefined ? null : newBankAccount.agencyDv,
            account: newBankAccount.account,
            accountDv: newBankAccount.accountDv === null || newBankAccount.accountDv === undefined ? null : newBankAccount.accountDv,
            type: newBankAccount.type,
            documentNumber: newBankAccount.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createOrEditBankAccount", args, progress});
    return {
        id: ret.id,
        bankCode: ret.bankCode,
        agency: ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        documentNumber: ret.documentNumber,
    };
}

export async function getPackage(id: string, progress?: (progress: number) => void): Promise<Package> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPackage", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        speciality: ret.speciality,
        medicalProcedures: ret.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllPackages(pageOffset: number, progress?: (progress: number) => void): Promise<Package[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPackages", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        speciality: e.speciality,
        medicalProcedures: e.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getTotalNumberOfPackages(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberOfPackages", args: {}, progress});
    return ret || 0;
}

export async function editPackage(id: string, editPackage: EditPackage, progress?: (progress: number) => void): Promise<Package> {
    const args = {
        id: id,
        editPackage: {
            name: editPackage.name,
            image: editPackage.image === null || editPackage.image === undefined ? null : {
                bytes: editPackage.image.bytes === null || editPackage.image.bytes === undefined ? null : editPackage.image.bytes.toString("base64"),
                image: editPackage.image.image === null || editPackage.image.image === undefined ? null : {
                    thumb: {
                        width: editPackage.image.image.thumb.width || 0,
                        height: editPackage.image.image.thumb.height || 0,
                        url: editPackage.image.image.thumb.url,
                    },
                    width: editPackage.image.image.width || 0,
                    height: editPackage.image.image.height || 0,
                    url: editPackage.image.image.url,
                },
            },
            speciality: editPackage.speciality,
            medicalProcedures: editPackage.medicalProcedures.map(e => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
        },
    };
    const ret = await makeRequest({name: "editPackage", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        speciality: ret.speciality,
        medicalProcedures: ret.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createPackage(newPackage: NewPackage, progress?: (progress: number) => void): Promise<Package> {
    const args = {
        newPackage: {
            name: newPackage.name,
            image: newPackage.image === null || newPackage.image === undefined ? null : {
                bytes: newPackage.image.bytes === null || newPackage.image.bytes === undefined ? null : newPackage.image.bytes.toString("base64"),
                image: newPackage.image.image === null || newPackage.image.image === undefined ? null : {
                    thumb: {
                        width: newPackage.image.image.thumb.width || 0,
                        height: newPackage.image.image.thumb.height || 0,
                        url: newPackage.image.image.thumb.url,
                    },
                    width: newPackage.image.image.width || 0,
                    height: newPackage.image.image.height || 0,
                    url: newPackage.image.image.url,
                },
            },
            speciality: newPackage.speciality,
            medicalProcedures: newPackage.medicalProcedures.map(e => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
        },
    };
    const ret = await makeRequest({name: "createPackage", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        speciality: ret.speciality,
        medicalProcedures: ret.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deletePackage(id: string, progress?: (progress: number) => void): Promise<Package> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePackage", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        speciality: ret.speciality,
        medicalProcedures: ret.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function autoCompletePackages(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<Package[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompletePackages", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        speciality: e.speciality,
        medicalProcedures: e.medicalProcedures.map((e: any) => ({
            id: e.id,
            name: e.name,
            description: e.description,
        })),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getClinicPackage(id: string, progress?: (progress: number) => void): Promise<ClinicPackage> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getClinicPackage", args, progress});
    return {
        id: ret.id,
        package: {
            id: ret.package.id,
            name: ret.package.name,
            image: ret.package.image === null || ret.package.image === undefined ? null : {
                thumb: {
                    width: ret.package.image.thumb.width || 0,
                    height: ret.package.image.thumb.height || 0,
                    url: ret.package.image.thumb.url,
                },
                width: ret.package.image.width || 0,
                height: ret.package.image.height || 0,
                url: ret.package.image.url,
            },
            speciality: ret.package.speciality,
            medicalProcedures: ret.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(ret.package.createdAt + "Z"),
        },
        clinic: {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        price: ret.price || 0,
    };
}

export async function getAllClinicsPackages(pageOffset: number, progress?: (progress: number) => void): Promise<ClinicPackage[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClinicsPackages", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        package: {
            id: e.package.id,
            name: e.package.name,
            image: e.package.image === null || e.package.image === undefined ? null : {
                thumb: {
                    width: e.package.image.thumb.width || 0,
                    height: e.package.image.thumb.height || 0,
                    url: e.package.image.thumb.url,
                },
                width: e.package.image.width || 0,
                height: e.package.image.height || 0,
                url: e.package.image.url,
            },
            speciality: e.package.speciality,
            medicalProcedures: e.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(e.package.createdAt + "Z"),
        },
        clinic: {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        price: e.price || 0,
    }));
}

export async function getAllClinicPackageByClinicId(id: string, pageOffset: number, progress?: (progress: number) => void): Promise<ClinicPackage[]> {
    const args = {
        id: id,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClinicPackageByClinicId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        package: {
            id: e.package.id,
            name: e.package.name,
            image: e.package.image === null || e.package.image === undefined ? null : {
                thumb: {
                    width: e.package.image.thumb.width || 0,
                    height: e.package.image.thumb.height || 0,
                    url: e.package.image.thumb.url,
                },
                width: e.package.image.width || 0,
                height: e.package.image.height || 0,
                url: e.package.image.url,
            },
            speciality: e.package.speciality,
            medicalProcedures: e.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(e.package.createdAt + "Z"),
        },
        clinic: {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        price: e.price || 0,
    }));
}

export async function getAllClinicPackageByPackageId(id: string, pageOffset: number, progress?: (progress: number) => void): Promise<ClinicPackage[]> {
    const args = {
        id: id,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClinicPackageByPackageId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        package: {
            id: e.package.id,
            name: e.package.name,
            image: e.package.image === null || e.package.image === undefined ? null : {
                thumb: {
                    width: e.package.image.thumb.width || 0,
                    height: e.package.image.thumb.height || 0,
                    url: e.package.image.thumb.url,
                },
                width: e.package.image.width || 0,
                height: e.package.image.height || 0,
                url: e.package.image.url,
            },
            speciality: e.package.speciality,
            medicalProcedures: e.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(e.package.createdAt + "Z"),
        },
        clinic: {
            id: e.clinic.id,
            name: e.clinic.name,
            description: e.clinic.description,
            specialities: e.clinic.specialities.map((e: any) => e),
            serviceDays: e.clinic.serviceDays,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary === null || e.clinic.address.complementary === undefined ? null : e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
                countryCode: e.clinic.address.countryCode,
                latLng: {
                    lat: e.clinic.address.latLng.lat,
                    lng: e.clinic.address.latLng.lng,
                },
            },
            bankAccount: e.clinic.bankAccount === null || e.clinic.bankAccount === undefined ? null : {
                id: e.clinic.bankAccount.id,
                bankCode: e.clinic.bankAccount.bankCode,
                agency: e.clinic.bankAccount.agency,
                agencyDv: e.clinic.bankAccount.agencyDv === null || e.clinic.bankAccount.agencyDv === undefined ? null : e.clinic.bankAccount.agencyDv,
                account: e.clinic.bankAccount.account,
                accountDv: e.clinic.bankAccount.accountDv === null || e.clinic.bankAccount.accountDv === undefined ? null : e.clinic.bankAccount.accountDv,
                type: e.clinic.bankAccount.type,
                documentNumber: e.clinic.bankAccount.documentNumber,
            },
            blockedAt: e.clinic.blockedAt === null || e.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinic.blockedAt.split("-")[2], 10)),
            phone: e.clinic.phone,
            whatsapp: e.clinic.whatsapp,
            technicalManager: e.clinic.technicalManager,
            crm: e.clinic.crm,
            image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            createdAt: new Date(e.clinic.createdAt + "Z"),
        },
        price: e.price || 0,
    }));
}

export async function getTotalAmountOfClinicPackagesByClinic(id: string, progress?: (progress: number) => void): Promise<number> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getTotalAmountOfClinicPackagesByClinic", args, progress});
    return ret || 0;
}

export async function createClinicPackage(newClinicService: NewClinicPackage, progress?: (progress: number) => void): Promise<ClinicPackage> {
    const args = {
        newClinicService: {
            clinicId: newClinicService.clinicId,
            packageId: newClinicService.packageId,
            price: newClinicService.price || 0,
        },
    };
    const ret = await makeRequest({name: "createClinicPackage", args, progress});
    return {
        id: ret.id,
        package: {
            id: ret.package.id,
            name: ret.package.name,
            image: ret.package.image === null || ret.package.image === undefined ? null : {
                thumb: {
                    width: ret.package.image.thumb.width || 0,
                    height: ret.package.image.thumb.height || 0,
                    url: ret.package.image.thumb.url,
                },
                width: ret.package.image.width || 0,
                height: ret.package.image.height || 0,
                url: ret.package.image.url,
            },
            speciality: ret.package.speciality,
            medicalProcedures: ret.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(ret.package.createdAt + "Z"),
        },
        clinic: {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        price: ret.price || 0,
    };
}

export async function editClinicPackage(id: string, editClinicService: EditClinicPackage, progress?: (progress: number) => void): Promise<ClinicPackage> {
    const args = {
        id: id,
        editClinicService: {
            package: {
                id: editClinicService.package.id,
                name: editClinicService.package.name,
                image: editClinicService.package.image === null || editClinicService.package.image === undefined ? null : {
                    thumb: {
                        width: editClinicService.package.image.thumb.width || 0,
                        height: editClinicService.package.image.thumb.height || 0,
                        url: editClinicService.package.image.thumb.url,
                    },
                    width: editClinicService.package.image.width || 0,
                    height: editClinicService.package.image.height || 0,
                    url: editClinicService.package.image.url,
                },
                speciality: editClinicService.package.speciality,
                medicalProcedures: editClinicService.package.medicalProcedures.map(e => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: (typeof editClinicService.package.createdAt === "string" && (editClinicService.package.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editClinicService.package.createdAt as any).replace("Z", "") : editClinicService.package.createdAt.toISOString().replace("Z", "")),
            },
            clinic: {
                id: editClinicService.clinic.id,
                name: editClinicService.clinic.name,
                description: editClinicService.clinic.description,
                specialities: editClinicService.clinic.specialities.map(e => e),
                serviceDays: editClinicService.clinic.serviceDays,
                address: {
                    zipcode: editClinicService.clinic.address.zipcode,
                    street: editClinicService.clinic.address.street,
                    streetNumber: editClinicService.clinic.address.streetNumber,
                    complementary: editClinicService.clinic.address.complementary === null || editClinicService.clinic.address.complementary === undefined ? null : editClinicService.clinic.address.complementary,
                    neighborhood: editClinicService.clinic.address.neighborhood,
                    city: editClinicService.clinic.address.city,
                    state: editClinicService.clinic.address.state,
                    countryCode: editClinicService.clinic.address.countryCode,
                    latLng: {
                        lat: editClinicService.clinic.address.latLng.lat,
                        lng: editClinicService.clinic.address.latLng.lng,
                    },
                },
                bankAccount: editClinicService.clinic.bankAccount === null || editClinicService.clinic.bankAccount === undefined ? null : {
                    id: editClinicService.clinic.bankAccount.id,
                    bankCode: editClinicService.clinic.bankAccount.bankCode,
                    agency: editClinicService.clinic.bankAccount.agency,
                    agencyDv: editClinicService.clinic.bankAccount.agencyDv === null || editClinicService.clinic.bankAccount.agencyDv === undefined ? null : editClinicService.clinic.bankAccount.agencyDv,
                    account: editClinicService.clinic.bankAccount.account,
                    accountDv: editClinicService.clinic.bankAccount.accountDv === null || editClinicService.clinic.bankAccount.accountDv === undefined ? null : editClinicService.clinic.bankAccount.accountDv,
                    type: editClinicService.clinic.bankAccount.type,
                    documentNumber: editClinicService.clinic.bankAccount.documentNumber,
                },
                blockedAt: editClinicService.clinic.blockedAt === null || editClinicService.clinic.blockedAt === undefined ? null : typeof(editClinicService.clinic.blockedAt) === "string" ? new Date(new Date(editClinicService.clinic.blockedAt).getTime() - new Date(editClinicService.clinic.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editClinicService.clinic.blockedAt.getTime() - editClinicService.clinic.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                phone: editClinicService.clinic.phone,
                whatsapp: editClinicService.clinic.whatsapp,
                technicalManager: editClinicService.clinic.technicalManager,
                crm: editClinicService.clinic.crm,
                image: editClinicService.clinic.image === null || editClinicService.clinic.image === undefined ? null : {
                    thumb: {
                        width: editClinicService.clinic.image.thumb.width || 0,
                        height: editClinicService.clinic.image.thumb.height || 0,
                        url: editClinicService.clinic.image.thumb.url,
                    },
                    width: editClinicService.clinic.image.width || 0,
                    height: editClinicService.clinic.image.height || 0,
                    url: editClinicService.clinic.image.url,
                },
                createdAt: (typeof editClinicService.clinic.createdAt === "string" && (editClinicService.clinic.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editClinicService.clinic.createdAt as any).replace("Z", "") : editClinicService.clinic.createdAt.toISOString().replace("Z", "")),
            },
            price: editClinicService.price || 0,
        },
    };
    const ret = await makeRequest({name: "editClinicPackage", args, progress});
    return {
        id: ret.id,
        package: {
            id: ret.package.id,
            name: ret.package.name,
            image: ret.package.image === null || ret.package.image === undefined ? null : {
                thumb: {
                    width: ret.package.image.thumb.width || 0,
                    height: ret.package.image.thumb.height || 0,
                    url: ret.package.image.thumb.url,
                },
                width: ret.package.image.width || 0,
                height: ret.package.image.height || 0,
                url: ret.package.image.url,
            },
            speciality: ret.package.speciality,
            medicalProcedures: ret.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(ret.package.createdAt + "Z"),
        },
        clinic: {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        price: ret.price || 0,
    };
}

export async function deleteClinicPackage(id: string, progress?: (progress: number) => void): Promise<ClinicPackage> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteClinicPackage", args, progress});
    return {
        id: ret.id,
        package: {
            id: ret.package.id,
            name: ret.package.name,
            image: ret.package.image === null || ret.package.image === undefined ? null : {
                thumb: {
                    width: ret.package.image.thumb.width || 0,
                    height: ret.package.image.thumb.height || 0,
                    url: ret.package.image.thumb.url,
                },
                width: ret.package.image.width || 0,
                height: ret.package.image.height || 0,
                url: ret.package.image.url,
            },
            speciality: ret.package.speciality,
            medicalProcedures: ret.package.medicalProcedures.map((e: any) => ({
                id: e.id,
                name: e.name,
                description: e.description,
            })),
            createdAt: new Date(ret.package.createdAt + "Z"),
        },
        clinic: {
            id: ret.clinic.id,
            name: ret.clinic.name,
            description: ret.clinic.description,
            specialities: ret.clinic.specialities.map((e: any) => e),
            serviceDays: ret.clinic.serviceDays,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary === null || ret.clinic.address.complementary === undefined ? null : ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
                countryCode: ret.clinic.address.countryCode,
                latLng: {
                    lat: ret.clinic.address.latLng.lat,
                    lng: ret.clinic.address.latLng.lng,
                },
            },
            bankAccount: ret.clinic.bankAccount === null || ret.clinic.bankAccount === undefined ? null : {
                id: ret.clinic.bankAccount.id,
                bankCode: ret.clinic.bankAccount.bankCode,
                agency: ret.clinic.bankAccount.agency,
                agencyDv: ret.clinic.bankAccount.agencyDv === null || ret.clinic.bankAccount.agencyDv === undefined ? null : ret.clinic.bankAccount.agencyDv,
                account: ret.clinic.bankAccount.account,
                accountDv: ret.clinic.bankAccount.accountDv === null || ret.clinic.bankAccount.accountDv === undefined ? null : ret.clinic.bankAccount.accountDv,
                type: ret.clinic.bankAccount.type,
                documentNumber: ret.clinic.bankAccount.documentNumber,
            },
            blockedAt: ret.clinic.blockedAt === null || ret.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinic.blockedAt.split("-")[2], 10)),
            phone: ret.clinic.phone,
            whatsapp: ret.clinic.whatsapp,
            technicalManager: ret.clinic.technicalManager,
            crm: ret.clinic.crm,
            image: ret.clinic.image === null || ret.clinic.image === undefined ? null : {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            createdAt: new Date(ret.clinic.createdAt + "Z"),
        },
        price: ret.price || 0,
    };
}

export async function getHomeStatisticsData(filterOptions: PeriodFilterOptions, progress?: (progress: number) => void): Promise<HomeData> {
    const args = {
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getHomeStatisticsData", args, progress});
    return {
        totalAmountOfClinicPackages: ret.totalAmountOfClinicPackages === null || ret.totalAmountOfClinicPackages === undefined ? null : ret.totalAmountOfClinicPackages || 0,
        totalAmountOfClinics: ret.totalAmountOfClinics === null || ret.totalAmountOfClinics === undefined ? null : ret.totalAmountOfClinics || 0,
        totalAmountOfProfessionalsByClinicId: ret.totalAmountOfProfessionalsByClinicId === null || ret.totalAmountOfProfessionalsByClinicId === undefined ? null : ret.totalAmountOfProfessionalsByClinicId || 0,
        totalAmountOfPackages: ret.totalAmountOfPackages === null || ret.totalAmountOfPackages === undefined ? null : ret.totalAmountOfPackages || 0,
        topTenPackages: ret.topTenPackages === null || ret.topTenPackages === undefined ? null : ret.topTenPackages.map((e: any) => ({
            package: {
                id: e.package.id,
                name: e.package.name,
                speciality: e.package.speciality,
                createdAt: new Date(e.package.createdAt + "Z"),
            },
            count: e.count || 0,
        })),
        topTenHighestBillingClinicPackages: ret.topTenHighestBillingClinicPackages === null || ret.topTenHighestBillingClinicPackages === undefined ? null : ret.topTenHighestBillingClinicPackages.map((e: any) => ({
            package: {
                id: e.package.id,
                name: e.package.name,
                speciality: e.package.speciality,
                createdAt: new Date(e.package.createdAt + "Z"),
            },
            revenue: e.revenue || 0,
            id: e.id,
            price: e.price || 0,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        topTenClinicPackages: ret.topTenClinicPackages === null || ret.topTenClinicPackages === undefined ? null : ret.topTenClinicPackages.map((e: any) => ({
            package: {
                id: e.package.id,
                name: e.package.name,
                speciality: e.package.speciality,
                createdAt: new Date(e.package.createdAt + "Z"),
            },
            count: e.count || 0,
        })),
        topTenHighestBillingPackages: ret.topTenHighestBillingPackages === null || ret.topTenHighestBillingPackages === undefined ? null : ret.topTenHighestBillingPackages.map((e: any) => ({
            package: {
                id: e.package.id,
                name: e.package.name,
                speciality: e.package.speciality,
                createdAt: new Date(e.package.createdAt + "Z"),
            },
            revenue: e.revenue || 0,
        })),
        topTenClinicsWithTheHighestSales: ret.topTenClinicsWithTheHighestSales === null || ret.topTenClinicsWithTheHighestSales === undefined ? null : ret.topTenClinicsWithTheHighestSales.map((e: any) => ({
            clinic: {
                id: e.clinic.id,
                name: e.clinic.name,
                description: e.clinic.description,
                specialities: e.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinic.serviceDays,
                phone: e.clinic.phone,
                whatsapp: e.clinic.whatsapp,
                technicalManager: e.clinic.technicalManager,
                crm: e.clinic.crm,
                image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinic.image.thumb.width || 0,
                        height: e.clinic.image.thumb.height || 0,
                        url: e.clinic.image.thumb.url,
                    },
                    width: e.clinic.image.width || 0,
                    height: e.clinic.image.height || 0,
                    url: e.clinic.image.url,
                },
                createdAt: new Date(e.clinic.createdAt + "Z"),
            },
            amountOfSales: e.amountOfSales || 0,
        })),
        topTenClinicsWithHighestBilling: ret.topTenClinicsWithHighestBilling === null || ret.topTenClinicsWithHighestBilling === undefined ? null : ret.topTenClinicsWithHighestBilling.map((e: any) => ({
            clinic: {
                id: e.clinic.id,
                name: e.clinic.name,
                description: e.clinic.description,
                specialities: e.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinic.serviceDays,
                phone: e.clinic.phone,
                whatsapp: e.clinic.whatsapp,
                technicalManager: e.clinic.technicalManager,
                crm: e.clinic.crm,
                image: e.clinic.image === null || e.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinic.image.thumb.width || 0,
                        height: e.clinic.image.thumb.height || 0,
                        url: e.clinic.image.thumb.url,
                    },
                    width: e.clinic.image.width || 0,
                    height: e.clinic.image.height || 0,
                    url: e.clinic.image.url,
                },
                createdAt: new Date(e.clinic.createdAt + "Z"),
            },
            revenue: e.revenue || 0,
        })),
        totalBilling: ret.totalBilling || 0,
        averageBilling: ret.averageBilling || 0,
        totalAmountOfUsers: ret.totalAmountOfUsers || 0,
    };
}

export async function getHomeGraphicsDataPerDate(graphicType: GraphicType, filterOptions: PeriodFilterOptions, progress?: (progress: number) => void): Promise<GraphicDataPerDate> {
    const args = {
        graphicType: graphicType,
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getHomeGraphicsDataPerDate", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            date: new Date(e.date + "Z"),
        })),
    };
}

export async function getHomeGraphicsDataPerDateHour(graphicType: GraphicType, filterOptions: PeriodFilterOptions, progress?: (progress: number) => void): Promise<GraphicDataPerDateHour> {
    const args = {
        graphicType: graphicType,
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getHomeGraphicsDataPerDateHour", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            dateHour: new Date(e.dateHour + "Z"),
        })),
    };
}

export async function getHomeGraphicsDataPerHour(graphicType: GraphicType, filterOptions: PeriodFilterOptions, progress?: (progress: number) => void): Promise<GraphicDataPerHour> {
    const args = {
        graphicType: graphicType,
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getHomeGraphicsDataPerHour", args, progress});
    return {
        sales: ret.sales.map((e: any) => ({
            value: e.value || 0,
            hour: e.hour || 0,
        })),
    };
}

export async function getMedicalProcedure(id: string, progress?: (progress: number) => void): Promise<MedicalProcedure> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMedicalProcedure", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
    };
}

export async function getAllMedicalProcedures(pageOffset: number, progress?: (progress: number) => void): Promise<MedicalProcedure[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllMedicalProcedures", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
    }));
}

export async function getTotalNumberOfMedicalProcedures(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberOfMedicalProcedures", args: {}, progress});
    return ret || 0;
}

export async function createMedicalProcedure(newClinicService: NewMedicalProcedure, progress?: (progress: number) => void): Promise<MedicalProcedure> {
    const args = {
        newClinicService: {
            name: newClinicService.name,
            description: newClinicService.description,
        },
    };
    const ret = await makeRequest({name: "createMedicalProcedure", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
    };
}

export async function editMedicalProcedure(id: string, editClinicService: EditMedicalProcedure, progress?: (progress: number) => void): Promise<MedicalProcedure> {
    const args = {
        id: id,
        editClinicService: {
            name: editClinicService.name,
            description: editClinicService.description,
        },
    };
    const ret = await makeRequest({name: "editMedicalProcedure", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
    };
}

export async function deleteMedicalProcedure(id: string, progress?: (progress: number) => void): Promise<MedicalProcedure> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteMedicalProcedure", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
    };
}

export async function autoCompleteMedicalProcedures(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<MedicalProcedure[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompleteMedicalProcedures", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
    }));
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        name: ret.name,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getTotalNumberOfUsers(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberOfUsers", args: {}, progress});
    return ret || 0;
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            name: editUser.name,
            phone: editUser.phone,
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
            documentNumber: editUser.documentNumber === null || editUser.documentNumber === undefined ? null : editUser.documentNumber,
            address: editUser.address === null || editUser.address === undefined ? null : {
                zipcode: editUser.address.zipcode,
                street: editUser.address.street,
                streetNumber: editUser.address.streetNumber,
                complementary: editUser.address.complementary === null || editUser.address.complementary === undefined ? null : editUser.address.complementary,
                neighborhood: editUser.address.neighborhood,
                city: editUser.address.city,
                state: editUser.address.state,
                countryCode: editUser.address.countryCode,
            },
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        name: ret.name,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            email: newUser.email,
            password: newUser.password,
            address: newUser.address === null || newUser.address === undefined ? null : {
                zipcode: newUser.address.zipcode,
                street: newUser.address.street,
                streetNumber: newUser.address.streetNumber,
                complementary: newUser.address.complementary === null || newUser.address.complementary === undefined ? null : newUser.address.complementary,
                neighborhood: newUser.address.neighborhood,
                city: newUser.address.city,
                state: newUser.address.state,
                countryCode: newUser.address.countryCode,
            },
            documentNumber: newUser.documentNumber === null || newUser.documentNumber === undefined ? null : newUser.documentNumber,
            name: newUser.name,
            phone: newUser.phone,
            image: newUser.image === null || newUser.image === undefined ? null : {
                bytes: newUser.image.bytes === null || newUser.image.bytes === undefined ? null : newUser.image.bytes.toString("base64"),
                image: newUser.image.image === null || newUser.image.image === undefined ? null : {
                    thumb: {
                        width: newUser.image.image.thumb.width || 0,
                        height: newUser.image.image.thumb.height || 0,
                        url: newUser.image.image.thumb.url,
                    },
                    width: newUser.image.image.width || 0,
                    height: newUser.image.image.height || 0,
                    url: newUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        name: ret.name,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
        },
        name: e.name,
        phone: e.phone,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function changeUserBlockStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeUserBlockStatus", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        name: ret.name,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        name: ret.name,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getProfessional(id: string, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProfessional", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function getAllProfessionals(pageOffset: number, progress?: (progress: number) => void): Promise<Professional[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllProfessionals", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        ccmOrIss: e.ccmOrIss === null || e.ccmOrIss === undefined ? null : e.ccmOrIss,
        cnes: e.cnes === null || e.cnes === undefined ? null : e.cnes,
        resume: e.resume === null || e.resume === undefined ? null : e.resume,
        identificationNumber: e.identificationNumber,
        documentNumber: e.documentNumber,
        description: e.description,
        specialities: e.specialities.map((e: any) => e),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
    }));
}

export async function getTotalNumberOfProfessionals(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberOfProfessionals", args: {}, progress});
    return ret || 0;
}

export async function getAllProfessionalsByClinicId(pageOffset: number, clinicId: string, progress?: (progress: number) => void): Promise<Professional[]> {
    const args = {
        pageOffset: pageOffset || 0,
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "getAllProfessionalsByClinicId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        ccmOrIss: e.ccmOrIss === null || e.ccmOrIss === undefined ? null : e.ccmOrIss,
        cnes: e.cnes === null || e.cnes === undefined ? null : e.cnes,
        resume: e.resume === null || e.resume === undefined ? null : e.resume,
        identificationNumber: e.identificationNumber,
        documentNumber: e.documentNumber,
        description: e.description,
        specialities: e.specialities.map((e: any) => e),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
    }));
}

export async function editProfessional(id: string, editProfessional: EditProfessional, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
        editProfessional: {
            name: editProfessional.name,
            image: editProfessional.image === null || editProfessional.image === undefined ? null : {
                bytes: editProfessional.image.bytes === null || editProfessional.image.bytes === undefined ? null : editProfessional.image.bytes.toString("base64"),
                image: editProfessional.image.image === null || editProfessional.image.image === undefined ? null : {
                    thumb: {
                        width: editProfessional.image.image.thumb.width || 0,
                        height: editProfessional.image.image.thumb.height || 0,
                        url: editProfessional.image.image.thumb.url,
                    },
                    width: editProfessional.image.image.width || 0,
                    height: editProfessional.image.image.height || 0,
                    url: editProfessional.image.image.url,
                },
            },
            ccmOrIss: editProfessional.ccmOrIss === null || editProfessional.ccmOrIss === undefined ? null : {
                bytes: editProfessional.ccmOrIss.bytes === null || editProfessional.ccmOrIss.bytes === undefined ? null : editProfessional.ccmOrIss.bytes.toString("base64"),
                url: editProfessional.ccmOrIss.url === null || editProfessional.ccmOrIss.url === undefined ? null : editProfessional.ccmOrIss.url,
            },
            cnes: editProfessional.cnes === null || editProfessional.cnes === undefined ? null : {
                bytes: editProfessional.cnes.bytes === null || editProfessional.cnes.bytes === undefined ? null : editProfessional.cnes.bytes.toString("base64"),
                url: editProfessional.cnes.url === null || editProfessional.cnes.url === undefined ? null : editProfessional.cnes.url,
            },
            resume: editProfessional.resume === null || editProfessional.resume === undefined ? null : {
                bytes: editProfessional.resume.bytes === null || editProfessional.resume.bytes === undefined ? null : editProfessional.resume.bytes.toString("base64"),
                url: editProfessional.resume.url === null || editProfessional.resume.url === undefined ? null : editProfessional.resume.url,
            },
            identificationNumber: editProfessional.identificationNumber,
            documentNumber: editProfessional.documentNumber,
            description: editProfessional.description,
            specialities: editProfessional.specialities.map(e => e),
        },
    };
    const ret = await makeRequest({name: "editProfessional", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function createProfessional(newProfessional: NewProfessional, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        newProfessional: {
            name: newProfessional.name,
            image: newProfessional.image === null || newProfessional.image === undefined ? null : {
                bytes: newProfessional.image.bytes === null || newProfessional.image.bytes === undefined ? null : newProfessional.image.bytes.toString("base64"),
                image: newProfessional.image.image === null || newProfessional.image.image === undefined ? null : {
                    thumb: {
                        width: newProfessional.image.image.thumb.width || 0,
                        height: newProfessional.image.image.thumb.height || 0,
                        url: newProfessional.image.image.thumb.url,
                    },
                    width: newProfessional.image.image.width || 0,
                    height: newProfessional.image.image.height || 0,
                    url: newProfessional.image.image.url,
                },
            },
            ccmOrIss: newProfessional.ccmOrIss === null || newProfessional.ccmOrIss === undefined ? null : {
                bytes: newProfessional.ccmOrIss.bytes === null || newProfessional.ccmOrIss.bytes === undefined ? null : newProfessional.ccmOrIss.bytes.toString("base64"),
                url: newProfessional.ccmOrIss.url === null || newProfessional.ccmOrIss.url === undefined ? null : newProfessional.ccmOrIss.url,
            },
            cnes: newProfessional.cnes === null || newProfessional.cnes === undefined ? null : {
                bytes: newProfessional.cnes.bytes === null || newProfessional.cnes.bytes === undefined ? null : newProfessional.cnes.bytes.toString("base64"),
                url: newProfessional.cnes.url === null || newProfessional.cnes.url === undefined ? null : newProfessional.cnes.url,
            },
            resume: newProfessional.resume === null || newProfessional.resume === undefined ? null : {
                bytes: newProfessional.resume.bytes === null || newProfessional.resume.bytes === undefined ? null : newProfessional.resume.bytes.toString("base64"),
                url: newProfessional.resume.url === null || newProfessional.resume.url === undefined ? null : newProfessional.resume.url,
            },
            identificationNumber: newProfessional.identificationNumber,
            documentNumber: newProfessional.documentNumber,
            description: newProfessional.description,
            specialities: newProfessional.specialities.map(e => e),
        },
    };
    const ret = await makeRequest({name: "createProfessional", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function deleteProfessional(id: string, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteProfessional", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function changeProfessionalBlockStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<Professional> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeProfessionalBlockStatus", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        ccmOrIss: ret.ccmOrIss === null || ret.ccmOrIss === undefined ? null : ret.ccmOrIss,
        cnes: ret.cnes === null || ret.cnes === undefined ? null : ret.cnes,
        resume: ret.resume === null || ret.resume === undefined ? null : ret.resume,
        identificationNumber: ret.identificationNumber,
        documentNumber: ret.documentNumber,
        description: ret.description,
        specialities: ret.specialities.map((e: any) => e),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function autoCompleteProfessionals(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<Professional[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompleteProfessionals", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        ccmOrIss: e.ccmOrIss === null || e.ccmOrIss === undefined ? null : e.ccmOrIss,
        cnes: e.cnes === null || e.cnes === undefined ? null : e.cnes,
        resume: e.resume === null || e.resume === undefined ? null : e.resume,
        identificationNumber: e.identificationNumber,
        documentNumber: e.documentNumber,
        description: e.description,
        specialities: e.specialities.map((e: any) => e),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
    }));
}

export async function getOrder(id: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getOrder", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            name: ret.user.name,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(parseInt(ret.user.blockedAt.split("-")[0], 10), parseInt(ret.user.blockedAt.split("-")[1], 10) - 1, parseInt(ret.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: ret.clinicPackage.id,
            package: {
                id: ret.clinicPackage.package.id,
                name: ret.clinicPackage.package.name,
                image: ret.clinicPackage.package.image === null || ret.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.package.image.thumb.width || 0,
                        height: ret.clinicPackage.package.image.thumb.height || 0,
                        url: ret.clinicPackage.package.image.thumb.url,
                    },
                    width: ret.clinicPackage.package.image.width || 0,
                    height: ret.clinicPackage.package.image.height || 0,
                    url: ret.clinicPackage.package.image.url,
                },
                speciality: ret.clinicPackage.package.speciality,
                medicalProcedures: ret.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(ret.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: ret.clinicPackage.clinic.id,
                name: ret.clinicPackage.clinic.name,
                description: ret.clinicPackage.clinic.description,
                specialities: ret.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: ret.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: ret.clinicPackage.clinic.address.zipcode,
                    street: ret.clinicPackage.clinic.address.street,
                    streetNumber: ret.clinicPackage.clinic.address.streetNumber,
                    complementary: ret.clinicPackage.clinic.address.complementary === null || ret.clinicPackage.clinic.address.complementary === undefined ? null : ret.clinicPackage.clinic.address.complementary,
                    neighborhood: ret.clinicPackage.clinic.address.neighborhood,
                    city: ret.clinicPackage.clinic.address.city,
                    state: ret.clinicPackage.clinic.address.state,
                    countryCode: ret.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: ret.clinicPackage.clinic.address.latLng.lat,
                        lng: ret.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: ret.clinicPackage.clinic.bankAccount === null || ret.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: ret.clinicPackage.clinic.bankAccount.id,
                    bankCode: ret.clinicPackage.clinic.bankAccount.bankCode,
                    agency: ret.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: ret.clinicPackage.clinic.bankAccount.agencyDv === null || ret.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.agencyDv,
                    account: ret.clinicPackage.clinic.bankAccount.account,
                    accountDv: ret.clinicPackage.clinic.bankAccount.accountDv === null || ret.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.accountDv,
                    type: ret.clinicPackage.clinic.bankAccount.type,
                    documentNumber: ret.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: ret.clinicPackage.clinic.blockedAt === null || ret.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: ret.clinicPackage.clinic.phone,
                whatsapp: ret.clinicPackage.clinic.whatsapp,
                technicalManager: ret.clinicPackage.clinic.technicalManager,
                crm: ret.clinicPackage.clinic.crm,
                image: ret.clinicPackage.clinic.image === null || ret.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.clinic.image.thumb.width || 0,
                        height: ret.clinicPackage.clinic.image.thumb.height || 0,
                        url: ret.clinicPackage.clinic.image.thumb.url,
                    },
                    width: ret.clinicPackage.clinic.image.width || 0,
                    height: ret.clinicPackage.clinic.image.height || 0,
                    url: ret.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(ret.clinicPackage.clinic.createdAt + "Z"),
            },
            price: ret.clinicPackage.price || 0,
        },
        professional: ret.professional === null || ret.professional === undefined ? null : {
            id: ret.professional.id,
            name: ret.professional.name,
            image: ret.professional.image === null || ret.professional.image === undefined ? null : {
                thumb: {
                    width: ret.professional.image.thumb.width || 0,
                    height: ret.professional.image.thumb.height || 0,
                    url: ret.professional.image.thumb.url,
                },
                width: ret.professional.image.width || 0,
                height: ret.professional.image.height || 0,
                url: ret.professional.image.url,
            },
            ccmOrIss: ret.professional.ccmOrIss === null || ret.professional.ccmOrIss === undefined ? null : ret.professional.ccmOrIss,
            cnes: ret.professional.cnes === null || ret.professional.cnes === undefined ? null : ret.professional.cnes,
            resume: ret.professional.resume === null || ret.professional.resume === undefined ? null : ret.professional.resume,
            identificationNumber: ret.professional.identificationNumber,
            documentNumber: ret.professional.documentNumber,
            description: ret.professional.description,
            specialities: ret.professional.specialities.map((e: any) => e),
            blockedAt: ret.professional.blockedAt === null || ret.professional.blockedAt === undefined ? null : new Date(parseInt(ret.professional.blockedAt.split("-")[0], 10), parseInt(ret.professional.blockedAt.split("-")[1], 10) - 1, parseInt(ret.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: ret.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: ret.confirmedDate === null || ret.confirmedDate === undefined ? null : new Date(ret.confirmedDate + "Z"),
        suggestedHours: ret.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: ret.confirmedHour === null || ret.confirmedHour === undefined ? null : new Date(ret.confirmedHour + "Z"),
        transactionId: ret.transactionId === null || ret.transactionId === undefined ? null : ret.transactionId,
        paymentMethod: ret.paymentMethod,
        paymentStatus: ret.paymentStatus,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllOrders(pageOffset: number, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllOrders", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            name: e.user.name,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(parseInt(e.user.blockedAt.split("-")[0], 10), parseInt(e.user.blockedAt.split("-")[1], 10) - 1, parseInt(e.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: e.clinicPackage.id,
            package: {
                id: e.clinicPackage.package.id,
                name: e.clinicPackage.package.name,
                image: e.clinicPackage.package.image === null || e.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.package.image.thumb.width || 0,
                        height: e.clinicPackage.package.image.thumb.height || 0,
                        url: e.clinicPackage.package.image.thumb.url,
                    },
                    width: e.clinicPackage.package.image.width || 0,
                    height: e.clinicPackage.package.image.height || 0,
                    url: e.clinicPackage.package.image.url,
                },
                speciality: e.clinicPackage.package.speciality,
                medicalProcedures: e.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(e.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: e.clinicPackage.clinic.id,
                name: e.clinicPackage.clinic.name,
                description: e.clinicPackage.clinic.description,
                specialities: e.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: e.clinicPackage.clinic.address.zipcode,
                    street: e.clinicPackage.clinic.address.street,
                    streetNumber: e.clinicPackage.clinic.address.streetNumber,
                    complementary: e.clinicPackage.clinic.address.complementary === null || e.clinicPackage.clinic.address.complementary === undefined ? null : e.clinicPackage.clinic.address.complementary,
                    neighborhood: e.clinicPackage.clinic.address.neighborhood,
                    city: e.clinicPackage.clinic.address.city,
                    state: e.clinicPackage.clinic.address.state,
                    countryCode: e.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: e.clinicPackage.clinic.address.latLng.lat,
                        lng: e.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: e.clinicPackage.clinic.bankAccount === null || e.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: e.clinicPackage.clinic.bankAccount.id,
                    bankCode: e.clinicPackage.clinic.bankAccount.bankCode,
                    agency: e.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: e.clinicPackage.clinic.bankAccount.agencyDv === null || e.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : e.clinicPackage.clinic.bankAccount.agencyDv,
                    account: e.clinicPackage.clinic.bankAccount.account,
                    accountDv: e.clinicPackage.clinic.bankAccount.accountDv === null || e.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : e.clinicPackage.clinic.bankAccount.accountDv,
                    type: e.clinicPackage.clinic.bankAccount.type,
                    documentNumber: e.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: e.clinicPackage.clinic.blockedAt === null || e.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: e.clinicPackage.clinic.phone,
                whatsapp: e.clinicPackage.clinic.whatsapp,
                technicalManager: e.clinicPackage.clinic.technicalManager,
                crm: e.clinicPackage.clinic.crm,
                image: e.clinicPackage.clinic.image === null || e.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.clinic.image.thumb.width || 0,
                        height: e.clinicPackage.clinic.image.thumb.height || 0,
                        url: e.clinicPackage.clinic.image.thumb.url,
                    },
                    width: e.clinicPackage.clinic.image.width || 0,
                    height: e.clinicPackage.clinic.image.height || 0,
                    url: e.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(e.clinicPackage.clinic.createdAt + "Z"),
            },
            price: e.clinicPackage.price || 0,
        },
        professional: e.professional === null || e.professional === undefined ? null : {
            id: e.professional.id,
            name: e.professional.name,
            image: e.professional.image === null || e.professional.image === undefined ? null : {
                thumb: {
                    width: e.professional.image.thumb.width || 0,
                    height: e.professional.image.thumb.height || 0,
                    url: e.professional.image.thumb.url,
                },
                width: e.professional.image.width || 0,
                height: e.professional.image.height || 0,
                url: e.professional.image.url,
            },
            ccmOrIss: e.professional.ccmOrIss === null || e.professional.ccmOrIss === undefined ? null : e.professional.ccmOrIss,
            cnes: e.professional.cnes === null || e.professional.cnes === undefined ? null : e.professional.cnes,
            resume: e.professional.resume === null || e.professional.resume === undefined ? null : e.professional.resume,
            identificationNumber: e.professional.identificationNumber,
            documentNumber: e.professional.documentNumber,
            description: e.professional.description,
            specialities: e.professional.specialities.map((e: any) => e),
            blockedAt: e.professional.blockedAt === null || e.professional.blockedAt === undefined ? null : new Date(parseInt(e.professional.blockedAt.split("-")[0], 10), parseInt(e.professional.blockedAt.split("-")[1], 10) - 1, parseInt(e.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: e.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: e.confirmedDate === null || e.confirmedDate === undefined ? null : new Date(e.confirmedDate + "Z"),
        suggestedHours: e.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: e.confirmedHour === null || e.confirmedHour === undefined ? null : new Date(e.confirmedHour + "Z"),
        transactionId: e.transactionId === null || e.transactionId === undefined ? null : e.transactionId,
        paymentMethod: e.paymentMethod,
        paymentStatus: e.paymentStatus,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllPendingOrders(pageOffset: number, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingOrders", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            name: e.user.name,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(parseInt(e.user.blockedAt.split("-")[0], 10), parseInt(e.user.blockedAt.split("-")[1], 10) - 1, parseInt(e.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: e.clinicPackage.id,
            package: {
                id: e.clinicPackage.package.id,
                name: e.clinicPackage.package.name,
                image: e.clinicPackage.package.image === null || e.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.package.image.thumb.width || 0,
                        height: e.clinicPackage.package.image.thumb.height || 0,
                        url: e.clinicPackage.package.image.thumb.url,
                    },
                    width: e.clinicPackage.package.image.width || 0,
                    height: e.clinicPackage.package.image.height || 0,
                    url: e.clinicPackage.package.image.url,
                },
                speciality: e.clinicPackage.package.speciality,
                medicalProcedures: e.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(e.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: e.clinicPackage.clinic.id,
                name: e.clinicPackage.clinic.name,
                description: e.clinicPackage.clinic.description,
                specialities: e.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: e.clinicPackage.clinic.address.zipcode,
                    street: e.clinicPackage.clinic.address.street,
                    streetNumber: e.clinicPackage.clinic.address.streetNumber,
                    complementary: e.clinicPackage.clinic.address.complementary === null || e.clinicPackage.clinic.address.complementary === undefined ? null : e.clinicPackage.clinic.address.complementary,
                    neighborhood: e.clinicPackage.clinic.address.neighborhood,
                    city: e.clinicPackage.clinic.address.city,
                    state: e.clinicPackage.clinic.address.state,
                    countryCode: e.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: e.clinicPackage.clinic.address.latLng.lat,
                        lng: e.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: e.clinicPackage.clinic.bankAccount === null || e.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: e.clinicPackage.clinic.bankAccount.id,
                    bankCode: e.clinicPackage.clinic.bankAccount.bankCode,
                    agency: e.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: e.clinicPackage.clinic.bankAccount.agencyDv === null || e.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : e.clinicPackage.clinic.bankAccount.agencyDv,
                    account: e.clinicPackage.clinic.bankAccount.account,
                    accountDv: e.clinicPackage.clinic.bankAccount.accountDv === null || e.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : e.clinicPackage.clinic.bankAccount.accountDv,
                    type: e.clinicPackage.clinic.bankAccount.type,
                    documentNumber: e.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: e.clinicPackage.clinic.blockedAt === null || e.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: e.clinicPackage.clinic.phone,
                whatsapp: e.clinicPackage.clinic.whatsapp,
                technicalManager: e.clinicPackage.clinic.technicalManager,
                crm: e.clinicPackage.clinic.crm,
                image: e.clinicPackage.clinic.image === null || e.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.clinic.image.thumb.width || 0,
                        height: e.clinicPackage.clinic.image.thumb.height || 0,
                        url: e.clinicPackage.clinic.image.thumb.url,
                    },
                    width: e.clinicPackage.clinic.image.width || 0,
                    height: e.clinicPackage.clinic.image.height || 0,
                    url: e.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(e.clinicPackage.clinic.createdAt + "Z"),
            },
            price: e.clinicPackage.price || 0,
        },
        professional: e.professional === null || e.professional === undefined ? null : {
            id: e.professional.id,
            name: e.professional.name,
            image: e.professional.image === null || e.professional.image === undefined ? null : {
                thumb: {
                    width: e.professional.image.thumb.width || 0,
                    height: e.professional.image.thumb.height || 0,
                    url: e.professional.image.thumb.url,
                },
                width: e.professional.image.width || 0,
                height: e.professional.image.height || 0,
                url: e.professional.image.url,
            },
            ccmOrIss: e.professional.ccmOrIss === null || e.professional.ccmOrIss === undefined ? null : e.professional.ccmOrIss,
            cnes: e.professional.cnes === null || e.professional.cnes === undefined ? null : e.professional.cnes,
            resume: e.professional.resume === null || e.professional.resume === undefined ? null : e.professional.resume,
            identificationNumber: e.professional.identificationNumber,
            documentNumber: e.professional.documentNumber,
            description: e.professional.description,
            specialities: e.professional.specialities.map((e: any) => e),
            blockedAt: e.professional.blockedAt === null || e.professional.blockedAt === undefined ? null : new Date(parseInt(e.professional.blockedAt.split("-")[0], 10), parseInt(e.professional.blockedAt.split("-")[1], 10) - 1, parseInt(e.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: e.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: e.confirmedDate === null || e.confirmedDate === undefined ? null : new Date(e.confirmedDate + "Z"),
        suggestedHours: e.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: e.confirmedHour === null || e.confirmedHour === undefined ? null : new Date(e.confirmedHour + "Z"),
        transactionId: e.transactionId === null || e.transactionId === undefined ? null : e.transactionId,
        paymentMethod: e.paymentMethod,
        paymentStatus: e.paymentStatus,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getTotalAmountOfOrders(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalAmountOfOrders", args: {}, progress});
    return ret || 0;
}

export async function getTotalAmountOfOrdersByClinic(clinicId: string, progress?: (progress: number) => void): Promise<number> {
    const args = {
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "getTotalAmountOfOrdersByClinic", args, progress});
    return ret || 0;
}

export async function getAllOrdersByClinic(clinicId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        clinicId: clinicId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllOrdersByClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            name: e.user.name,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(parseInt(e.user.blockedAt.split("-")[0], 10), parseInt(e.user.blockedAt.split("-")[1], 10) - 1, parseInt(e.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: e.clinicPackage.id,
            package: {
                id: e.clinicPackage.package.id,
                name: e.clinicPackage.package.name,
                image: e.clinicPackage.package.image === null || e.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.package.image.thumb.width || 0,
                        height: e.clinicPackage.package.image.thumb.height || 0,
                        url: e.clinicPackage.package.image.thumb.url,
                    },
                    width: e.clinicPackage.package.image.width || 0,
                    height: e.clinicPackage.package.image.height || 0,
                    url: e.clinicPackage.package.image.url,
                },
                speciality: e.clinicPackage.package.speciality,
                medicalProcedures: e.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(e.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: e.clinicPackage.clinic.id,
                name: e.clinicPackage.clinic.name,
                description: e.clinicPackage.clinic.description,
                specialities: e.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: e.clinicPackage.clinic.address.zipcode,
                    street: e.clinicPackage.clinic.address.street,
                    streetNumber: e.clinicPackage.clinic.address.streetNumber,
                    complementary: e.clinicPackage.clinic.address.complementary === null || e.clinicPackage.clinic.address.complementary === undefined ? null : e.clinicPackage.clinic.address.complementary,
                    neighborhood: e.clinicPackage.clinic.address.neighborhood,
                    city: e.clinicPackage.clinic.address.city,
                    state: e.clinicPackage.clinic.address.state,
                    countryCode: e.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: e.clinicPackage.clinic.address.latLng.lat,
                        lng: e.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: e.clinicPackage.clinic.bankAccount === null || e.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: e.clinicPackage.clinic.bankAccount.id,
                    bankCode: e.clinicPackage.clinic.bankAccount.bankCode,
                    agency: e.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: e.clinicPackage.clinic.bankAccount.agencyDv === null || e.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : e.clinicPackage.clinic.bankAccount.agencyDv,
                    account: e.clinicPackage.clinic.bankAccount.account,
                    accountDv: e.clinicPackage.clinic.bankAccount.accountDv === null || e.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : e.clinicPackage.clinic.bankAccount.accountDv,
                    type: e.clinicPackage.clinic.bankAccount.type,
                    documentNumber: e.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: e.clinicPackage.clinic.blockedAt === null || e.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: e.clinicPackage.clinic.phone,
                whatsapp: e.clinicPackage.clinic.whatsapp,
                technicalManager: e.clinicPackage.clinic.technicalManager,
                crm: e.clinicPackage.clinic.crm,
                image: e.clinicPackage.clinic.image === null || e.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.clinic.image.thumb.width || 0,
                        height: e.clinicPackage.clinic.image.thumb.height || 0,
                        url: e.clinicPackage.clinic.image.thumb.url,
                    },
                    width: e.clinicPackage.clinic.image.width || 0,
                    height: e.clinicPackage.clinic.image.height || 0,
                    url: e.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(e.clinicPackage.clinic.createdAt + "Z"),
            },
            price: e.clinicPackage.price || 0,
        },
        professional: e.professional === null || e.professional === undefined ? null : {
            id: e.professional.id,
            name: e.professional.name,
            image: e.professional.image === null || e.professional.image === undefined ? null : {
                thumb: {
                    width: e.professional.image.thumb.width || 0,
                    height: e.professional.image.thumb.height || 0,
                    url: e.professional.image.thumb.url,
                },
                width: e.professional.image.width || 0,
                height: e.professional.image.height || 0,
                url: e.professional.image.url,
            },
            ccmOrIss: e.professional.ccmOrIss === null || e.professional.ccmOrIss === undefined ? null : e.professional.ccmOrIss,
            cnes: e.professional.cnes === null || e.professional.cnes === undefined ? null : e.professional.cnes,
            resume: e.professional.resume === null || e.professional.resume === undefined ? null : e.professional.resume,
            identificationNumber: e.professional.identificationNumber,
            documentNumber: e.professional.documentNumber,
            description: e.professional.description,
            specialities: e.professional.specialities.map((e: any) => e),
            blockedAt: e.professional.blockedAt === null || e.professional.blockedAt === undefined ? null : new Date(parseInt(e.professional.blockedAt.split("-")[0], 10), parseInt(e.professional.blockedAt.split("-")[1], 10) - 1, parseInt(e.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: e.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: e.confirmedDate === null || e.confirmedDate === undefined ? null : new Date(e.confirmedDate + "Z"),
        suggestedHours: e.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: e.confirmedHour === null || e.confirmedHour === undefined ? null : new Date(e.confirmedHour + "Z"),
        transactionId: e.transactionId === null || e.transactionId === undefined ? null : e.transactionId,
        paymentMethod: e.paymentMethod,
        paymentStatus: e.paymentStatus,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllPendingOrdersByClinic(clinicId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        clinicId: clinicId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingOrdersByClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            name: e.user.name,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(parseInt(e.user.blockedAt.split("-")[0], 10), parseInt(e.user.blockedAt.split("-")[1], 10) - 1, parseInt(e.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: e.clinicPackage.id,
            package: {
                id: e.clinicPackage.package.id,
                name: e.clinicPackage.package.name,
                image: e.clinicPackage.package.image === null || e.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.package.image.thumb.width || 0,
                        height: e.clinicPackage.package.image.thumb.height || 0,
                        url: e.clinicPackage.package.image.thumb.url,
                    },
                    width: e.clinicPackage.package.image.width || 0,
                    height: e.clinicPackage.package.image.height || 0,
                    url: e.clinicPackage.package.image.url,
                },
                speciality: e.clinicPackage.package.speciality,
                medicalProcedures: e.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(e.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: e.clinicPackage.clinic.id,
                name: e.clinicPackage.clinic.name,
                description: e.clinicPackage.clinic.description,
                specialities: e.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: e.clinicPackage.clinic.address.zipcode,
                    street: e.clinicPackage.clinic.address.street,
                    streetNumber: e.clinicPackage.clinic.address.streetNumber,
                    complementary: e.clinicPackage.clinic.address.complementary === null || e.clinicPackage.clinic.address.complementary === undefined ? null : e.clinicPackage.clinic.address.complementary,
                    neighborhood: e.clinicPackage.clinic.address.neighborhood,
                    city: e.clinicPackage.clinic.address.city,
                    state: e.clinicPackage.clinic.address.state,
                    countryCode: e.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: e.clinicPackage.clinic.address.latLng.lat,
                        lng: e.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: e.clinicPackage.clinic.bankAccount === null || e.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: e.clinicPackage.clinic.bankAccount.id,
                    bankCode: e.clinicPackage.clinic.bankAccount.bankCode,
                    agency: e.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: e.clinicPackage.clinic.bankAccount.agencyDv === null || e.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : e.clinicPackage.clinic.bankAccount.agencyDv,
                    account: e.clinicPackage.clinic.bankAccount.account,
                    accountDv: e.clinicPackage.clinic.bankAccount.accountDv === null || e.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : e.clinicPackage.clinic.bankAccount.accountDv,
                    type: e.clinicPackage.clinic.bankAccount.type,
                    documentNumber: e.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: e.clinicPackage.clinic.blockedAt === null || e.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: e.clinicPackage.clinic.phone,
                whatsapp: e.clinicPackage.clinic.whatsapp,
                technicalManager: e.clinicPackage.clinic.technicalManager,
                crm: e.clinicPackage.clinic.crm,
                image: e.clinicPackage.clinic.image === null || e.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.clinic.image.thumb.width || 0,
                        height: e.clinicPackage.clinic.image.thumb.height || 0,
                        url: e.clinicPackage.clinic.image.thumb.url,
                    },
                    width: e.clinicPackage.clinic.image.width || 0,
                    height: e.clinicPackage.clinic.image.height || 0,
                    url: e.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(e.clinicPackage.clinic.createdAt + "Z"),
            },
            price: e.clinicPackage.price || 0,
        },
        professional: e.professional === null || e.professional === undefined ? null : {
            id: e.professional.id,
            name: e.professional.name,
            image: e.professional.image === null || e.professional.image === undefined ? null : {
                thumb: {
                    width: e.professional.image.thumb.width || 0,
                    height: e.professional.image.thumb.height || 0,
                    url: e.professional.image.thumb.url,
                },
                width: e.professional.image.width || 0,
                height: e.professional.image.height || 0,
                url: e.professional.image.url,
            },
            ccmOrIss: e.professional.ccmOrIss === null || e.professional.ccmOrIss === undefined ? null : e.professional.ccmOrIss,
            cnes: e.professional.cnes === null || e.professional.cnes === undefined ? null : e.professional.cnes,
            resume: e.professional.resume === null || e.professional.resume === undefined ? null : e.professional.resume,
            identificationNumber: e.professional.identificationNumber,
            documentNumber: e.professional.documentNumber,
            description: e.professional.description,
            specialities: e.professional.specialities.map((e: any) => e),
            blockedAt: e.professional.blockedAt === null || e.professional.blockedAt === undefined ? null : new Date(parseInt(e.professional.blockedAt.split("-")[0], 10), parseInt(e.professional.blockedAt.split("-")[1], 10) - 1, parseInt(e.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: e.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: e.confirmedDate === null || e.confirmedDate === undefined ? null : new Date(e.confirmedDate + "Z"),
        suggestedHours: e.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: e.confirmedHour === null || e.confirmedHour === undefined ? null : new Date(e.confirmedHour + "Z"),
        transactionId: e.transactionId === null || e.transactionId === undefined ? null : e.transactionId,
        paymentMethod: e.paymentMethod,
        paymentStatus: e.paymentStatus,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllOrdersByClinicWithoutPending(clinicId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        clinicId: clinicId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllOrdersByClinicWithoutPending", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            name: e.user.name,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(parseInt(e.user.blockedAt.split("-")[0], 10), parseInt(e.user.blockedAt.split("-")[1], 10) - 1, parseInt(e.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: e.clinicPackage.id,
            package: {
                id: e.clinicPackage.package.id,
                name: e.clinicPackage.package.name,
                image: e.clinicPackage.package.image === null || e.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.package.image.thumb.width || 0,
                        height: e.clinicPackage.package.image.thumb.height || 0,
                        url: e.clinicPackage.package.image.thumb.url,
                    },
                    width: e.clinicPackage.package.image.width || 0,
                    height: e.clinicPackage.package.image.height || 0,
                    url: e.clinicPackage.package.image.url,
                },
                speciality: e.clinicPackage.package.speciality,
                medicalProcedures: e.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(e.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: e.clinicPackage.clinic.id,
                name: e.clinicPackage.clinic.name,
                description: e.clinicPackage.clinic.description,
                specialities: e.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: e.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: e.clinicPackage.clinic.address.zipcode,
                    street: e.clinicPackage.clinic.address.street,
                    streetNumber: e.clinicPackage.clinic.address.streetNumber,
                    complementary: e.clinicPackage.clinic.address.complementary === null || e.clinicPackage.clinic.address.complementary === undefined ? null : e.clinicPackage.clinic.address.complementary,
                    neighborhood: e.clinicPackage.clinic.address.neighborhood,
                    city: e.clinicPackage.clinic.address.city,
                    state: e.clinicPackage.clinic.address.state,
                    countryCode: e.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: e.clinicPackage.clinic.address.latLng.lat,
                        lng: e.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: e.clinicPackage.clinic.bankAccount === null || e.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: e.clinicPackage.clinic.bankAccount.id,
                    bankCode: e.clinicPackage.clinic.bankAccount.bankCode,
                    agency: e.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: e.clinicPackage.clinic.bankAccount.agencyDv === null || e.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : e.clinicPackage.clinic.bankAccount.agencyDv,
                    account: e.clinicPackage.clinic.bankAccount.account,
                    accountDv: e.clinicPackage.clinic.bankAccount.accountDv === null || e.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : e.clinicPackage.clinic.bankAccount.accountDv,
                    type: e.clinicPackage.clinic.bankAccount.type,
                    documentNumber: e.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: e.clinicPackage.clinic.blockedAt === null || e.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(e.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(e.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(e.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: e.clinicPackage.clinic.phone,
                whatsapp: e.clinicPackage.clinic.whatsapp,
                technicalManager: e.clinicPackage.clinic.technicalManager,
                crm: e.clinicPackage.clinic.crm,
                image: e.clinicPackage.clinic.image === null || e.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: e.clinicPackage.clinic.image.thumb.width || 0,
                        height: e.clinicPackage.clinic.image.thumb.height || 0,
                        url: e.clinicPackage.clinic.image.thumb.url,
                    },
                    width: e.clinicPackage.clinic.image.width || 0,
                    height: e.clinicPackage.clinic.image.height || 0,
                    url: e.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(e.clinicPackage.clinic.createdAt + "Z"),
            },
            price: e.clinicPackage.price || 0,
        },
        professional: e.professional === null || e.professional === undefined ? null : {
            id: e.professional.id,
            name: e.professional.name,
            image: e.professional.image === null || e.professional.image === undefined ? null : {
                thumb: {
                    width: e.professional.image.thumb.width || 0,
                    height: e.professional.image.thumb.height || 0,
                    url: e.professional.image.thumb.url,
                },
                width: e.professional.image.width || 0,
                height: e.professional.image.height || 0,
                url: e.professional.image.url,
            },
            ccmOrIss: e.professional.ccmOrIss === null || e.professional.ccmOrIss === undefined ? null : e.professional.ccmOrIss,
            cnes: e.professional.cnes === null || e.professional.cnes === undefined ? null : e.professional.cnes,
            resume: e.professional.resume === null || e.professional.resume === undefined ? null : e.professional.resume,
            identificationNumber: e.professional.identificationNumber,
            documentNumber: e.professional.documentNumber,
            description: e.professional.description,
            specialities: e.professional.specialities.map((e: any) => e),
            blockedAt: e.professional.blockedAt === null || e.professional.blockedAt === undefined ? null : new Date(parseInt(e.professional.blockedAt.split("-")[0], 10), parseInt(e.professional.blockedAt.split("-")[1], 10) - 1, parseInt(e.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: e.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: e.confirmedDate === null || e.confirmedDate === undefined ? null : new Date(e.confirmedDate + "Z"),
        suggestedHours: e.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: e.confirmedHour === null || e.confirmedHour === undefined ? null : new Date(e.confirmedHour + "Z"),
        transactionId: e.transactionId === null || e.transactionId === undefined ? null : e.transactionId,
        paymentMethod: e.paymentMethod,
        paymentStatus: e.paymentStatus,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function editOrder(id: string, editOrder: EditOrder, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        id: id,
        editOrder: {
            professional: editOrder.professional === null || editOrder.professional === undefined ? null : {
                id: editOrder.professional.id,
                name: editOrder.professional.name,
                image: editOrder.professional.image === null || editOrder.professional.image === undefined ? null : {
                    thumb: {
                        width: editOrder.professional.image.thumb.width || 0,
                        height: editOrder.professional.image.thumb.height || 0,
                        url: editOrder.professional.image.thumb.url,
                    },
                    width: editOrder.professional.image.width || 0,
                    height: editOrder.professional.image.height || 0,
                    url: editOrder.professional.image.url,
                },
                ccmOrIss: editOrder.professional.ccmOrIss === null || editOrder.professional.ccmOrIss === undefined ? null : editOrder.professional.ccmOrIss,
                cnes: editOrder.professional.cnes === null || editOrder.professional.cnes === undefined ? null : editOrder.professional.cnes,
                resume: editOrder.professional.resume === null || editOrder.professional.resume === undefined ? null : editOrder.professional.resume,
                identificationNumber: editOrder.professional.identificationNumber,
                documentNumber: editOrder.professional.documentNumber,
                description: editOrder.professional.description,
                specialities: editOrder.professional.specialities.map(e => e),
                blockedAt: editOrder.professional.blockedAt === null || editOrder.professional.blockedAt === undefined ? null : typeof(editOrder.professional.blockedAt) === "string" ? new Date(new Date(editOrder.professional.blockedAt).getTime() - new Date(editOrder.professional.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editOrder.professional.blockedAt.getTime() - editOrder.professional.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            confirmedDate: editOrder.confirmedDate === null || editOrder.confirmedDate === undefined ? null : (typeof editOrder.confirmedDate === "string" && (editOrder.confirmedDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editOrder.confirmedDate as any).replace("Z", "") : editOrder.confirmedDate.toISOString().replace("Z", "")),
            confirmedHour: editOrder.confirmedHour === null || editOrder.confirmedHour === undefined ? null : (typeof editOrder.confirmedHour === "string" && (editOrder.confirmedHour as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editOrder.confirmedHour as any).replace("Z", "") : editOrder.confirmedHour.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "editOrder", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            name: ret.user.name,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(parseInt(ret.user.blockedAt.split("-")[0], 10), parseInt(ret.user.blockedAt.split("-")[1], 10) - 1, parseInt(ret.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: ret.clinicPackage.id,
            package: {
                id: ret.clinicPackage.package.id,
                name: ret.clinicPackage.package.name,
                image: ret.clinicPackage.package.image === null || ret.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.package.image.thumb.width || 0,
                        height: ret.clinicPackage.package.image.thumb.height || 0,
                        url: ret.clinicPackage.package.image.thumb.url,
                    },
                    width: ret.clinicPackage.package.image.width || 0,
                    height: ret.clinicPackage.package.image.height || 0,
                    url: ret.clinicPackage.package.image.url,
                },
                speciality: ret.clinicPackage.package.speciality,
                medicalProcedures: ret.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(ret.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: ret.clinicPackage.clinic.id,
                name: ret.clinicPackage.clinic.name,
                description: ret.clinicPackage.clinic.description,
                specialities: ret.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: ret.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: ret.clinicPackage.clinic.address.zipcode,
                    street: ret.clinicPackage.clinic.address.street,
                    streetNumber: ret.clinicPackage.clinic.address.streetNumber,
                    complementary: ret.clinicPackage.clinic.address.complementary === null || ret.clinicPackage.clinic.address.complementary === undefined ? null : ret.clinicPackage.clinic.address.complementary,
                    neighborhood: ret.clinicPackage.clinic.address.neighborhood,
                    city: ret.clinicPackage.clinic.address.city,
                    state: ret.clinicPackage.clinic.address.state,
                    countryCode: ret.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: ret.clinicPackage.clinic.address.latLng.lat,
                        lng: ret.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: ret.clinicPackage.clinic.bankAccount === null || ret.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: ret.clinicPackage.clinic.bankAccount.id,
                    bankCode: ret.clinicPackage.clinic.bankAccount.bankCode,
                    agency: ret.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: ret.clinicPackage.clinic.bankAccount.agencyDv === null || ret.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.agencyDv,
                    account: ret.clinicPackage.clinic.bankAccount.account,
                    accountDv: ret.clinicPackage.clinic.bankAccount.accountDv === null || ret.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.accountDv,
                    type: ret.clinicPackage.clinic.bankAccount.type,
                    documentNumber: ret.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: ret.clinicPackage.clinic.blockedAt === null || ret.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: ret.clinicPackage.clinic.phone,
                whatsapp: ret.clinicPackage.clinic.whatsapp,
                technicalManager: ret.clinicPackage.clinic.technicalManager,
                crm: ret.clinicPackage.clinic.crm,
                image: ret.clinicPackage.clinic.image === null || ret.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.clinic.image.thumb.width || 0,
                        height: ret.clinicPackage.clinic.image.thumb.height || 0,
                        url: ret.clinicPackage.clinic.image.thumb.url,
                    },
                    width: ret.clinicPackage.clinic.image.width || 0,
                    height: ret.clinicPackage.clinic.image.height || 0,
                    url: ret.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(ret.clinicPackage.clinic.createdAt + "Z"),
            },
            price: ret.clinicPackage.price || 0,
        },
        professional: ret.professional === null || ret.professional === undefined ? null : {
            id: ret.professional.id,
            name: ret.professional.name,
            image: ret.professional.image === null || ret.professional.image === undefined ? null : {
                thumb: {
                    width: ret.professional.image.thumb.width || 0,
                    height: ret.professional.image.thumb.height || 0,
                    url: ret.professional.image.thumb.url,
                },
                width: ret.professional.image.width || 0,
                height: ret.professional.image.height || 0,
                url: ret.professional.image.url,
            },
            ccmOrIss: ret.professional.ccmOrIss === null || ret.professional.ccmOrIss === undefined ? null : ret.professional.ccmOrIss,
            cnes: ret.professional.cnes === null || ret.professional.cnes === undefined ? null : ret.professional.cnes,
            resume: ret.professional.resume === null || ret.professional.resume === undefined ? null : ret.professional.resume,
            identificationNumber: ret.professional.identificationNumber,
            documentNumber: ret.professional.documentNumber,
            description: ret.professional.description,
            specialities: ret.professional.specialities.map((e: any) => e),
            blockedAt: ret.professional.blockedAt === null || ret.professional.blockedAt === undefined ? null : new Date(parseInt(ret.professional.blockedAt.split("-")[0], 10), parseInt(ret.professional.blockedAt.split("-")[1], 10) - 1, parseInt(ret.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: ret.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: ret.confirmedDate === null || ret.confirmedDate === undefined ? null : new Date(ret.confirmedDate + "Z"),
        suggestedHours: ret.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: ret.confirmedHour === null || ret.confirmedHour === undefined ? null : new Date(ret.confirmedHour + "Z"),
        transactionId: ret.transactionId === null || ret.transactionId === undefined ? null : ret.transactionId,
        paymentMethod: ret.paymentMethod,
        paymentStatus: ret.paymentStatus,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function cancelOrder(id: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "cancelOrder", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            name: ret.user.name,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(parseInt(ret.user.blockedAt.split("-")[0], 10), parseInt(ret.user.blockedAt.split("-")[1], 10) - 1, parseInt(ret.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: ret.clinicPackage.id,
            package: {
                id: ret.clinicPackage.package.id,
                name: ret.clinicPackage.package.name,
                image: ret.clinicPackage.package.image === null || ret.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.package.image.thumb.width || 0,
                        height: ret.clinicPackage.package.image.thumb.height || 0,
                        url: ret.clinicPackage.package.image.thumb.url,
                    },
                    width: ret.clinicPackage.package.image.width || 0,
                    height: ret.clinicPackage.package.image.height || 0,
                    url: ret.clinicPackage.package.image.url,
                },
                speciality: ret.clinicPackage.package.speciality,
                medicalProcedures: ret.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(ret.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: ret.clinicPackage.clinic.id,
                name: ret.clinicPackage.clinic.name,
                description: ret.clinicPackage.clinic.description,
                specialities: ret.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: ret.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: ret.clinicPackage.clinic.address.zipcode,
                    street: ret.clinicPackage.clinic.address.street,
                    streetNumber: ret.clinicPackage.clinic.address.streetNumber,
                    complementary: ret.clinicPackage.clinic.address.complementary === null || ret.clinicPackage.clinic.address.complementary === undefined ? null : ret.clinicPackage.clinic.address.complementary,
                    neighborhood: ret.clinicPackage.clinic.address.neighborhood,
                    city: ret.clinicPackage.clinic.address.city,
                    state: ret.clinicPackage.clinic.address.state,
                    countryCode: ret.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: ret.clinicPackage.clinic.address.latLng.lat,
                        lng: ret.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: ret.clinicPackage.clinic.bankAccount === null || ret.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: ret.clinicPackage.clinic.bankAccount.id,
                    bankCode: ret.clinicPackage.clinic.bankAccount.bankCode,
                    agency: ret.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: ret.clinicPackage.clinic.bankAccount.agencyDv === null || ret.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.agencyDv,
                    account: ret.clinicPackage.clinic.bankAccount.account,
                    accountDv: ret.clinicPackage.clinic.bankAccount.accountDv === null || ret.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.accountDv,
                    type: ret.clinicPackage.clinic.bankAccount.type,
                    documentNumber: ret.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: ret.clinicPackage.clinic.blockedAt === null || ret.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: ret.clinicPackage.clinic.phone,
                whatsapp: ret.clinicPackage.clinic.whatsapp,
                technicalManager: ret.clinicPackage.clinic.technicalManager,
                crm: ret.clinicPackage.clinic.crm,
                image: ret.clinicPackage.clinic.image === null || ret.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.clinic.image.thumb.width || 0,
                        height: ret.clinicPackage.clinic.image.thumb.height || 0,
                        url: ret.clinicPackage.clinic.image.thumb.url,
                    },
                    width: ret.clinicPackage.clinic.image.width || 0,
                    height: ret.clinicPackage.clinic.image.height || 0,
                    url: ret.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(ret.clinicPackage.clinic.createdAt + "Z"),
            },
            price: ret.clinicPackage.price || 0,
        },
        professional: ret.professional === null || ret.professional === undefined ? null : {
            id: ret.professional.id,
            name: ret.professional.name,
            image: ret.professional.image === null || ret.professional.image === undefined ? null : {
                thumb: {
                    width: ret.professional.image.thumb.width || 0,
                    height: ret.professional.image.thumb.height || 0,
                    url: ret.professional.image.thumb.url,
                },
                width: ret.professional.image.width || 0,
                height: ret.professional.image.height || 0,
                url: ret.professional.image.url,
            },
            ccmOrIss: ret.professional.ccmOrIss === null || ret.professional.ccmOrIss === undefined ? null : ret.professional.ccmOrIss,
            cnes: ret.professional.cnes === null || ret.professional.cnes === undefined ? null : ret.professional.cnes,
            resume: ret.professional.resume === null || ret.professional.resume === undefined ? null : ret.professional.resume,
            identificationNumber: ret.professional.identificationNumber,
            documentNumber: ret.professional.documentNumber,
            description: ret.professional.description,
            specialities: ret.professional.specialities.map((e: any) => e),
            blockedAt: ret.professional.blockedAt === null || ret.professional.blockedAt === undefined ? null : new Date(parseInt(ret.professional.blockedAt.split("-")[0], 10), parseInt(ret.professional.blockedAt.split("-")[1], 10) - 1, parseInt(ret.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: ret.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: ret.confirmedDate === null || ret.confirmedDate === undefined ? null : new Date(ret.confirmedDate + "Z"),
        suggestedHours: ret.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: ret.confirmedHour === null || ret.confirmedHour === undefined ? null : new Date(ret.confirmedHour + "Z"),
        transactionId: ret.transactionId === null || ret.transactionId === undefined ? null : ret.transactionId,
        paymentMethod: ret.paymentMethod,
        paymentStatus: ret.paymentStatus,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function updateOrderStatus(id: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "updateOrderStatus", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            name: ret.user.name,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(parseInt(ret.user.blockedAt.split("-")[0], 10), parseInt(ret.user.blockedAt.split("-")[1], 10) - 1, parseInt(ret.user.blockedAt.split("-")[2], 10)),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        clinicPackage: {
            id: ret.clinicPackage.id,
            package: {
                id: ret.clinicPackage.package.id,
                name: ret.clinicPackage.package.name,
                image: ret.clinicPackage.package.image === null || ret.clinicPackage.package.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.package.image.thumb.width || 0,
                        height: ret.clinicPackage.package.image.thumb.height || 0,
                        url: ret.clinicPackage.package.image.thumb.url,
                    },
                    width: ret.clinicPackage.package.image.width || 0,
                    height: ret.clinicPackage.package.image.height || 0,
                    url: ret.clinicPackage.package.image.url,
                },
                speciality: ret.clinicPackage.package.speciality,
                medicalProcedures: ret.clinicPackage.package.medicalProcedures.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                    description: e.description,
                })),
                createdAt: new Date(ret.clinicPackage.package.createdAt + "Z"),
            },
            clinic: {
                id: ret.clinicPackage.clinic.id,
                name: ret.clinicPackage.clinic.name,
                description: ret.clinicPackage.clinic.description,
                specialities: ret.clinicPackage.clinic.specialities.map((e: any) => e),
                serviceDays: ret.clinicPackage.clinic.serviceDays,
                address: {
                    zipcode: ret.clinicPackage.clinic.address.zipcode,
                    street: ret.clinicPackage.clinic.address.street,
                    streetNumber: ret.clinicPackage.clinic.address.streetNumber,
                    complementary: ret.clinicPackage.clinic.address.complementary === null || ret.clinicPackage.clinic.address.complementary === undefined ? null : ret.clinicPackage.clinic.address.complementary,
                    neighborhood: ret.clinicPackage.clinic.address.neighborhood,
                    city: ret.clinicPackage.clinic.address.city,
                    state: ret.clinicPackage.clinic.address.state,
                    countryCode: ret.clinicPackage.clinic.address.countryCode,
                    latLng: {
                        lat: ret.clinicPackage.clinic.address.latLng.lat,
                        lng: ret.clinicPackage.clinic.address.latLng.lng,
                    },
                },
                bankAccount: ret.clinicPackage.clinic.bankAccount === null || ret.clinicPackage.clinic.bankAccount === undefined ? null : {
                    id: ret.clinicPackage.clinic.bankAccount.id,
                    bankCode: ret.clinicPackage.clinic.bankAccount.bankCode,
                    agency: ret.clinicPackage.clinic.bankAccount.agency,
                    agencyDv: ret.clinicPackage.clinic.bankAccount.agencyDv === null || ret.clinicPackage.clinic.bankAccount.agencyDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.agencyDv,
                    account: ret.clinicPackage.clinic.bankAccount.account,
                    accountDv: ret.clinicPackage.clinic.bankAccount.accountDv === null || ret.clinicPackage.clinic.bankAccount.accountDv === undefined ? null : ret.clinicPackage.clinic.bankAccount.accountDv,
                    type: ret.clinicPackage.clinic.bankAccount.type,
                    documentNumber: ret.clinicPackage.clinic.bankAccount.documentNumber,
                },
                blockedAt: ret.clinicPackage.clinic.blockedAt === null || ret.clinicPackage.clinic.blockedAt === undefined ? null : new Date(parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[0], 10), parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[1], 10) - 1, parseInt(ret.clinicPackage.clinic.blockedAt.split("-")[2], 10)),
                phone: ret.clinicPackage.clinic.phone,
                whatsapp: ret.clinicPackage.clinic.whatsapp,
                technicalManager: ret.clinicPackage.clinic.technicalManager,
                crm: ret.clinicPackage.clinic.crm,
                image: ret.clinicPackage.clinic.image === null || ret.clinicPackage.clinic.image === undefined ? null : {
                    thumb: {
                        width: ret.clinicPackage.clinic.image.thumb.width || 0,
                        height: ret.clinicPackage.clinic.image.thumb.height || 0,
                        url: ret.clinicPackage.clinic.image.thumb.url,
                    },
                    width: ret.clinicPackage.clinic.image.width || 0,
                    height: ret.clinicPackage.clinic.image.height || 0,
                    url: ret.clinicPackage.clinic.image.url,
                },
                createdAt: new Date(ret.clinicPackage.clinic.createdAt + "Z"),
            },
            price: ret.clinicPackage.price || 0,
        },
        professional: ret.professional === null || ret.professional === undefined ? null : {
            id: ret.professional.id,
            name: ret.professional.name,
            image: ret.professional.image === null || ret.professional.image === undefined ? null : {
                thumb: {
                    width: ret.professional.image.thumb.width || 0,
                    height: ret.professional.image.thumb.height || 0,
                    url: ret.professional.image.thumb.url,
                },
                width: ret.professional.image.width || 0,
                height: ret.professional.image.height || 0,
                url: ret.professional.image.url,
            },
            ccmOrIss: ret.professional.ccmOrIss === null || ret.professional.ccmOrIss === undefined ? null : ret.professional.ccmOrIss,
            cnes: ret.professional.cnes === null || ret.professional.cnes === undefined ? null : ret.professional.cnes,
            resume: ret.professional.resume === null || ret.professional.resume === undefined ? null : ret.professional.resume,
            identificationNumber: ret.professional.identificationNumber,
            documentNumber: ret.professional.documentNumber,
            description: ret.professional.description,
            specialities: ret.professional.specialities.map((e: any) => e),
            blockedAt: ret.professional.blockedAt === null || ret.professional.blockedAt === undefined ? null : new Date(parseInt(ret.professional.blockedAt.split("-")[0], 10), parseInt(ret.professional.blockedAt.split("-")[1], 10) - 1, parseInt(ret.professional.blockedAt.split("-")[2], 10)),
        },
        suggestedDates: ret.suggestedDates.map((e: any) => new Date(e + "Z")),
        confirmedDate: ret.confirmedDate === null || ret.confirmedDate === undefined ? null : new Date(ret.confirmedDate + "Z"),
        suggestedHours: ret.suggestedHours.map((e: any) => new Date(e + "Z")),
        confirmedHour: ret.confirmedHour === null || ret.confirmedHour === undefined ? null : new Date(ret.confirmedHour + "Z"),
        transactionId: ret.transactionId === null || ret.transactionId === undefined ? null : ret.transactionId,
        paymentMethod: ret.paymentMethod,
        paymentStatus: ret.paymentStatus,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
