import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import FilePickerShelf from "~/shelves/FilePickerShelf";

const pageStrings = strings.professionals.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		description: "",
		identificationNumber: "",
		documentNumber: "",
	});

	public cnes = new FilePickerShelf(api.uploadUncertainFile);
	public resume = new FilePickerShelf(api.uploadUncertainFile);
	public ccmOrIss = new FilePickerShelf(api.uploadUncertainFile);
	public specialities: api.Speciality[] = [];
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getProfessional(id);
		}
	}

	public addSpeciality = (speciality: api.Speciality) => {
		this.specialities.push(speciality);
	};

	public removeSpeciality = (speciality: api.Speciality) => {
		this.specialities.splice(this.specialities.indexOf(speciality), 1);
	};

	public getProfessional = async (id: string) => {
		this.loader.tryStart();
		try {
			const professional = await api.getProfessional(id);
			this.setInitValues(professional);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (professional: api.Professional) => {
		this.formShelf = new FormShelf({
			name: professional.name,
			description: professional.description,
			identificationNumber: professional.identificationNumber,
			documentNumber: professional.documentNumber,
		});
		this.specialities = professional.specialities;
		this.cnes.getPickerFields().setUploadedFile(professional.cnes);
		this.resume.getPickerFields().setUploadedFile(professional.resume);
		this.ccmOrIss.getPickerFields().setUploadedFile(professional.ccmOrIss);
		if (professional.image) {
			this.imageShelf.getPickerFields().setUploadedImage(professional.image);
		}
	};

	public createOrEditProfessional = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				name,
				description,
				identificationNumber,
				documentNumber,
			} = data;

			if (this.id.value) {

				await api.editProfessional(this.id.value, {
					image: this.imageShelf.uncertainfiedImage,
					name,
					description,
					specialities: this.specialities,
					cnes: this.cnes.uncertainfiedFile,
					ccmOrIss: this.ccmOrIss.uncertainfiedFile,
					resume: this.resume.uncertainfiedFile,
					identificationNumber,
					documentNumber,
				});
			} else {
				await api.createProfessional({
					image: this.imageShelf.uncertainfiedImage,
					name,
					description,
					specialities: this.specialities,
					cnes: this.cnes.uncertainfiedFile,
					ccmOrIss: this.ccmOrIss.uncertainfiedFile,
					resume: this.resume.uncertainfiedFile,
					identificationNumber,
					documentNumber,
				});
			}

			showSuccessToast(pageStrings.success(!!this.id));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
