import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, ModelShelf } from "@startapp/mobx-utils";
import { showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";

export default class Store {

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.Professional>;
	public isBlocked = new AttributeShelf(false);


	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.modelShelf = new ModelShelf(
				id,
				api.getProfessional,
				{
					onFinishFetch: () => this.isBlocked.setValue(!!this.modelShelf.model.blockedAt),
				},
			);
		}
	}

	public changeProfessionalBlockStatus = async () => {
		this.loader.tryStart();
		try {
			const professional = await api.changeProfessionalBlockStatus(
				this.modelShelf.model.id,
				!this.isBlocked.value,
			);
			this.isBlocked.setValue(!!professional.blockedAt);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
