import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr, useDisclosure } from "@chakra-ui/react";
import { AutoCompleteModal, Table, TableCellWithActionButtons, TextInput, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import Store from "./store";
import { useDebounce } from "~/hooks/useDebounce";
import { useHistory } from "react-router-dom";

const TableView: React.FC = () => {
	const pageStrings = strings.clinicProfessionals.table;
	const modal = strings.common.modal;
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { dialog, authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store());
	const debounce = useDebounce();
	const history = useHistory();

	const onDeleteProfessional = (id: string) => {
		store.deleteProfessional(id);
		dialog.closeDialog();
	};

	const openDialog = (professional: API.Professional) => {
		dialog.showDialog({
			title: pageStrings.modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modal.description(professional.name),
			buttons: [
				{
					title: pageStrings.modal.button.remove,
					onPress: () => onDeleteProfessional(professional.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onGoToProfessionalDetails = (id: string) => history.push(`professionals/details/${id}`);

	React.useEffect(() => {
		if (authStore.currentAdminUser && authStore.currentAdminUser.clinic){
			store.clinicId.setValue(authStore.currentAdminUser.clinic.id);
			store.fetchPage(0);
		}
	}, [authStore.currentAdminUser]);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16"}}>
			<TotalInfo
				width="100%"
				total={store.totalProfessionals.value}
				text={pageStrings.totalText}
			/>
			<Table
				data={store._items}
				headers={pageStrings.header}
				onAdd={onOpen}
				addButtonText={pageStrings.addButtonText}
				addButtonLoading={store.loader.isLoading}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onView={() => onGoToProfessionalDetails(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>
							<Text>{item.name}</Text>
						</Td>
						<Td>
							<Text>{item.documentNumber}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
			<AutoCompleteModal
				isOpen={isOpen}
				onClose={onClose}
				header={
					<TextInput
						placeholder={pageStrings.professionalPlaceholder}
						my={2}
						onKeyUp={(e) => {
							const input = e.target as HTMLInputElement;
							debounce.clearTimer();
							debounce.setTimer(setTimeout(() => store.searchProfessional.setValue(input.value), 500));
						}}
					/>
				}
				listProps={{
					data: store.autoCompleteProfessional.items,
					loading: store.autoCompleteProfessional.loader.isLoading,
					renderItem: (item) => (
						<Text
							key={item.id}
							onClick={() => {
								store.addProfessional(item.id);
								onClose();
							}}
							cursor="pointer"
						>
							{item.name}
						</Text>
					),
					paginantionProps: {
						currentPage: store.autoCompleteProfessional.page,
						nextPage: store.autoCompleteProfessional.nextPage,
						prevPage: store.autoCompleteProfessional.previousPage,
						hasNextPage: store.autoCompleteProfessional.hasNextPage,
					},
				}}
			/>
		</Flex>
	);
};

export default observer(TableView);
