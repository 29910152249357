import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
	Td,
	Tr,
	Text,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import ClinicPackageShelf from "../../Shelves/ClinicPackageShelf";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import format from "~/resources/format";

interface IProps {
	id?: string;
	clinicPackageShelf: ClinicPackageShelf;
}

const ClinicPackages: React.FC<IProps> = (props) => {
	const { id, clinicPackageShelf } = props;
	const clinicPackageStrings = strings.clinics.details.clinicPackages;
	const modal = strings.common.modal;

	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onDeleteClinicPackage = (clinicName: string, clinicPackageId: string) => {
		clinicPackageShelf.deleteClinicPackage(clinicPackageId, clinicName);
		dialog.closeDialog();
	};

	const onGoToDetailsClinicPackage = (clinicPackageId: string) => history.push(`/dashboard/clinics/${id}/detailsClinicPackage/${clinicPackageId}`);
	const onGoToEditClinicPackage = (clinicPackageId: string) => history.push(`/dashboard/clinics/${id}/editClinicPackage/${clinicPackageId}`);
	const onGoToCreateClinicPackage = () => history.push(`/dashboard/clinics/${id}/createClinicPackage`);

	const openClinicPackageDialog = (clinicPackage: api.ClinicPackage) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(clinicPackage.package.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteClinicPackage(clinicPackage.clinic.name, clinicPackage.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<CentralizedCard
			title={{
				text: clinicPackageStrings.tableTitle,
			}}
			isTable
		>
			<Table
				isCard
				data={clinicPackageShelf._items}
				headers={clinicPackageStrings.tableHeaders}
				onAdd={onGoToCreateClinicPackage}
				addButtonText={clinicPackageStrings.tableAddButton}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onView={() => onGoToDetailsClinicPackage(item.id)}
							onEdit={() => onGoToEditClinicPackage(item.id)}
							onDelete={() => openClinicPackageDialog(item)}
						/>
						<Td>
							<Text>{item.package.name}</Text>
						</Td>
						<Td>
							<Text>{format.decimal(item.price)}</Text>
						</Td>
					</Tr>
				)}
				loading={clinicPackageShelf._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={clinicPackageShelf.page}
				prevPage={clinicPackageShelf.previousPage}
				nextPage={clinicPackageShelf.nextPage}
				hasNextPage={clinicPackageShelf._isHaveNextPage}
			/>
		</CentralizedCard>
	);
};

export default observer(ClinicPackages);
