import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { reaction } from "mobx";

export default class Store extends AbstractPaginatedListStore<api.Professional> {

	public clinicId = new AttributeShelf("");

	public loader = new LoaderShelf();
	public totalProfessionals = new AttributeShelf(0);
	public searchProfessional = new AttributeShelf("");

	constructor() {
		super();
		this.getTotalProfessionals();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Professional[]> {
		return api.getAllProfessionalsByClinicId(page, this.clinicId.value);
	}

	public getTotalProfessionals = async() => {
		try {
			const professionals = await api.getTotalNumberOfProfessionals();
			this.totalProfessionals.setValue(professionals);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public addProfessional = async (id: string) => {
		this.loader.tryStart();
		try {

			const professional = await api.addProfessionalIntoClinic(this.clinicId.value, id);

			showSuccessToast(strings.clinicProfessionals.table.added(professional.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public deleteProfessional = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedProfessional = await api.removeProfessionalFromClinic(this.clinicId.value, id);

			showSuccessToast(strings.clinicProfessionals.table.removed(deletedProfessional.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	private autoCompleteReaction = reaction(() => this.searchProfessional.value,
		() => this.autoCompleteProfessional.refresh(),
	);

	public autoCompleteProfessional = new PaginatedListShelf(
		async (page: number) => await api.autoCompleteProfessionals(page, this.searchProfessional.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};
}
