import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Loading,
	DetailsRow,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import moment from "moment";

interface IParams {
	clinicOrderId: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.clinics.details.clinicOrders.details;

	const { clinicOrderId } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(clinicOrderId));

	const goBack = () => history.goBack();

	return (
		<CentralizedCard
			title={{
				text: strings.common.detailsTitle,
			}}
			button={(
				<Button
					variant="outline"
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={goBack}
				>
					{strings.actions.back}
				</Button>
			)}
		>
			{store.modelShelf.initialLoader.isLoading ?
				(
					<Loading />
				) : (
					<>
						<DetailsRow
							label={pageStrings.user}
							value={store.modelShelf._model.user.name}
						/>
						<DetailsRow
							label={pageStrings.userPhone}
							value={store.modelShelf._model.user.phone}
						/>
						<DetailsRow
							label={pageStrings.service}
							value={store.modelShelf._model.clinicPackage.package.name}
						/>
						<DetailsRow
							label={pageStrings.clinic}
							value={store.modelShelf._model.clinicPackage.clinic.name}
						/>
						<DetailsRow
							label={pageStrings.professional}
							value={store.modelShelf._model.professional?.name || ""}
						/>

						<DetailsRow
							label={pageStrings.date}
							value={
								store.modelShelf.model.confirmedDate ?
									moment(store.modelShelf._model.confirmedDate).format("DD/MM/YYYY")
									:
									pageStrings.noConfirmedDate
							}
						/>
						<DetailsRow
							label={pageStrings.payment}
							value={store.modelShelf._model.paymentMethod}
						/>
					</>
				)}
		</CentralizedCard>
	);
};

export default observer(Details);
