import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button } from "@chakra-ui/react";
import strings from "~/resources/strings";
import {
	AddressAutoCompleteInput,
	CentralizedCard,
	ExpertiseSelect,
	ImagePicker,
	Label,
	TextareaInput,
	TextInput,
	DetailsRowMultiple,
} from "..";
import api from "../../resources/api";
import { IPlaceDetailsResult } from "../../declarations/maps";

type FieldType = "name" | "description" | "serviceDays" | "technicalManager" | "crm" | "phone" | "whatsapp" | "zipcode" | "street" | "streetNumber" | "complementary" | "neighborhood" | "city";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
	address: {
		onSelectAddress: (placeSelected: IPlaceDetailsResult) => void;
	};
	specialities: {
		items: api.Speciality[];
		onAdd: (speciality: api.Speciality) => void;
		onRemove: (speciality: api.Speciality) => void;
	};
	image: {
		pick: () => void;
		src: string | null;
	};
}

interface IProps {
	title: string;
	isLoading: boolean;
	formValues: FormValues;
	isProfile?: boolean;
	submit?: {
		onClick: () => void;
		text: string;
		isLoading: boolean;
	};
}

export const ClinicForm: React.FC<IProps> = observer((props) => {

	const { title, isLoading, formValues, isProfile, submit } = props;

	const pageStrings = strings.clinics.createOrEdit;
	const commonStrings = strings.common;

	return (
		<CentralizedCard
			title={{
				text: title,
			}}
			button={(
				submit ?
					<Button
						minW={{ base: "100%", md: 280 }}
						size="lg"
						mt={10}
						isLoading={submit.isLoading}
						onClick={submit.onClick}
					>
						{submit.text}
					</Button>
					:
					undefined
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{commonStrings.fields.photo}
				</Label>
				<ImagePicker
					pickImage={formValues.image.pick}
					src={formValues.image.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("name").error}
				{...formValues.field("name")}
			/>
			<TextareaInput
				onChange={formValues.field("description").onChange}
				labelText={commonStrings.fields.description}
				value={formValues.field("description").value}
			/>
			<ExpertiseSelect
				expertises={api.allValuesSpeciality()}
				onAdd={formValues.specialities.onAdd}
				onRemove={formValues.specialities.onRemove}
				allSelectedsSpecialities={formValues.specialities.items}
			/>
			<TextInput
				labelText={commonStrings.fields.serviceDays}
				helper={pageStrings.helperServiceDays}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("serviceDays").error}
				{...formValues.field("serviceDays")}
			/>
			{
				isProfile ? (
					<DetailsRowMultiple
						items={[
							{
								label: commonStrings.fields.technicalManager,
								value: formValues.field("technicalManager").value,
							},
							{
								label: commonStrings.fields.crm,
								value: formValues.field("crm").value,
							},
						]}
					/>
				) : (
					<>
						<TextInput
							labelText={commonStrings.fields.technicalManager}
							labelProps={{ fontWeight: "bold" }}
							type="text"
							isDisabled={isLoading}
							errorText={formValues.field("technicalManager").error}
							{...formValues.field("technicalManager")}
						/>
						<TextInput
							labelText={commonStrings.fields.crm}
							labelProps={{ fontWeight: "bold" }}
							type="text"
							isDisabled={isLoading}
							errorText={formValues.field("crm").error}
							{...formValues.field("crm")}
						/>
					</>
				)
			}
			<TextInput
				labelText={commonStrings.fields.telephone}
				labelProps={{ fontWeight: "bold" }}
				type="phone"
				mask="99 99999-9999"
				isDisabled={isLoading}
				errorText={formValues.field("phone").error}
				{...formValues.field("phone")}
			/>
			<TextInput
				labelText={commonStrings.fields.whatsapp}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				mask="99 99999-9999"
				isDisabled={isLoading}
				errorText={formValues.field("whatsapp").error}
				{...formValues.field("whatsapp")}
			/>
			<AddressAutoCompleteInput
				onSelected={formValues.address.onSelectAddress}
				value="Av. Angélica, 1752 - Consolação, São Paulo - SP, 01228-200"
				labelText={commonStrings.fields.address}
			/>
		</CentralizedCard>
	);
});
