import React from "react";
import { Button } from "@chakra-ui/react";
import api from "~/resources/api";

interface IProps {
	name: string;
	onAdd?: (name: string) => void;
	onRemove?: (name: string) => void;
	isDisabled?: boolean;
	isSelected?: boolean;
}

export const ExpertiseButton: React.FC<IProps> = (props) => {

	const { name, isSelected, isDisabled, onRemove, onAdd } = props;

	return (
		isDisabled ? (
			<Button
				disabled={isDisabled}
				color="white"
				fontWeight="initial"
			>
				{name}
			</Button>
		) : (
			isSelected ? (
				<Button
					disabled={isDisabled}
					color="white"
					fontWeight="initial"
					onClick={() => {
						if (onRemove){
							onRemove(api.valueFromTranslationSpeciality(name));
						}
					}}
				>
					{name}
				</Button>
			) : (
				<Button
					color="gray.400"
					fontWeight="initial"
					colorScheme="gray"
					onClick={() => {
						if (onAdd){
							onAdd(api.valueFromTranslationSpeciality(name));
						}
					}}
				>
					{name}
				</Button>
			)
		)
	);
};
