import { LoaderShelf, AttributeShelf, AbstractPaginatedListStore } from "@startapp/mobx-utils";


import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.User> {

	public loader = new LoaderShelf();
	public totalUsers = new AttributeShelf(0);
	public blockedAt = new AttributeShelf(false);

	constructor() {
		super();
		this.fetchPage(0);
		this.getTotalUsers();
	}

	protected getDataItemsPerPage(page: number): Promise<api.User[]> {
		return api.getAllUsers(page);
	}

	public getTotalUsers = async() => {
		try {
			const users = await api.getTotalNumberOfUsers();
			this.totalUsers.setValue(users);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public deleteUser = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedUser = await api.deleteUser(id);

			showSuccessToast(strings.users.table.delete(deletedUser.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public changeUserBlockStatus = async (id: string, blockedAt: Date | null) => {
		this.loader.tryStart();
		try {
			if (blockedAt) {
				await api.changeUserBlockStatus(id, this.blockedAt.value);
				showSuccessToast(strings.users.table.statusUser(this.blockedAt.value));
			} else {
				await api.changeUserBlockStatus(id, !this.blockedAt.value);
				showSuccessToast(strings.users.table.statusUser(!this.blockedAt.value));
			}
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
