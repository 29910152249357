import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Center,
	useDisclosure,
} from "@chakra-ui/react";
import {
	DateModal,
	Graphic,
	InfoCard,
	PeriodGroup,
	TotalInfo,
} from "~/components";
import strings from "~/resources/strings";
import format from "~/resources/format";
import Store from "./store";
import MostSoldPackages from "./Tables/MostSoldPackages";
import MostBillingPackages from "./Tables/MostBillingPackages";
import MostSalesClinics from "./Tables/MostSalesClinics";
import MostBillingClinics from "./Tables/MostBillingClinics";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

const Home: React.FC = () => {
	const pageStrings = strings.home;
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store());

	const { isOpen, onClose, onOpen } = useDisclosure();

	const getDate = (time: number) => {
		const todayTime = new Date().getTime();
		return new Date(todayTime - time);
	};

	const todayStringDate = format.dateWithMonth(new Date());

	const oneDay = ((1000 * 60) * 60) * 24;
	const sevenDays = oneDay * 7;
	const fifteenDays = oneDay * 15;
	const thirtyDays = oneDay * 30;

	const dates = {
		today: () => {
			store.startDate.setValue(new Date());
			store.endDate.setValue(new Date());

			store.fetch();
		},
		yesterday: () => {
			const date = getDate(oneDay);

			store.startDate.setValue(date);
			store.endDate.setValue(date);

			store.fetch();
		},
		sevenDaysBefore: () => {
			const date = getDate(sevenDays);

			store.startDate.setValue(date);
			store.endDate.setValue(new Date());

			store.fetch();
		},
		fifteenDaysBefore: () => {
			const date = getDate(fifteenDays);

			store.startDate.setValue(date);
			store.endDate.setValue(new Date());

			store.fetch();
		},
		thirtyDaysBefore: () => {
			const date = getDate(thirtyDays);

			store.startDate.setValue(date);
			store.endDate.setValue(new Date());

			store.fetch();
		},
	};

	const getCustomDescription = (startDate: Date | null, endDate: Date | null) => {
		if (startDate && endDate){
			return pageStrings.filters.betweenDates(format.date(startDate), format.date(endDate));
		}

		return pageStrings.filters.selectPeriod;
	};

	const items = [
		{
			title: pageStrings.filters.yesterday,
			description: format.dateWithMonth(getDate(oneDay)),
			onClick: dates.yesterday,
		},
		{
			title: pageStrings.filters.today,
			description: format.dateWithMonth(new Date()),
			onClick: dates.today,
		},
		{
			title: pageStrings.filters.sevenDaysBefore,
			description: pageStrings.filters.betweenDates(format.dateWithMonth(getDate(sevenDays)), todayStringDate),
			onClick: dates.sevenDaysBefore,
		},
		{
			title: pageStrings.filters.fifteenDaysBefore,
			description: pageStrings.filters.betweenDates(format.dateWithMonth(getDate(fifteenDays)), todayStringDate),
			onClick: dates.fifteenDaysBefore,
		},
		{
			title: pageStrings.filters.thirtyDaysBefore,
			description: pageStrings.filters.betweenDates(format.dateWithMonth(getDate(thirtyDays)), todayStringDate),
			onClick: dates.thirtyDaysBefore,
		},
		{
			title: pageStrings.filters.custom,
			description: getCustomDescription(store.startDate.value, store.endDate.value),
			onClick: onOpen,
		},
	];

	React.useEffect(() => {
		if (authStore.currentAdminUser){

			if (authStore.currentAdminUser.clinic){
				store.clinicId.setValue(authStore.currentAdminUser.clinic.id);
			}

			store.fetch();
		}
	}, [authStore.currentAdminUser]);

	const onSubmitDateModal = async () => {
		await store.getHomeStatisticsData();
		onClose();
	};
	const returnTopTenPackages = () => {
		if (store.homeData.value?.topTenPackages) {
			return store.homeData.value?.topTenPackages;
		}
		return store.homeData.value?.topTenClinicPackages;
	};

	const returnTopTenHighestBillingPackages = () => {
		if (store.homeData.value?.topTenHighestBillingPackages) {
			return store.homeData.value?.topTenHighestBillingPackages;
		}
		return store.homeData.value?.topTenHighestBillingClinicPackages;
	};

	return (
		<Center>
			<Flex
				flexDirection="column"
				alignContent="center"
				p={{ base: 2, lg: 12 }}
				w="80%"
				maxW={1200}
			>
				<Flex
					flexDirection={{ base: "column", md: "row" }}
					pt="20px"
				>
					{store.homeData.value?.totalAmountOfClinics ? (
						<TotalInfo
							total={store.homeData.value?.totalAmountOfClinics || 0}
							text={pageStrings.totalClinics}
							mr={{ base: 0, md: 5 }}
							pb={{ base: 5, sm: 0 }}
						/>
					) : (
						<TotalInfo
							total={store.homeData.value?.totalAmountOfProfessionalsByClinicId || 0}
							text={pageStrings.totalProfessionals}
							mr={{ base: 0, md: 5 }}
							pb={{ base: 5, sm: 0 }}
						/>
					)}
					{store.homeData.value?.totalAmountOfPackages ? (
						<TotalInfo
							total={store.homeData.value?.totalAmountOfPackages || 0}
							text={pageStrings.totalPackages}
							mr={{ base: 0, sm: 5 }}
							pb={{ base: 5, sm: 0 }}
						/>
					):(
						<TotalInfo
							total={store.homeData.value?.totalAmountOfClinicPackages || 0}
							text={pageStrings.totalPackages}
							mr={{ base: 0, sm: 5 }}
							pb={{ base: 5, sm: 0 }}
						/>
					)}
				</Flex>
				<PeriodGroup items={items} />
				<Graphic
					selectedPeriodFilter={store.selectedPeriodFilter.value}
					onSelectPeriodFilter={(value: api.HomeGraphicType) => {
						store.selectedPeriodFilter.setValue(value);
						store.getHomeGraphicsData();
					}}
					selectedAmountFilter={store.selectedAmountFilter.value}
					onSelectAmountFilter={(value: api.GraphicType) => {
						store.selectedAmountFilter.setValue(value);
						store.getHomeGraphicsData();
					}}
					graphicLabel={store.graphicLabel.value}
					data={store.graphicData.value}
					loading={store.loader.isLoading}
					mt={5}
				/>
				<Flex flexDirection={{ base: "column", sm: "row" }} alignItems={{ base: "center", sm: "initial" }} mt={{ base: 8, lg: 16 }}>
					<InfoCard
						w={{ base: "60%", sm: "initial" }}
						title={pageStrings.totalBilling}
						description={format.currencyForBR(store.homeData.value ? store.homeData.value.totalBilling : 0)}
					/>
					<InfoCard
						w={{ base: "60%", sm: "initial" }}
						mx={{ base: 0, sm: 2 }}
						title={pageStrings.totalTicketAvg}
						description={format.currencyForBR(store.homeData.value ? store.homeData.value.averageBilling : 0)}
					/>
					<InfoCard
						w={{ base: "60%", sm: "initial" }}
						title={pageStrings.newUsers}
						description={store.homeData.value?.totalAmountOfUsers?.toString() || "0"}
					/>
				</Flex>
				<Flex
					w="100%"
					wrap="wrap"
					justifyContent={{ base: "center", lg: "space-between" }}
					flexDirection="row"
				>
					<MostSoldPackages
						isLoading={store.loader.isLoading}
						topTenPackages={store.homeData.value ? (returnTopTenPackages() || []) : []}
					/>
					<MostBillingPackages
						isLoading={store.loader.isLoading}
						topTenHighestBillingPackages={store.homeData.value ? (returnTopTenHighestBillingPackages() || []) : []}
					/>
				</Flex>
				{(authStore.currentAdminUser && !authStore.currentAdminUser.clinic) && (
					<Flex
						w="100%"
						wrap="wrap"
						justifyContent={{ base: "center", lg: "space-between" }}
						flexDirection="row"
					>
						<MostSalesClinics
							isLoading={store.loader.isLoading}
							topTenClinicsWithTheHighestSales={store.homeData.value ? (store.homeData.value.topTenClinicsWithTheHighestSales || []) : []}
						/>
						<MostBillingClinics
							isLoading={store.loader.isLoading}
							topTenClinicsWithHighestBilling={store.homeData.value ? store.homeData.value.topTenClinicsWithHighestBilling || [] : []}
						/>
					</Flex>
				)}
			</Flex>
			<DateModal
				onSubmit={onSubmitDateModal}
				isOpen={isOpen}
				onClose={onClose}
				closeOnOverlayClick
				title={pageStrings.filters.title}
				startInput={{
					label: pageStrings.filters.startedDate,
					onChange: store.startDate.setValue,
					selectedDate: store.startDate.value,
					isDisabled: false,
				}}
				endInput={{
					label: pageStrings.filters.endDate,
					onChange: store.endDate.setValue,
					selectedDate: store.endDate.value,
					isDisabled: false,
				}}
			/>
		</Center>
	);
};


export default observer(Home);
