import { makeAutoObservable, reaction } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.clinics.details.clinicPackages.createOrEdit;

export default class Store {

	public loader = new LoaderShelf();

	public packageId = new AttributeShelf("");
	public clinicId = new AttributeShelf("");
	public price = new AttributeShelf(0);
	public searchPackage = new AttributeShelf("");
	public clinic: AttributeShelf<api.Clinic | null> = new AttributeShelf(null);
	public package: AttributeShelf<api.Package | null> = new AttributeShelf(null);

	constructor(clinicId: string, clinicPackageId?: string) {
		makeAutoObservable(this);

		this.clinicId.setValue(clinicId);
		if (clinicPackageId) {
			this.packageId.setValue(clinicPackageId);
			this.getClinicPackage(clinicPackageId);
		}
	}

	public getClinicPackage = async (packageId: string) => {
		this.loader.tryStart();
		try {
			const packages = await api.getClinicPackage(packageId);
			this.setInitValues(packages);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (packages: api.ClinicPackage) => {
		this.price.setValue(packages.price);
		this.package.setValue(packages.package);
		this.clinic.setValue(packages.clinic);
	};

	public onCreateOrEditClinicPackage = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {

			if (this.packageId.value && this.package.value && this.clinic.value) {
				await api.editClinicPackage(this.packageId.value, {
					package: this.package.value,
					clinic: this.clinic.value,
					price: this.price.value,
				});
				showSuccessToast(pageStrings.success(this.packageId.value));
			} else {
				await api.createClinicPackage({
					packageId: this.package.value?.id || "",
					clinicId: this.clinicId.value,
					price: this.price.value,
				});
				showSuccessToast(pageStrings.success());
			}
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	private autoCompleteReaction = reaction(() => this.searchPackage.value,
		() => this.autoCompletePackage.refresh(),
	);

	public autoCompletePackage = new PaginatedListShelf(
		async (page: number) => await api.autoCompletePackages(page, this.searchPackage.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};

}
