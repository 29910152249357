import { makeAutoObservable, reaction } from "mobx";
import { LoaderShelf, AttributeShelf, ModelShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.orders.edit;

export default class Store {

	public modelShelf: ModelShelf<api.Order>;

	public professional = new AttributeShelf<api.Professional | null>(null);
	public autoCompleteModal = new AttributeShelf<boolean>(false);
	public searchProfessionals = new AttributeShelf("");

	public confirmedDate = new AttributeShelf<Date | null>(null);
	public confirmedHour = new AttributeShelf<Date | null>(null);

	public blocked = new AttributeShelf(false)
	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	constructor(id: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.modelShelf = new ModelShelf(id, api.getOrder, {onFinishFetch: ()=>{
				this.professional.setValue(this.modelShelf._model.professional);
				this.confirmedDate.setValue(this.modelShelf._model.confirmedDate);
				this.confirmedHour.setValue(this.modelShelf._model.confirmedHour);
			}});
		}
	}

	public createOrEditUser = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {

			await api.editOrder(this.id.value,{
				confirmedDate: this.confirmedDate.value,
				confirmedHour: this.confirmedHour.value,
				professional: this.professional.value,
			});

			showSuccessToast(pageStrings.success);
			onSuccess();

		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public updateOrderStatus = async () => {
		this.loader.tryStart();
		try {

			await api.updateOrderStatus(this.id.value);
			this.modelShelf.fetchModel();
			showSuccessToast(pageStrings.successUpdateStatus);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public cancelOrder = async () => {
		this.loader.tryStart();
		try {

			await api.cancelOrder(this.id.value);
			this.modelShelf.fetchModel();
			showSuccessToast(pageStrings.successCancel);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	private autoCompleteReaction = reaction(() => this.searchProfessionals.value,
		() => this.autoCompleteProfessionals.refresh(),
	);

	public autoCompleteProfessionals = new PaginatedListShelf(
		async (page: number) => await api.autoCompleteProfessionals(page, this.searchProfessionals.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};
}
