import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.medicalProcedure.edit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		description: "",
	});

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");
	public medicalProcedure: api.MedicalProcedure;

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getMedicalProcedure(id);
		}
	}

	public getMedicalProcedure = async (id: string) => {
		this.loader.tryStart();
		try {
			this.medicalProcedure = await api.getMedicalProcedure(id);
			this.setInitValues(this.medicalProcedure);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (medicalProcedure: api.MedicalProcedure) => {
		this.formShelf = new FormShelf({
			name: medicalProcedure.name,
			description: medicalProcedure.description,
		});
	};

	public onCreateOrEditMedicalProcedure = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				name,
				description,
			} = data;

			if (this.id.value){
				await api.editMedicalProcedure(this.id.value,{
					name,
					description,
				});
				showSuccessToast(pageStrings.editSuccess);
			} else {
				await api.createMedicalProcedure({
					name,
					description,
				});
				showSuccessToast(pageStrings.createSuccess);
			}
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
