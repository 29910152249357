import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Box,
	Button, Text, useDisclosure,
} from "@chakra-ui/react";
import {
	AutoCompleteModal,
	CentralizedCard,
	EnumSelect,
	ImagePicker,
	Label,
	TextInput,
} from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import Store from "./store";
import api from "~/resources/api";
import { useDebounce } from "~/hooks/useDebounce";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const pageStrings = strings.packages.edit;
	const commonStrings = strings.common;
	const history = useHistory();
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const debounce = useDebounce();
	const { authStore} = useGlobalStore();
	const onSuccess = () => {
		history.goBack();
	};

	const onSubmit = () => {
		store.onCreateOrEditPackage(onSuccess);
	};

	const onSelectMedicalProcedure = (procedure: api.MedicalProcedure) => {
		store.medicalProcedures.value.push(procedure);
		onClose();
	};

	const isAdminClinicUser = authStore.currentAdminUser && authStore.currentAdminUser.clinic;

	React.useEffect(() => {
		if (isAdminClinicUser) {
			history.replace("/");
		}
	},[isAdminClinicUser]);

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title(id),
			}}
			button={(
				<Button
					w="100%"
					maxW={280}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{commonStrings.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{commonStrings.fields.photo}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={pageStrings.autoCompleteLabel(id)}
				onClick={onOpen}
				labelProps={{ fontWeight: "bold" }}
				value={store.medicalProcedures.value.flatMap((x) => [x.name])}
				isReadOnly
				cursor="pointer"
			/>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("name")}
				{...store.formShelf.field("name")}
			/>
			<AutoCompleteModal
				isOpen={isOpen}
				onClose={onClose}
				header={
					<TextInput
						placeholder={pageStrings.medicalProcedures}
						my={2}
						onKeyUp={(e) => {
							const input = e.target as HTMLInputElement;
							debounce.clearTimer();
							debounce.setTimer(setTimeout(() => store.searchMedicalProcedures.setValue(input.value), 500));
						}}
					/>
				}
				listProps={{
					data: store.autoCompleteMedicalProcedures.items,
					loading: store.autoCompleteMedicalProcedures.loader.isLoading,
					renderItem: (item) => (
						<Text
							key={item.id}
							onClick={() => onSelectMedicalProcedure(item)}
							cursor="pointer"
						>
							{item.name}
						</Text>
					),
					paginantionProps: {
						currentPage: store.autoCompleteMedicalProcedures.page,
						nextPage: store.autoCompleteMedicalProcedures.nextPage,
						prevPage: store.autoCompleteMedicalProcedures.previousPage,
						hasNextPage: store.autoCompleteMedicalProcedures.hasNextPage,
					},
				}}
			/>
			<EnumSelect
				items={api.allValuesSpeciality()}
				currentValue={store.specialty.value}
				label={commonStrings.fields.speciality}
				onChangeEnum={store.specialty.setValue}
				tranlateEnum={api.translateSpeciality}
			/>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
