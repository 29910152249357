import { LoaderShelf, AttributeShelf } from "@startapp/mobx-utils";
import { showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import format from "~/resources/format";

export default class Store {

	public loader = new LoaderShelf();

	public startDate = new AttributeShelf<Date | null>(null);
	public endDate = new AttributeShelf<Date | null>(null);

	public homeData = new AttributeShelf<api.HomeData | null>(null);
	public homeGraphicData = new AttributeShelf<api.GraphicData[]>([]);

	public selectedAmountFilter = new AttributeShelf<api.GraphicType>(api.GraphicType.price);
	public selectedPeriodFilter = new AttributeShelf<api.HomeGraphicType>(api.HomeGraphicType.hour);

	public graphicLabel = new AttributeShelf<string[]>([]);
	public graphicData = new AttributeShelf<number[]>([]);
	public clinicId = new AttributeShelf("");

	constructor() {}

	public fetch = async() => {
		await this.getHomeStatisticsData();
		await this.getHomeGraphicsData();
	}

	public getHomeStatisticsData = async() => {
		this.loader.tryStart();
		try {
			const homeData = await api.getHomeStatisticsData({
				startDate: this.startDate.value,
				endDate: this.endDate.value,
			});

			this.homeData.setValue(homeData || null);
		} catch (e){
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}

	public treatHour = (hour: number) => {
		const gmt = 3;
		const dayInHours = 24;

		if (hour < 2){
			return (dayInHours + hour) - gmt;
		}

		return hour - gmt;
	}

	public clearGraphic = () => {
		this.graphicData.setValue([]);
		this.graphicLabel.setValue([]);
	}

	public getHomeGraphicsData = async() => {
		this.loader.tryStart();
		try {
			this.clearGraphic();

			if (this.selectedPeriodFilter.value === "hour"){
				const { sales } = await api.getHomeGraphicsDataPerHour(
					this.selectedAmountFilter.value,
					{
						startDate: this.startDate.value,
						endDate: this.endDate.value,
					},
				);
				sales.map((value: api.GraphicHourReport) => {
					this.graphicData.setValue([...this.graphicData.value, value.value]);

					const hour = this.treatHour(value.hour);
					this.graphicLabel.setValue([...this.graphicLabel.value, `${hour.toString()}h`]);
				});
			}

			if (this.selectedPeriodFilter.value === "date"){
				const { sales } = await api.getHomeGraphicsDataPerDate(
					this.selectedAmountFilter.value,
					{
						startDate: this.startDate.value,
						endDate: this.endDate.value,
					},
				);
				sales.map((value: api.GraphicDateReport) => {
					this.graphicData.setValue([...this.graphicData.value, value.value]);
					this.graphicLabel.setValue([...this.graphicLabel.value, format.date(value.date)]);
				});
			}

			if (this.selectedPeriodFilter.value === "dateHour"){
				const { sales } = await api.getHomeGraphicsDataPerDateHour(
					this.selectedAmountFilter.value,
					{
						startDate: this.startDate.value,
						endDate: this.endDate.value,
					},
				);
				sales.map((value: api.GraphicDateHourReport) => {
					this.graphicData.setValue([...this.graphicData.value, value.value]);
					this.graphicLabel.setValue([...this.graphicLabel.value, format.dateWithHour(value.dateHour)]);
				});
			}

		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}

}
