const blue = {
	50: "#E5FDFF",
	100: "#B8F9FF",
	200: "#8AF6FF",
	300: "#5CF2FF",
	400: "#2EEFFF",
	500: "#00B0BF",
	600: "#00BCCC",
	700: "#008D99",
	800: "#005E66",
	900: "#002F33",
};

const green = {
	50: "#F2F9EC",
	100: "#DAEEC9",
	200: "#C3E3A6",
	300: "#ACD883",
	400: "#94CC60",
	500: "#7DC13E",
	600: "#649B31",
	700: "#4B7425",
	800: "#324D19",
	900: "#19270C",
};

export const colors = {
	blue,
	green,
	primary: blue,
};
