import React from "react";
import {
	Heading,
	Box,
	Flex,
} from "@chakra-ui/react";
import { ExpertiseButton } from "./ExpertiseButton";
import strings from "~/resources/strings";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";

interface IProps{
	onAdd?: (value: api.Speciality) => void;
	onRemove?: (value: api.Speciality) => void;
	expertises: api.Speciality[];
	isDisabled?: boolean;
	isSelected?: boolean;
	allSelectedsSpecialities?: api.Speciality[];
}

export const ExpertiseSelect: React.FC<IProps> = observer((props) => {

	const {
		expertises,
		isDisabled,
		onAdd,
		onRemove,
		allSelectedsSpecialities,
	} = props;

	const componentStrings = strings.components.expertiseSelect;

	return (
		<Box>
			<Heading size="sm">
				{componentStrings.title}
			</Heading>
			<Flex
				wrap="wrap"
			>
				{expertises.map((value, index)=>(
					<Box key={index} mt={3} mr={2}>
						<ExpertiseButton isDisabled={isDisabled}
							name={api.translateSpeciality(value)}
							onAdd={onAdd}
							onRemove={onRemove}
							isSelected={!!allSelectedsSpecialities?.find((selected) => value === selected)}
						/>
					</Box>
				))}
			</Flex>
		</Box>

	);
});
