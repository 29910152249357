import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Button,
	Box,
	Image,
	Flex,
	Switch,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Loading,
	Label,
	ExpertiseSelect,
	DocumentAttach,
	DetailsRow,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { EditIcon } from "@chakra-ui/icons";
import imagePlaceholder from "../../../../../static/pick_image.svg";

interface IParams {
	id?: string;
	clinicId?: string;
}

const Details: React.FC = () => {
	const pageCommonStrings = strings.professionals.common;
	const commonStrings = strings.common;

	const { clinicId, id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id));

	const onGoToEditProfessional = (professionalId: string) => history.push(`/dashboard/professionals/edit/${professionalId}`);
	const goBack = () => history.goBack();

	return (
		<CentralizedCard
			title={{
				text: commonStrings.detailsTitle,
				helper: !clinicId ? (
					<Tooltip label={strings.common.edit}>
						<IconButton
							variant="icon"
							aria-label="Edit"
							size="lg"
							icon={<EditIcon w="24px" h="24px" />}
							onClick={() => onGoToEditProfessional(id || "")}
						/>
					</Tooltip>
				) : undefined,
			}}
			button={(
				<Button
					variant="outline"
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={goBack}
				>
					{strings.actions.back}
				</Button>
			)}
		>
			{store.modelShelf.initialLoader.isLoading ?
				(
					<Loading />
				) : (
					<>
						<Box>
							<Label fontWeight="bold" marginBottom={1}>
								{commonStrings.fields.photo}
							</Label>
							<Image
								width={120}
								height={120}
								p={0}
								m={0}
								src={store.modelShelf._model.image ? store.modelShelf._model.image.url : imagePlaceholder}
								fallbackSrc={imagePlaceholder}
								rounded="lg"
							/>
						</Box>
						<DetailsRow
							label={commonStrings.fields.name}
							value={store.modelShelf._model.name}
						/>
						<DetailsRow
							value={store.modelShelf._model.description}
							label={commonStrings.fields.description}
						/>
						{store.modelShelf.model.specialities.length > 0 && (
							<ExpertiseSelect
								isDisabled
								expertises={store.modelShelf.model.specialities}
								onAdd={store.modelShelf.fetchModel}
								onRemove={store.modelShelf.fetchModel}
							/>
						)}
						<DocumentAttach
							title={commonStrings.fields.resume}
							fileName={pageCommonStrings.resumeFileName}
							url={store.modelShelf.model.resume}
							isDisabled
						/>
						<DocumentAttach
							title={commonStrings.fields.cnes}
							fileName={pageCommonStrings.cnesFileName}
							url={store.modelShelf.model.cnes}
							isDisabled
						/>
						<DocumentAttach
							title={commonStrings.fields.ccmOrIss}
							fileName={pageCommonStrings.ccmOrIssFileName}
							url={store.modelShelf.model.ccmOrIss}
							isDisabled
						/>
						<Flex alignItems="center">
							<Label fontWeight="bold" marginRight={2}>
								{commonStrings.fields.disable}
							</Label>
							<Switch
								isChecked={store.isBlocked.value}
								onChange={store.changeProfessionalBlockStatus}
								size="lg"
							/>
						</Flex>
					</>
				)}
		</CentralizedCard>
	);
};

export default observer(Details);
