import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	FlexProps,
} from "@chakra-ui/react";
import { Card, Fetchable, EmptyList, DropdownEnum } from "~/components";
import strings from "~/resources/strings";
import { colors } from "~/themes/colors";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import format from "~/resources/format";
import api from "~/resources/api";

Chart.register(CategoryScale);

interface IProps extends FlexProps {
	selectedPeriodFilter: api.HomeGraphicType;
	selectedAmountFilter: api.GraphicType;
	data: number[];
	graphicLabel: number[] | string[];
	onSelectPeriodFilter: (value: api.HomeGraphicType) => void;
	onSelectAmountFilter: (value: api.GraphicType) => void;
	loading?: boolean;
	hasError?: boolean;
}

export const Graphic: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.components.graphic;
	const {
		selectedAmountFilter,
		onSelectAmountFilter,
		selectedPeriodFilter,
		onSelectPeriodFilter,
		graphicLabel,
		data,
		loading,
		hasError,
		...rest
	} = props;

	return (
		<Flex
			w="100%"
			flexDir="column"
			{...rest}
		>
			<Card
				mb={{base: 5, lg: 0}}
				borderRadius={12}
				boxShadow="lg"
				cursor="pointer"
				p={{base:2, sm: 5}}
				bg="white"
			>
				<Fetchable loading={loading}>
					{
						!hasError
							? (
								<>
									<DropdownEnum
										tranlateEnum={api.translateHomeGraphicType}
										itemList={api.allValuesHomeGraphicType()}
										onSelectItem={onSelectPeriodFilter}
										selectedItem={selectedPeriodFilter}
									/>
									<DropdownEnum
										tranlateEnum={api.translateGraphicType}
										itemList={api.allValuesGraphicType()}
										onSelectItem={onSelectAmountFilter}
										selectedItem={selectedAmountFilter}
									/>
									<Bar
										data={{
											labels: [...graphicLabel],
											datasets: [
												{
													label: api.translateGraphicType(selectedAmountFilter),
													type: "bar",
													data,
													backgroundColor: colors.primary[500],
													maxBarThickness: 30,
												},
											],
										}}
										options={{
											scales: {
												y: {
													ticks: {
														callback: (value: string) => selectedAmountFilter === api.GraphicType.price ? format.currencyForBR(Number(value)) : value,
													},
												},
											},
											plugins: {
												tooltip: {
													callbacks: {
														label: (context) => {
															let label = context.dataset.label || "";

															if (label) {
																label += ": ";
															}
															if (context.parsed.y !== null) {
																if (selectedAmountFilter === api.GraphicType.price){
																	label += format.currencyForBR(context.parsed.y);
																} else {
																	label += context.parsed.y;
																}
															}
															return label;
														},
													},
												},
											},
										}}
									/>
								</>
							)
							: <EmptyList text={componentStrings.error} />
					}
				</Fetchable>
			</Card>
		</Flex>
	);
});
