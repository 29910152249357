import { LoaderShelf, ModelShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { makeAutoObservable } from "mobx";

export default class Store{

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.ClinicPackage>;


	constructor(id: string) {
		makeAutoObservable(this);

		if (id) {
			this.modelShelf = new ModelShelf(id, api.getClinicPackage);
		}
	}
}
