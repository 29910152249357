import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	BoxProps,
	Textarea,
	TextareaProps,
} from "@chakra-ui/react";
import { Label } from "..";

export interface IProps extends Omit<TextareaProps, "value">{
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	labelText?: string;
	value?: string;
	helper?: string;
	boxProps?: BoxProps;
}

export const TextareaInput: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		labelText,
		helper,
		boxProps,
		value,
		...rest
	} = props;

	return (
		<Box {...boxProps}>
			<Label fontWeight="bold" marginBottom={1} helper={helper}>{labelText}</Label>
			<Textarea
				minH="200px"
				borderWidth={2}
				backgroundColor="gray.100"
				_hover={{
					borderWidth: 2,
					borderColor: "primary.700",
				}}
				onChange={onChange}
				value={value}
				defaultValue={value || undefined}
				{...rest}
			/>
		</Box>
	);
});
