import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Box } from "@chakra-ui/react";

import {
	CentralizedCard,
	TextInput,
	TextareaInput,
	ImagePicker,
	Label,
	ExpertiseSelect,
	DocumentAttach,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import api from "~/resources/api";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.professionals.createOrEdit;
	const pageCommonStrings = strings.professionals.common;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditProfessional(onSuccess);
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title(!!id),
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{commonStrings.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{commonStrings.fields.photo}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("name")}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.cpf}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("documentNumber")}
				{...store.formShelf.field("documentNumber")}
			/>
			<TextInput
				labelText={commonStrings.fields.IdentificationNumber}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("identificationNumber")}
				{...store.formShelf.field("identificationNumber")}
			/>
			<TextareaInput
				onChange={store.formShelf.field("description").onChange}
				labelText={commonStrings.fields.description}
				value={store.formShelf.field("description").value}
			/>
			<ExpertiseSelect
				expertises={api.allValuesSpeciality()}
				allSelectedsSpecialities={store.specialities}
				onAdd={store.addSpeciality}
				onRemove={store.removeSpeciality}
			/>
			<DocumentAttach
				title={commonStrings.fields.resume}
				pickFile={store.resume.getPickerFields().pick}
				fileName={pageCommonStrings.resumeFileName}
				onDelete={store.resume.getPickerFields().clear}
				url={store.resume.uncertainfiedFile?.url || null}
			/>
			<DocumentAttach
				title={commonStrings.fields.cnes}
				pickFile={store.cnes.getPickerFields().pick}
				fileName={pageCommonStrings.cnesFileName}
				onDelete={store.cnes.getPickerFields().clear}
				url={store.cnes.uncertainfiedFile?.url || null}
			/>
			<DocumentAttach
				title={commonStrings.fields.ccmOrIss}
				pickFile={store.ccmOrIss.getPickerFields().pick}
				fileName={pageCommonStrings.ccmOrIssFileName}
				onDelete={store.ccmOrIss.getPickerFields().clear}
				url={store.ccmOrIss.uncertainfiedFile?.url || null}
			/>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
