import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";

import { ClinicForm } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.clinics.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditClinic(onSuccess);
	};

	return (
		<>
			<ClinicForm
				title={pageStrings.title(!!id)}
				isLoading={store.loader.isLoading}
				formValues={{
					field: store.formShelf.field,
					address: {
						onSelectAddress: store.onSelectAddress,
					},
					image: {
						pick: store.imageShelf.getPickerFields().pick,
						src: store.imageShelf.src,
					},
					specialities: {
						items: store.specialities,
						onAdd: store.addSpeciality,
						onRemove: store.removeSpeciality,
					},
				}}
				submit={{
					onClick: onSubmit,
					text: commonStrings.buttons.confirmButton(!!id),
					isLoading: store.loader.isLoading,
				}}
			/>

		</>
	);
};

export default observer(CreateOrEdit);
