import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

interface IParams {
	id?: string;
}

const Edit: React.FC = () => {
	const pageStrings = strings.medicalProcedure.edit;
	const commonStrings = strings.common;
	const history = useHistory();
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));

	const onSuccess = () => {
		history.goBack();
	};

	const onSubmit = () => {
		store.onCreateOrEditMedicalProcedure(onSuccess);
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title(id),
			}}
			button={(
				<Button
					w="100%"
					maxW={280}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{commonStrings.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("name")}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.description}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("description")}
				{...store.formShelf.field("description")}
			/>


		</CentralizedCard>
	);
};

export default observer(Edit);
