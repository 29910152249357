import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";

import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Package> {

	public loader = new LoaderShelf();
	public numberOfPackages = new AttributeShelf(0);

	constructor() {
		super();
		this.fetchPage(0);
		this.getTotalPackages();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Package[]> {
		return api.getAllPackages(page);
	}

	public getTotalPackages = async () => {
		try {
			const response = await api.getTotalNumberOfPackages();
			this.numberOfPackages.setValue(response);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public deletePackage = async (id: string) => {
		this.loader.tryStart();
		try {
			const deletedPackage = await api.deletePackage(id);

			showSuccessToast(strings.packages.table.delete(deletedPackage.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}
