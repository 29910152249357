import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Edit from "./Edit";

const OrdersForClinic: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/orders" component={Table} />
		<Route path="/dashboard/orders/edit/:id" component={Edit} />
	</Switch>
);

export default observer(OrdersForClinic);
