import React from "react";
import {
	Text,
	Flex,
	Box,
	Button,
	Link,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";

export interface IProps {
	title: string;
	url: string | null;
	fileName: string | null;
	pickFile?: () => void;
	onDelete?: () => void;
	isDisabled?: boolean;
}

export const DocumentAttach: React.FC<IProps> = (props) => {

	const {
		title,
		pickFile,
		fileName,
		onDelete,
		isDisabled,
		url,
		...rest
	} = props;
	const componentStrings = strings.components.documentAttach;

	return (
		<Box
			width="100%"
			{...rest}
		>
			<Text fontWeight="bold" >{`${title}`}</Text>
			<Flex
				w="100%"
				flexWrap="wrap"
			>
				<Button
					variant="outline"
					mr={2}
					my={2}
					onClick={pickFile}
					isDisabled={isDisabled}
				>
					{componentStrings.attachDocument}
				</Button>
				{
					url &&
						<Button
							isDisabled={isDisabled}
							mr={2}
							my={2}
							color="gray.700"
							fontWeight="initial"
							colorScheme="gray"
							background="gray.200"
							leftIcon={<CloseIcon w="12px" onClick={onDelete} />}
						>
							<Link isExternal href={url ? url : ""} target="_blank" >{fileName}</Link>
						</Button>
				}
			</Flex>
		</Box>

	);
};
