import { makeAutoObservable, reaction } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.clinicPackages.createOrEdit;

export default class Store {

	private id?: string;

	public loader = new LoaderShelf();

	public clinicId = new AttributeShelf("");
	public clinic = new AttributeShelf<api.Clinic | null>(null);
	public package = new AttributeShelf<api.Package | null>(null);
	public searchPackages = new AttributeShelf("");
	public price = new AttributeShelf(0);

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id = id;
			this.getClinicPackage(id);
		}
	}

	public getClinicPackage = async (id: string) => {
		this.loader.tryStart();
		try {
			const clinicPackages = await api.getClinicPackage(id);
			this.setInitValues(clinicPackages);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (clinicPackage: api.ClinicPackage) => {

		this.clinic.setValue(clinicPackage.clinic);
		this.package.setValue(clinicPackage.package);
		this.price.setValue(clinicPackage.price);
	};

	public onCreateOrEditClinicPackage = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			if (!this.package.value){
				return showErrorToast(pageStrings.error.package);
			}

			if (this.id) {
				if (!this.clinic.value){
					return showErrorToast(pageStrings.error.clinic);
				}
				await api.editClinicPackage(this.id, {
					clinic: this.clinic.value,
					package: this.package.value,
					price: this.price.value,
				});
				showSuccessToast(pageStrings.success(this.id));

			} else {
				await api.createClinicPackage({
					clinicId: this.clinicId.value,
					packageId: this.package.value.id,
					price: this.price.value,
				});
				showSuccessToast(pageStrings.success());
			}
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	private autoCompleteReaction = reaction(() => this.searchPackages.value,
		() => this.autoCompletePackages.refresh(),
	);

	public autoCompletePackages= new PaginatedListShelf(
		async (page: number) => await api.autoCompletePackages(page, this.searchPackages.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};

}
