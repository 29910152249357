import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export default class ClinicPackageShelf extends AbstractPaginatedListStore<api.ClinicPackage>{

	public loader = new LoaderShelf();
	public clinicId = new AttributeShelf("");

	constructor(clinicId: string) {
		super();
		this.clinicId.setValue(clinicId);
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.ClinicPackage[]> {
		return api.getAllClinicPackageByClinicId(this.clinicId.value, page);
	}

	public deleteClinicPackage = async (id: string, clinicName: string) => {
		this.loader.tryStart();
		try {

			const deletedClinicPackage = await api.deleteClinicPackage(id);

			showSuccessToast(strings.clinics.details.clinicPackages.delete(clinicName, deletedClinicPackage.package.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
