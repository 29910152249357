import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";
import format from "../../../../resources/format";
import api from "../../../../resources/api";
import { showInfoToast } from "../../../../resources/toast";

const TableView: React.FC = () => {
	const pageStrings = strings.orders.table;
	const store = useLocalObservable(() => new Store());
	const { dialog, authStore } = useGlobalStore();

	const history = useHistory();

	const onGoToEditOrder = (id: string) => history.push(`orders/edit/${id}`);

	const openDialogCancelOrder = (orderId: string) => {
		const modalCancelOrder = strings.orders.modal;
		dialog.showDialog({
			title: modalCancelOrder.title,
			closeOnOverlayClick: true,
			description: modalCancelOrder.description,
			buttons: [
				{
					title: modalCancelOrder.button.yes,
					onPress: () => {
						store.cancelOrder(orderId);
						dialog.closeDialog();
					},
					buttonProps: { bg: "green.500" },
				},
				{
					title: modalCancelOrder.button.no,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const closedStatus = [api.OrderPaymentStatus.finished, api.OrderPaymentStatus.canceled];

	const showClosedStatusToast = (status: api.OrderPaymentStatus) => {
		const messages = {
			finished: pageStrings.finished,
			canceled: pageStrings.canceled,
		};

		showInfoToast(messages[status]);
	};

	const currentUserIsClinic = authStore.currentAdminUser && authStore.currentAdminUser.clinic;

	React.useEffect(() => {
		if (authStore.currentAdminUser){
			if (authStore.currentAdminUser.clinic){
				store.pendingOrderShelf.clinicId.setValue(authStore.currentAdminUser.clinic.id);
				store.orderShelf.clinicId.setValue(authStore.currentAdminUser.clinic.id);

				store.pendingOrderShelf.fetchPage(0);
				store.orderShelf.fetchPage(0);
				store.getTotalOrders();
			} else {
				store.orderShelf.fetchPage(0);
				store.getTotalOrders();
			}
		}

	}, [authStore.currentAdminUser]);

	return (
		<Flex
			flexDirection="column"
			p={{ base: "2", lg: "16" }}
		>
			<TotalInfo
				width="100%"
				total={store.totalOrders.value}
				text={pageStrings.totalText}
			/>
			{currentUserIsClinic &&
				<Table
					marginBottom={10}
					tableTitle={pageStrings.pendingOrdersTitle}
					data={store.pendingOrderShelf._items}
					headers={pageStrings.header}
					renderRow={(item) => (
						<Tr key={item.id} >
							<TableCellWithActionButtons
								onEdit={() => onGoToEditOrder(item.id)}
								onBlock={() => {
									if (!closedStatus.includes(item.paymentStatus)) {
										openDialogCancelOrder(item.id);
									} else {
										showClosedStatusToast(item.paymentStatus);
									}
								}}
								isBlocked={item.paymentStatus === api.OrderPaymentStatus.canceled}
							/>
							<Td>
								<Text>{format.date(item.createdAt)}</Text>
							</Td>
							<Td>
								<Text>{item.user.name}</Text>
							</Td>
						</Tr>
					)}
					loading={store.pendingOrderShelf._loading}
					emptyMessage={strings.common.noResutls}
					currentPage={store.pendingOrderShelf.page}
					prevPage={store.pendingOrderShelf.previousPage}
					nextPage={store.pendingOrderShelf.nextPage}
					hasNextPage={store.pendingOrderShelf._isHaveNextPage}
				/>}

			<Table
				tableTitle={pageStrings.ordersTitle}
				data={store.orderShelf._items}
				headers={pageStrings.header}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onEdit={() => onGoToEditOrder(item.id)}
							onBlock={() => {
								if (!closedStatus.includes(item.paymentStatus)) {
									openDialogCancelOrder(item.id);
								} else {
									showClosedStatusToast(item.paymentStatus);
								}
							}}
							isBlocked={item.paymentStatus === api.OrderPaymentStatus.canceled}
						/>
						<Td>
							<Text>{format.date(item.createdAt)}</Text>
						</Td>
						<Td>
							<Text>{item.user.name}</Text>
						</Td>
					</Tr>
				)}
				loading={store.orderShelf._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.orderShelf.page}
				prevPage={store.orderShelf.previousPage}
				nextPage={store.orderShelf.nextPage}
				hasNextPage={store.orderShelf._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
