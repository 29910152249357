import React from "react";
import {
	Text,
	Flex,
	BoxProps,
} from "@chakra-ui/react";
import { Card } from "..";

interface IProps extends BoxProps{
	title: string;
	description: string;
}

export const InfoCard: React.FC<IProps> = (props) => {

	const { title, description, ...rest } = props;

	return (
		<Card
			mb={{base: 5, lg: 0}}
			p={5}
			borderRadius={12}
			boxShadow="lg"
			bg="white"
			{...rest}
		>
			<Flex
				flexDirection="column"
				w="100%"
				maxW={120}
				h="100%"
				justifyContent="space-between"
				mx="auto"
			>
				<Text textAlign="center">{title}</Text>
				<Text
					mt={2}
					color="primary.500"
					fontWeight="bold"
					textAlign="center"
				>
					{description}
				</Text>
			</Flex>
		</Card>

	);
};
