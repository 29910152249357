import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.medicalProcedure.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onGoToEditProcedure = (id: string) => history.push(`medicalProcedures/edit/${id}`);
	const onGoToCreateProcedure = () => history.push("medicalProcedures/create");

	const onDeleteProcedure = (id: string) => {
		store.deleteMedicalProcedure(id);
		dialog.closeDialog();
	};

	const openDialog = (service: api.MedicalProcedure) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(service.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteProcedure(service.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<TotalInfo
				total={store.numberOfProcedures.value}
				text={pageStrings.totalText}
				w="100%"
			/>
			<Table
				data={store._items}
				headers={pageStrings.header}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onEdit={() => onGoToEditProcedure(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>
							<Text>{item.name}</Text>
						</Td>
						<Td>
							<Text isTruncated>{item.description}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
			<Button
				onClick={onGoToCreateProcedure}
				w={250}
				mt={8}
				mx="auto"
			>
				{pageStrings.create}
			</Button>
		</Flex>
	);
};

export default observer(TableView);
