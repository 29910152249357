import { LoaderShelf, AttributeShelf } from "@startapp/mobx-utils";
import { makeAutoObservable } from "mobx";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import OrderShelf from "./Shelves/OrderShelf";
import PendingOrderShelf from "./Shelves/PendingOrderShelf";

export default class Store{

	public loader = new LoaderShelf();
	public orderShelf = new OrderShelf();
	public pendingOrderShelf = new PendingOrderShelf();
	public totalOrders = new AttributeShelf(0);

	constructor() {
		makeAutoObservable(this);
	}

	public cancelOrder = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.cancelOrder(id);
			showSuccessToast(strings.orders.table.cancelMessage);
			this.orderShelf.refresh();
			this.pendingOrderShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getTotalOrders = async() => {
		try {
			if (this.orderShelf.clinicId.value) {
				const orders = await api.getTotalAmountOfOrdersByClinic(this.orderShelf.clinicId.value);
				this.totalOrders.setValue(orders);
			} else {
				const orders = await api.getTotalAmountOfOrders();
				this.totalOrders.setValue(orders);
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}
}
