import { makeAutoObservable, reaction } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.packages.edit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
	});

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");
	public packages = new AttributeShelf<api.Package | null>(null);
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public medicalProcedures = new AttributeShelf<api.MedicalProcedure[]>([]);
	public autoCompleteModal = new AttributeShelf<boolean>(false);
	public searchMedicalProcedures = new AttributeShelf("");
	public specialty = new AttributeShelf<api.Speciality>(api.Speciality.cardiology);

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getPackage(id);
		}
	}

	public getPackage = async (id: string) => {
		this.loader.tryStart();
		try {
			const packages = await api.getPackage(id);
			this.packages.setValue(packages);
			if (this.packages.value) {
				this.setInitValues(this.packages.value);
			}
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (packages: api.Package) => {
		this.formShelf = new FormShelf({
			name: packages.name,
		});
		this.medicalProcedures.setValue(packages.medicalProcedures);
		this.specialty.setValue(packages.speciality);
		if (packages.image) {
			this.imageShelf.getPickerFields().setUploadedImage(packages.image);
		}
	};

	public onCreateOrEditPackage = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				name,
			} = data;

			if (this.id.value) {
				await api.editPackage(this.id.value, {
					name,
					image: this.imageShelf.uncertainfiedImage,
					medicalProcedures: this.medicalProcedures.value,
					speciality: this.specialty.value,
				});
				showSuccessToast(pageStrings.success(this.id.value));
			} else {
				await api.createPackage({
					name,
					image: this.imageShelf.uncertainfiedImage,
					medicalProcedures: this.medicalProcedures.value,
					speciality: this.specialty.value,
				});
				showSuccessToast(pageStrings.success());
			}
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	private autoCompleteReaction = reaction(() => this.searchMedicalProcedures.value,
		() => this.autoCompleteMedicalProcedures.refresh(),
	);

	public autoCompleteMedicalProcedures = new PaginatedListShelf(
		async (page: number) => await api.autoCompleteMedicalProcedures(page, this.searchMedicalProcedures.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};

}
