import React from "react";
import {
	Box,
	BoxProps,
	Input,
	RadioProps,
	useRadio,
} from "@chakra-ui/react";

interface IProps{
	radioProps: RadioProps;
	onClick: () => void;
	boxProps?: BoxProps;
}

export const RadioCard: React.FC<IProps> = (props) => {
	const { radioProps, onClick,boxProps } = props;
	const { getInputProps, getCheckboxProps } = useRadio(radioProps);

	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
		<Box
			as='label'
			w="100%"
			bg="white"
			borderRadius='md'
			boxShadow='md'
			borderWidth='1px'
			{...boxProps}
			{...checkbox}
			_checked={{
				bg: "primary.600",
				color: "white",
				borderColor: "primary.600",
			}}
		>
			<Input display="none" {...input} />
			<Box
				{...checkbox}
				minW={180}
				onClick={onClick}
				cursor='pointer'
				_focus={{
					boxShadow: "outline",
				}}
				px={5}
				py={3}
			>
				{props.children}
			</Box>
		</Box>
	);

};
