import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
	Td,
	Tr,
	Text,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import AdminClinicUserShelf from "../../Shelves/AdminClinicUserShelf";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IProps {
	id?: string;
	adminClinicUserShelf: AdminClinicUserShelf;
}

const AdminClinicUsers: React.FC<IProps> = (props) => {
	const { id, adminClinicUserShelf } = props;

	const adminClinicUsersStrings = strings.clinics.details.users;
	const modal = strings.common.modal;

	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onDeleteAdminClinicUser = (adminClinicUserid: string) => {
		adminClinicUserShelf.deleteAdminClinicUser(adminClinicUserid);
		dialog.closeDialog();
	};

	const onGoToAdminClinicUserDetails = (adminClinicUserId: string) => history.push(`/dashboard/clinics/${id}/detailsUser/${adminClinicUserId}`);
	const onGoToEditAdminClinicUser = (adminClinicUserId: string) => history.push(`/dashboard/clinics/${id}/editUser/${adminClinicUserId}`);
	const onGoToCreateAdminClinicUser = () => history.push(`/dashboard/clinics/${id}/createUser`);

	const openDialog = (adminClinicUser: api.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(adminClinicUser.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteAdminClinicUser(adminClinicUser.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<CentralizedCard
			title={{
				text: adminClinicUsersStrings.tableTitle,
			}}
			isTable
		>
			<Table
				isCard
				data={adminClinicUserShelf._items}
				headers={adminClinicUsersStrings.tableHeaders}
				onAdd={onGoToCreateAdminClinicUser}
				addButtonText={adminClinicUsersStrings.tableAddButton}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onView={() => onGoToAdminClinicUserDetails(item.id)}
							onEdit={() => onGoToEditAdminClinicUser(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>
							<Text>{item.name}</Text>
						</Td>
					</Tr>
				)}
				loading={adminClinicUserShelf._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={adminClinicUserShelf.page}
				prevPage={adminClinicUserShelf.previousPage}
				nextPage={adminClinicUserShelf.nextPage}
				hasNextPage={adminClinicUserShelf._isHaveNextPage}
			/>
		</CentralizedCard>
	);
};

export default observer(AdminClinicUsers);
