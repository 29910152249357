import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEdit";
import Details from "./Details";
import CreateAdminClinicUsers from "./CreateOrEditAdminClinicUsers";
import DetailsAdminClinicUsers from "./DetailsAdminClinicUsers";
import CreateOrEditClinicPackage from "./CreateOrEditClinicPackage";
import DetailsClinicPackage from "./DetailsClinicPackage";
import DetailsClinicOrder from "./DetailsClinicOrder";
import DetailsClinicProfessional from "../Professionals/Details";

const Clinics: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/clinics" component={Table} />
		<Route
			path="/dashboard/clinics/create/"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/clinics/edit/:id"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/clinics/details/:id"
			component={Details}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/createUser"
			component={CreateAdminClinicUsers}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/editUser/:userId"
			component={CreateAdminClinicUsers}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/detailsUser/:userId"
			component={DetailsAdminClinicUsers}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/createClinicPackage"
			component={CreateOrEditClinicPackage}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/editClinicPackage/:clinicPackageId"
			component={CreateOrEditClinicPackage}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/detailsClinicPackage/:clinicPackageId"
			component={DetailsClinicPackage}
		/>
		<Route
			path="/dashboard/clinics/:id/detailsClinicOrder/:clinicOrderId"
			component={DetailsClinicOrder}
		/>
		<Route
			path="/dashboard/clinics/:clinicId/detailsClinicProfessional/:id"
			component={DetailsClinicProfessional}
		/>
	</Switch>
);

export default observer(Clinics);
