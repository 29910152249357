import React from "react";
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface IProps<EnumType>{
	itemList: EnumType[];
	selectedItem: EnumType;
	onSelectItem: (value: EnumType) => void;
	tranlateEnum: (item: EnumType) => string;
}

export const DropdownEnum = <EnumType extends string>(props: IProps<EnumType>) => {
	const {
		itemList,
		selectedItem,
		onSelectItem,
		tranlateEnum,
	} = props;

	return (
		<Menu>
			<MenuButton
				px={4}
				py={2}
				transition='all 0.2s'
				colorscheme="black"
				fontWeight="bold"
			>
				{tranlateEnum(selectedItem)} <ChevronDownIcon color="primary.500" />
			</MenuButton>
			<MenuList>
				{itemList.map((value)=>(
					<MenuItem key={value} onClick={()=>onSelectItem(value)}>
						{tranlateEnum(value)}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};
