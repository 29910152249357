import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Button,
	Box,
	Switch,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	EnumSelect,
	ImagePicker,
	Label,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IParams {
	id?: string;
}

const Edit: React.FC = () => {
	const pageStrings = strings.users.edit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.EditUser(onSuccess);
	};

	const openDialogBlockedUser = () => {
		const modalBlockedUser = strings.users.modal;
		dialog.showDialog({
			title: modalBlockedUser.title,
			closeOnOverlayClick: true,
			description: modalBlockedUser.description(store.user.name, !!store.user?.blockedAt),
			buttons: [
				{
					title: store.user.blockedAt ?  modalBlockedUser.button.enable :  modalBlockedUser.button.disable,
					onPress: () => {
						store.changeUserBlockStatus();
						dialog.closeDialog();
						onSuccess();
					},
					buttonProps: { bg: store.user.blockedAt ? "green.500" : "red.500" },
				},
				{
					title: modalBlockedUser.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					w="100%"
					maxW={280}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{commonStrings.buttons.confirmButton}
				</Button>
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{commonStrings.fields.photo}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{fontWeight: "bold"}}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("name")}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{fontWeight: "bold"}}
				type="email"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.fieldError.getFieldError("email")}
				{...store.formShelf.field("email")}
			/>
			<TextInput
				labelText={commonStrings.fields.phone}
				labelProps={{fontWeight: "bold"}}
				type="text"
				isDisabled={store.loader.isLoading}
				mask="99 999999999"
				errorText={store.formShelf.fieldError.getFieldError("phone")}
				{...store.formShelf.field("phone")}
			/>
			<TextInput
				labelText={commonStrings.fields.cpf}
				labelProps={{fontWeight: "bold"}}
				type="text"
				isDisabled={store.loader.isLoading}
				mask="999.999.999-99"
				errorText={store.formShelf.fieldError.getFieldError("cpf")}
				{...store.formShelf.field("cpf")}
			/>
			<Box>
				<Label fontWeight="bold" marginBottom={8}>
					{commonStrings.fields.address}
				</Label>
				<Flex
					flexWrap="wrap"
					justifyContent="space-between"
				>
					<TextInput
						labelText={commonStrings.fields.street}
						boxProps={{
							marginBottom: 8,
							width: "100%",
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("street")}
						{...store.formShelf.field("street")}
					/>
					<TextInput
						labelText={commonStrings.fields.neighborhood}
						boxProps={{
							marginBottom: 8,
							width: {base:"100%", lg: "49%"},
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("neighborhood")}
						{...store.formShelf.field("neighborhood")}
					/>
					<TextInput
						labelText={commonStrings.fields.streetNumber}
						boxProps={{
							marginBottom: 8,
							width: {base:"100%", lg: "49%"},
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("streetNumber")}
						{...store.formShelf.field("streetNumber")}
					/>
					<TextInput
						labelText={commonStrings.fields.complementary}
						boxProps={{
							marginBottom: 8,
							width: "100%",
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("complementary")}
						{...store.formShelf.field("complementary")}
					/>
					<EnumSelect
						formProps={{
							marginBottom: 8,
							width: {base:"100%", lg: "49%"},
						}}
						label={commonStrings.fields.state}
						items={api.allValuesStateUF()}
						currentValue={store.stateUF.value}
						onChangeEnum={store.stateUF.setValue}
						tranlateEnum={api.translateStateUF}
					/>
					<TextInput
						labelText={commonStrings.fields.zipcode}
						boxProps={{
							marginBottom: 8,
							width: {base:"100%", lg: "49%"},
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("zipcode")}
						{...store.formShelf.field("zipcode")}
					/>
					<TextInput
						labelText={commonStrings.fields.city}
						boxProps={{
							marginBottom: 8,
							width: {base:"100%", lg: "49%"},
						}}
						type="text"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.fieldError.getFieldError("city")}
						{...store.formShelf.field("city")}
					/>
				</Flex>
			</Box>
			<Flex alignItems="center">
				<Label fontWeight="bold" marginRight={2}>
					{commonStrings.fields.disable}
				</Label>
				<Switch
					isChecked={!!store.user?.blockedAt}
					onChange={() => openDialogBlockedUser()}
					size="lg"
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(Edit);
