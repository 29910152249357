import { makeAutoObservable, reaction } from "mobx";
import { AttributeShelf, PaginatedListShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";


export default class Store {

	public searchMedicalProcedure = new AttributeShelf("");
	public medicalProcedure: AttributeShelf<api.MedicalProcedure | null> = new AttributeShelf(null);

	private autoCompleteReaction = reaction(() => this.searchMedicalProcedure.value,
		() => this.autoCompleteMedicalProcedure.refresh(),
	);

	constructor() {
		makeAutoObservable(this);
	}

	public autoCompleteMedicalProcedure = new PaginatedListShelf(
		async (page: number) => await api.autoCompleteMedicalProcedures(page, this.searchMedicalProcedure.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};
}
