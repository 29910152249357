import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";


import {
	BankForm,
	ClinicForm,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

interface IParams {
	id: string;
}

const ClinicProfile: React.FC = () => {
	const pageStrings = strings.clinics.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();

	const store = useLocalObservable(() => new Store(id));

	const onSubmitProfile = () => {
		store.EditClinic();
	};
	const onSubmitBankAccount = () => {
		store.createOrEditBanKAccount();
	};

	return (
		<>
			<ClinicForm
				title={pageStrings.title(!!id)}
				isLoading={store.loader.isLoading}
				formValues={{
					field: store.formShelf.field,
					address: {
						onSelectAddress: store.onSelectAddress,
					},
					image: {
						pick: store.imageShelf.getPickerFields().pick,
						src: store.imageShelf.src,
					},
					specialities: {
						items: store.specialities,
						onAdd: store.addSpeciality,
						onRemove: store.removeSpeciality,
					},
				}}
				submit={{
					onClick: onSubmitProfile,
					text: commonStrings.buttons.confirmButton(!!id),
					isLoading: store.loader.isLoading,
				}}
				isProfile
			/>
			<BankForm
				title={pageStrings.bankAccountTitle(!!store.formShelfBankAccount.getValue("account"))}
				formValues={{
					field: store.formShelfBankAccount.field,
					type: store.type,
				}}
				isLoading={store.loader.isLoading}
				submit={{
					onClick: onSubmitBankAccount,
					text: commonStrings.buttons.confirmButton(!!store.formShelfBankAccount.getValue("account")),
					isLoading: store.loader.isLoading,
				}}
			/>
		</>
	);
};

export default observer(ClinicProfile);
