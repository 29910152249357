import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, useDisclosure, Text } from "@chakra-ui/react";

import {
	CentralizedCard,
	TextInput,
	AutoCompleteModal,
	MoneyInput,
	Label,
	Loading,
} from "~/components";
import strings from "~/resources/strings";
import { useDebounce } from "~/hooks/useDebounce";
import api from "~/resources/api";
import Store from "./store";

interface IParams {
	clinicId: string;
	clinicPackageId?: string;
}

const CreateOrEditClinicPackage: React.FC = () => {
	const pageStrings = strings.clinics.details.clinicPackages.createOrEdit;
	const commonStrings = strings.common;

	const { isOpen, onClose, onOpen } = useDisclosure();
	const { clinicId, clinicPackageId } = useParams<IParams>();
	const history = useHistory();
	const debounce = useDebounce();

	const onSuccess = () => {
		history.goBack();
	};

	const onSelectPackage = (packages: api.Package) => {
		store.package.setValue(packages);
		onClose();
	};

	const store = useLocalObservable(() => new Store(clinicId, clinicPackageId));

	const onSubmit = () => {
		store.onCreateOrEditClinicPackage(onSuccess);
	};

	return (
		<>
			{
				store.loader.isLoading ? (
					<Loading />
				) : (
					<CentralizedCard
						title={{
							text: pageStrings.title(clinicPackageId),
						}}
						button={(
							<Button
								w="100%"
								maxW={280}
								size="lg"
								mt={10}
								isLoading={store.loader.isLoading}
								onClick={onSubmit}
							>
								{commonStrings.buttons.confirmButton(!!clinicPackageId)}
							</Button>
						)}
					>
						<TextInput
							labelText={pageStrings.autoCompleteLabel}
							onClick={onOpen}
							labelProps={{ fontWeight: "bold" }}
							value={store.package.value?.name || ""}
							isReadOnly
							cursor="pointer"
						/>
						<AutoCompleteModal
							isOpen={isOpen}
							onClose={onClose}
							header={
								<TextInput
									placeholder={pageStrings.titlePackages}
									my={2}
									onKeyUp={(e) => {
										const input = e.target as HTMLInputElement;
										debounce.clearTimer();
										debounce.setTimer(setTimeout(() => store.searchPackage.setValue(input.value), 500));
									}}
								/>
							}
							listProps={{
								data: store.autoCompletePackage.items,
								loading: store.autoCompletePackage.loader.isLoading,
								renderItem: (item) => (
									<Text
										key={item.id}
										onClick={() => onSelectPackage(item)}
										cursor="pointer"
									>
										{item.name}
									</Text>
								),
								paginantionProps: {
									currentPage: store.autoCompletePackage.page,
									nextPage: store.autoCompletePackage.nextPage,
									prevPage: store.autoCompletePackage.previousPage,
									hasNextPage: store.autoCompletePackage.hasNextPage,
								},
							}}
						/>
						<Label fontWeight="bold" marginBottom={-5}>
							{commonStrings.fields.value}
						</Label>
						<MoneyInput
							value={store.price.value.toString()}
							onChange={(value: string) => store.price.setValue(parseInt(value))}
						/>
					</CentralizedCard>
				)
			}
		</>
	);
};

export default observer(CreateOrEditClinicPackage);
