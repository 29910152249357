import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEdit";
import Details from "./Details";
const Packages: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/packages" component={Table} />
		<Route
			path="/dashboard/packages/edit/:id"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/packages/create/"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/packages/details/:id"
			component={Details}
		/>
	</Switch>
);

export default observer(Packages);
