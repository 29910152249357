import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
	Td,
	Tr,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	AutoCompleteModal,
	CentralizedCard,
	Table,
	TableCellWithActionButtons,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import ClinicProfessionalShelf from "../../Shelves/ClinicProfessionalShelf";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useDebounce } from "~/hooks/useDebounce";

interface IProps {
	id?: string;
	clinicProfessionalShelf: ClinicProfessionalShelf;
}

const ClinicProfessionals: React.FC<IProps> = (props) => {
	const { id, clinicProfessionalShelf } = props;
	const clinicProfessionalStrings = strings.clinics.details.clinicProfessionals;
	const modal = strings.common.modal;

	const history = useHistory();
	const { dialog } = useGlobalStore();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const debounce = useDebounce();

	const onDeleteClinicProfessional = (clinicProfessionalId: string) => {
		clinicProfessionalShelf.deleteClinicProfessional(clinicProfessionalId);
		dialog.closeDialog();
	};

	const onGoToClinicProfessionalDetails = (clinicProfessionalId: string) => history.push(`/dashboard/clinics/${id}/detailsClinicProfessional/${clinicProfessionalId}`);

	const openRemoveClinicProfessionalDialog = (clinicProfessional: api.Professional) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(clinicProfessional.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteClinicProfessional(clinicProfessional.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSelectProfessional = (clinicProfessional: api.Professional) => {
		clinicProfessionalShelf.professional.setValue(clinicProfessional);
		clinicProfessionalShelf.onCreateClinicProfessional(onClose);
		onClose();
	};

	return (
		<>
			<CentralizedCard
				title={{
					text: clinicProfessionalStrings.tableTitle,
				}}
				isTable
			>
				<Table
					isCard
					data={clinicProfessionalShelf._items}
					headers={clinicProfessionalStrings.tableHeaders}
					onAdd={onOpen}
					addButtonText={clinicProfessionalStrings.tableAddButton}
					renderRow={(item) => (
						<Tr key={item.id} >
							<TableCellWithActionButtons
								onDelete={() => openRemoveClinicProfessionalDialog(item)}
								onView={() => onGoToClinicProfessionalDetails(item.id)}
							/>
							<Td>
								<Text>{item.name}</Text>
							</Td>
						</Tr>
					)}
					loading={clinicProfessionalShelf._loading}
					emptyMessage={strings.common.noResutls}
					currentPage={clinicProfessionalShelf.page}
					prevPage={clinicProfessionalShelf.previousPage}
					nextPage={clinicProfessionalShelf.nextPage}
					hasNextPage={clinicProfessionalShelf._isHaveNextPage}
				/>
			</CentralizedCard>
			<AutoCompleteModal
				isOpen={isOpen}
				onClose={onClose}
				header={
					<TextInput
						placeholder={clinicProfessionalStrings.createOrEdit.titleProfessionals}
						my={2}
						onKeyUp={(e) => {
							const input = e.target as HTMLInputElement;
							debounce.clearTimer();
							debounce.setTimer(setTimeout(() => clinicProfessionalShelf.searchProfessional.setValue(input.value), 500));
						}}
					/>
				}
				listProps={{
					data: clinicProfessionalShelf.autoCompleteProfessional.items,
					loading: clinicProfessionalShelf.autoCompleteProfessional.loader.isLoading,
					renderItem: (item) => (
						<Text
							key={item.id}
							onClick={() => onSelectProfessional(item)}
							cursor="pointer"
						>
							{item.name}
						</Text>
					),
					paginantionProps: {
						currentPage: clinicProfessionalShelf.autoCompleteProfessional.page,
						nextPage: clinicProfessionalShelf.autoCompleteProfessional.nextPage,
						prevPage: clinicProfessionalShelf.autoCompleteProfessional.previousPage,
						hasNextPage: clinicProfessionalShelf.autoCompleteProfessional.hasNextPage,
					},
				}}
			/>
		</>
	);
};

export default observer(ClinicProfessionals);
