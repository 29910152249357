import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export default class Store extends AbstractPaginatedListStore<api.AdminUser>{

	public loader = new LoaderShelf();
	public clinicId = new AttributeShelf("");
	public totalClinics = new AttributeShelf(0);

	constructor() {
		super();
		this.getTotalAmountOfAdminClinicsUsersByClinicId();

	}

	protected getDataItemsPerPage(page: number): Promise<api.AdminUser[]> {
		if (this.clinicId.value) {
			return api.getAllAdminClinicUsersByClinicId(page, this.clinicId.value);
		} else {
			return api.getAllAdminClinicUsers(page);
		}
	}

	public deleteAdminClinicUser = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedAdminClinicUser = await api.deleteAdminUser(id);

			showSuccessToast(strings.users.table.delete(deletedAdminClinicUser.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getTotalAmountOfAdminClinicsUsersByClinicId = async () => {
		try {
			const clinics = await api.getTotalAmountOfAdminClinicsUsersWithFilter(this.clinicId.value);
			this.totalClinics.setValue(clinics);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}
}
