import React from "react";
import {
	Center,
	CenterProps,
	Box,
	HStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { AddressAutoCompleteInput, TextInput } from "..";
import * as mapsTypes from "~/declarations/maps";
import strings from "~/resources/strings";
import api from "~/resources/api";

export type AddressField = "street" | "neighborhood" | "streetNumber" | "complementary";

interface IProps extends CenterProps {
	address: Omit<api.Address, "placeId" | "formattedAddress" | "zipcode" | "city" | "state" | "countryCode">;
	addressSearch: string;
	onHandleChange: (field: AddressField, value: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeAddressSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onSelectAddress: (place: mapsTypes.IPlaceDetailsResult) => void;
	isLoading: boolean;
	disableForm: boolean;
}

const componentStrings = strings.components.addressForm;

export const AddressForm: React.FC<IProps> = observer((props) => {

	const {
		address,
		addressSearch,
		onHandleChange,
		onChangeAddressSearch,
		onSelectAddress,
		isLoading,
		disableForm,
		...rest
	} = props;

	return (
		<Center w="100%" flexDir="column" {...rest}>
			<Box width="100%">
				<AddressAutoCompleteInput
					labelText={componentStrings.title}
					onChange={onChangeAddressSearch}
					value={addressSearch}
					onSelected={onSelectAddress}
					placeholder={componentStrings.addressPlaceholder}
					mb={4}
					isDisabled={isLoading}
				/>
				<TextInput
					labelText={componentStrings.street}
					placeholder={componentStrings.street}
					value={address.street}
					onChange={(e) => onHandleChange("street", e)}
					isDisabled={isLoading || disableForm}
				/>
				<HStack m={0}>
					<TextInput
						boxProps={{ paddingY: 4, w: "100%" }}
						labelText={componentStrings.neighborhood}
						placeholder={componentStrings.neighborhood}
						value={address.neighborhood}
						onChange={(e) => onHandleChange("neighborhood", e)}
						isDisabled={isLoading || disableForm}
					/>
					<TextInput
						boxProps={{ paddingY: 4, w: "100%"}}
						labelText={componentStrings.number}
						placeholder={componentStrings.number}
						value={address.streetNumber}
						onChange={(e) => onHandleChange("streetNumber", e)}
						isDisabled={isLoading || disableForm}
					/>
				</HStack>
				<TextInput
					labelText={componentStrings.complement}
					placeholder={componentStrings.complement}
					value={address.complementary || ""}
					onChange={(e) => onHandleChange("complementary", e)}
					isDisabled={isLoading || disableForm}
				/>
			</Box>
		</Center>
	);
});
