import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.ClinicPackage> {

	public clinicId = new AttributeShelf("");
	public loader = new LoaderShelf();
	public numberOfClinicPackages = new AttributeShelf(0);

	constructor() {
		super();
	}

	protected getDataItemsPerPage(page: number): Promise<api.ClinicPackage[]> {
		return api.getAllClinicPackageByClinicId(this.clinicId.value, page);
	}

	public getTotalClinicPackages = async() => {
		try {
			const clinicPackagesNumber = await api.getTotalAmountOfClinicPackagesByClinic(this.clinicId.value);
			this.numberOfClinicPackages.setValue(clinicPackagesNumber);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public deleteClinicPackage = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteClinicPackage(id);

			showSuccessToast(strings.clinicPackages.table.delete);
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}
