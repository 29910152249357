import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEdit";
import Details from "./Details";

const ClinicPackages: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/clinicPackages" component={Table} />
		<Route
			path="/dashboard/clinicPackages/create/"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/clinicPackages/edit/:id"
			component={CreateOrEdit}
		/>
		<Route
			path="/dashboard/clinicPackages/details/:id"
			component={Details}
		/>
	</Switch>
);

export default observer(ClinicPackages);
