import { LoaderShelf, ModelShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { makeAutoObservable } from "mobx";
import ClinicPackageShelf from "./Shelves/ClinicPackageShelf";
import AdminClinicUserShelf from "./Shelves/AdminClinicUserShelf";
import ClinicOrderShelf from "./Shelves/ClinicOrderShelf";
import ClinicProfessionalShelf from "./Shelves/ClinicProfessionalShelf";

export default class Store{

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.Clinic>;
	public clinicOrderShelf: ClinicOrderShelf;
	public clinicPackageShelf: ClinicPackageShelf;
	public clinicProfessionalShelf: ClinicProfessionalShelf;
	public adminClinicUserShelf: AdminClinicUserShelf;

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.clinicOrderShelf = new ClinicOrderShelf(id);
			this.adminClinicUserShelf = new AdminClinicUserShelf(id);
			this.clinicPackageShelf = new ClinicPackageShelf(id);
			this.modelShelf = new ModelShelf(id, api.getClinic);
			this.clinicProfessionalShelf = new ClinicProfessionalShelf(id);
		}
	}
}
