import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";

import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.MedicalProcedure> {

	public loader = new LoaderShelf();
	public numberOfProcedures = new AttributeShelf(0);

	constructor() {
		super();
		this.fetchPage(0);
		this.getTotalMedicalProcedures();
	}

	protected getDataItemsPerPage(page: number): Promise<api.MedicalProcedure[]> {
		return api.getAllMedicalProcedures(page);
	}

	public getTotalMedicalProcedures = async () => {
		try {
			const procedures = await api.getTotalNumberOfMedicalProcedures();
			this.numberOfProcedures.setValue(procedures);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public deleteMedicalProcedure = async (id: string) => {
		this.loader.tryStart();
		try {
			const deletedProcedure = await api.deleteMedicalProcedure(id);

			showSuccessToast(strings.medicalProcedure.table.delete(deletedProcedure.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}
