import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import { makeAutoObservable, runInAction } from "mobx";
import { craftFileBuffer, openFileInput } from "./FileUtils";
import API from "../../resources/api";

type UploadFile = (file: API.UncertainFile, fileFormat: API.FileFormat | null, progress?: (progress: number) => void) => Promise<string>;


export default class FilePickerShelf {
	public uploadFile: UploadFile;
	public localFile = new AttributeShelf<File | null>(null);
	public uploadedFile = new AttributeShelf<string | null>(null);
	public errorMsg = new AttributeShelf<string | null>(null);
	public loader = new LoaderShelf();

	constructor(uploadFile: UploadFile) {
		this.uploadFile = uploadFile;
		makeAutoObservable(this);
	}

	public get fileName(): string | null {
		return (this.localFile.value?.name || "");
	}

	public get uncertainfiedFile(){
		if (this.uploadedFile.value) {
			return {
				bytes: null,
				url: this.uploadedFile.value,
			};
		}
		return null;
	}

	private setLocalFileAndCraftBuffer = (file: File | null) => {
		this.localFile.setValue(file);
		if (file) {
			craftFileBuffer(file, this.upload);
		}
	};

	private onFileInputResolve = (file: File | null) => {
		this.setLocalFileAndCraftBuffer(file);
	};

	private pick = () => {
		openFileInput(this.onFileInputResolve, this.errorMsg.setValue, "*");
	};

	private clear = () => {
		this.localFile.setValue(null);
		this.uploadedFile.setValue(null);
	}

	private upload = async (buffer: Buffer) => {
		this.loader.start();
		this.errorMsg.setValue(null);
		try {
			const image = await this.uploadFile({bytes: buffer, url: null}, null);
			runInAction(() => {
				this.uploadedFile.setValue(image);
			});
		} catch (err) {
			// TODO - Show error message / treat error
			this.errorMsg.setValue(err?.message || err || "Error uploading");
		} finally {
			this.loader.end();
		}
	};

	public getPickerFields = () => ({
		fileName: this.localFile.value?.name || "",
		uncertainfiedFile: this.uncertainfiedFile,
		loading: this.loader.isLoading,
		pick: this.pick,
		setUploadedFile: this.uploadedFile.setValue,
		clear: this.clear,
	});
}
