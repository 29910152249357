import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import moment from "moment";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import Store from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.adminClinicUsers.table;
	const modal = strings.common.modal;
	const { dialog, authStore } = useGlobalStore();

	const clinicId = authStore.currentAdminUser?.clinic?.id;
	React.useEffect(() => {
		if (clinicId) {
			store.clinicId.setValue(clinicId);
		}
		store.fetchPage(0);
	}, [clinicId]);

	const store = useLocalObservable(() => new Store());

	const history = useHistory();

	const onDeleteUser = (id: string) => {
		store.deleteAdminClinicUser(id);
		dialog.closeDialog();
	};

	const onGoToEditAdminClinicUser = (id: string) => history.push(`adminClinicUsers/edit/${id}`);
	const onGoToDetailsAdminClinicUser = (id: string) => history.push(`adminClinicUsers/details/${id}`);
	const onGoToCreateAdminClinicUser = () => history.push("adminClinicUsers/create/");

	const openDialog = (user: API.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(user.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteUser(user.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			{
				!clinicId &&
					<TotalInfo
						width="100%"
						total={store.totalClinics.value}
						text={pageStrings.totalText}
					/>
			}
			<Table
				data={store._items}
				headers={pageStrings.header(!!clinicId)}
				onAdd={onGoToCreateAdminClinicUser}
				addButtonText={pageStrings.tableAddButton}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onView={clinicId ? () => onGoToDetailsAdminClinicUser(item.id) : undefined}
							onEdit={() => onGoToEditAdminClinicUser(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>
							<Text>{item.name}</Text>
						</Td>
						{
							!clinicId &&
								<Td>
									<Text>{item.clinic?.name}</Text>
								</Td>
						}
						<Td>
							<Text>{moment(item.createdAt).format(strings.moment.date)}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
