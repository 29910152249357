import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Center,
	Flex,
	VStack,
	Image,
	Td,
	Tr,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	SelectRadio,
	Table,
	TableCellWithActionButtons,
	InfoCard,
	LabelWithText,
	PeriodGroup,
	TableRanking,
	AutoCompleteModal,
	TextInput,
} from "~/components";
import { useDebounce } from "~/hooks/useDebounce";
import strings from "~/resources/strings";
import Store from "./store";
import API from "~/resources/api";

const ComponentsScreen: React.FC = () => {
	const items = [
		{ title: "Ontem", description: "06/12", onClick: () => console.log("filtro de: 06/12") },
		{ title: "Hoje", description: "07/12", onClick: () => console.log("filtro de: 07/12") },
		{ title: "7 dias atrás", description: "30/11 até 07/12", onClick: () => console.log("filtro de: 30/11 até 07/12") },
		{ title: "15 dias atrás", description: "22/11 até 07/12", onClick: () => console.log("filtro de: 22/11 até 07/12") },
		{ title: "30 dias atrás", description: "07/11 até 07/12", onClick: () => console.log("filtro de: 07/11 até 07/12") },
		{ title: "Customizado", description: "Escolher período", onClick: () => console.log("filtro de: Escolher período") },
	];
	const { isOpen, onClose, onOpen } = useDisclosure();
	const debounce = useDebounce();
	const store = useLocalObservable(() => new Store());
	const onSelectMedicalProcedure = (establishment: API.MedicalProcedure) => {
		store.medicalProcedure.setValue(establishment);
		onClose();
	};

	React.useEffect(() => {
		store.autoCompleteMedicalProcedure.fetchPage(0);
		return () => store.dispose();
	}, []);


	return (
		<Center>
			<VStack p={20}>
				<Image src="/logo.svg" />
				<TextInput
					labelText="Pesquisar procedimento médico"
					onClick={onOpen}
					value={store.medicalProcedure.value?.name}
					bgColor="white"
					_hover={{ bgColor: "white" }}
					isReadOnly
				/>
				<AutoCompleteModal
					isOpen={isOpen}
					onClose={onClose}
					header={
						<TextInput
							placeholder="Pesquisar procedimento médico"
							onKeyUp={(e) => {
								const input = e.target as HTMLInputElement;
								debounce.clearTimer();
								debounce.setTimer(setTimeout(() => store.searchMedicalProcedure.setValue(input.value), 500));
							}}
						/>
					}
					listProps={{
						data: store.autoCompleteMedicalProcedure.items,
						loading: store.autoCompleteMedicalProcedure.loader.isLoading,
						renderItem: (item) => (
							<Text
								key={item.id}
								onClick={() => onSelectMedicalProcedure(item)}
								cursor="pointer"
							>
								{item.name}
							</Text>
						),
						paginantionProps: {
							currentPage: store.autoCompleteMedicalProcedure.page,
							nextPage: store.autoCompleteMedicalProcedure.nextPage,
							prevPage: store.autoCompleteMedicalProcedure.previousPage,
							hasNextPage: store.autoCompleteMedicalProcedure.hasNextPage,
						},
					}}
				/>
				<Flex flexWrap="wrap">
					<InfoCard title="Faturamento Total" description="R$ 00 000,00" />
					<InfoCard title="Ticket Médio Total" description="R$ 000,00" />
					<InfoCard title="Novos Usuários" description="60" />
				</Flex>
				<SelectRadio onSelect={(value) => console.log(value)} />
				<Flex p={{ base: "2", lg: "16" }} w="100%">
					<Table
						data={[
							{ id: 1, date: "05/12/2021", name: "Clínica Fulana" },
							{ id: 2, date: "03/12/2021", name: "Clínica Fulana" },
							{ id: 3, date: "03/12/2021", name: "Clínica Fulana" },
							{ id: 4, date: "03/12/2021", name: "Clínica Fulana" },
							{ id: 5, date: "29/11/2021", name: "Clínica Fulana" },
							{ id: 6, date: "28/11/2021", name: "Clínica Fulana" },
							{ id: 7, date: "28/11/2021", name: "Clínica Fulana" },
							{ id: 8, date: "25/11/2021", name: "Clínica Fulana" },
						]}
						headers={["", "Data de Cadastro", "Clínicas"]}
						onAdd={() => { }}
						renderRow={(item) => (
							<Tr
								key={item.id}
								borderBottomColor="gray.300"
								borderBottomWidth={1}
							>
								<TableCellWithActionButtons
									onEdit={() => { }}
									onDelete={() => { }}
									onBlock={() => { }}
								/>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text>{item.date}</Text>
								</Td>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text>{item.name}</Text>
								</Td>
							</Tr>
						)}
						loading={false}
						emptyMessage={strings.common.noResutls}
						currentPage={0}
						prevPage={() => { }}
						nextPage={() => { }}
						hasNextPage={false}
					/>
				</Flex>
				<Flex width="80%" flexDirection="column">
					<LabelWithText label="Bairro:" text="Caminho das Arvores" />
					<LabelWithText label="Atendimento:" helper="(dias da semana de atendimento)" text="Segunda a Sexta, das 8h ás 19h. Sábado, das 8h ás 13h." />
					<LabelWithText label="Descrição:" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat" />
				</Flex>
				<Flex p={{ base: "2", lg: "16" }} w="100%">
					<TableRanking
						maxW={600}
						headers={["", "Serviços mais vendidos", "Quantidade"]}
						data={[
							{ id: 1, name: "Nome do serviço", quantity: "100" },
							{ id: 2, name: "Nome do serviço", quantity: "90" },
							{ id: 3, name: "Nome do serviço", quantity: "85" },
							{ id: 4, name: "Nome do serviço", quantity: "83" },
							{ id: 5, name: "Nome do serviço", quantity: "80" },
							{ id: 6, name: "Nome do serviço", quantity: "75" },
							{ id: 7, name: "Nome do serviço", quantity: "70" },
							{ id: 8, name: "Nome do serviço", quantity: "69" },
							{ id: 9, name: "Nome do serviço", quantity: "65" },
							{ id: 10, name: "Nome do serviço", quantity: "62" },
						]}
						emptyMessage="erro"
						loading={false}
						renderRow={(item, index) => (
							<Tr
								key={item.id}
								borderBottomColor="gray.300"
								borderBottomWidth={1}
							>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text
										color="primary.500"
										textAlign="center"
									>
										{index + 1}
									</Text>
								</Td>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
									borderLeftWidth={1}
									borderLeftColor="primary.500"
								>
									<Text>{item.name}</Text>
								</Td>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text>{item.quantity}</Text>
								</Td>
							</Tr>
						)}
					/>
					<TableRanking
						headers={["", "Serviços mais vendidos", "Valor"]}
						data={[
							{ id: 1, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 2, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 3, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 4, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 5, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 6, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 7, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 8, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 9, name: "Nome do serviço", price: "R$ 0.000,00" },
							{ id: 10, name: "Nome do serviço", price: "R$ 0.000,00" },
						]}
						emptyMessage="erro"
						loading={false}
						renderRow={(item, index) => (
							<Tr
								key={item.id}
								borderBottomColor="gray.300"
								borderBottomWidth={1}
							>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text
										color="primary.500"
										textAlign="center"
									>
										{index + 1}
									</Text>
								</Td>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
									borderLeftWidth={1}
									borderLeftColor="primary.500"
								>
									<Text>{item.name}</Text>
								</Td>
								<Td
									borderBottomColor="gray.300"
									borderBottomWidth={1}
								>
									<Text>{item.price}</Text>
								</Td>
							</Tr>
						)}
					/>
				</Flex>

				<PeriodGroup items={items} />
			</VStack>
		</Center>
	);

};


export default observer(ComponentsScreen);
