import React from "react";
import { observer } from "mobx-react-lite";
import {
	Td,
	Tr,
	Text,
} from "@chakra-ui/react";
import {
	TableRanking,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";

interface IProps {
	isLoading: boolean;
	topTenHighestBillingPackages: api.HighestBillingPackages[];
}

const MostBillingPackages: React.FC<IProps> = (props) => {
	const { isLoading, topTenHighestBillingPackages } = props;
	const pageStrings = strings.home;

	return (
		<TableRanking
			mt={{ base: 8, lg: 16 }}
			headers={pageStrings.mostBillingPackageHeaders}
			data={topTenHighestBillingPackages}
			emptyMessage={strings.common.noResutls}
			loading={isLoading}
			renderRow={(item, index) => (
				<Tr
					key={item.package.id}
					borderBottomColor="gray.300"
					borderBottomWidth={1}
				>
					<Td
						borderBottomColor="gray.300"
						borderBottomWidth={1}
					>
						<Text
							color="primary.500"
							textAlign="center"
						>
							{index + 1}
						</Text>
					</Td>
					<Td
						borderBottomColor="gray.300"
						borderBottomWidth={1}
						borderLeftWidth={1}
						borderLeftColor="primary.500"
					>
						<Text>{item.package.name}</Text>
					</Td>
					<Td
						borderBottomColor="gray.300"
						borderBottomWidth={1}
					>
						<Text>{format.currencyForBR(item.revenue)}</Text>
					</Td>
				</Tr>
			)}
		/>
	);
};


export default observer(MostBillingPackages);
