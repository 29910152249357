import {
	LoaderShelf,
	AbstractPaginatedListStore,
	AttributeShelf,
} from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Clinic> {

	public loader = new LoaderShelf();
	public totalClinics = new AttributeShelf(0);

	constructor() {
		super();
		this.fetchPage(0);
		this.getTotalAmountOfClinics();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Clinic[]> {
		return api.getAllClinics(page);
	}

	public deleteClinic = async (id: string) => {
		this.loader.tryStart();
		try {

			const deletedClinic = await api.deleteClinic(id);

			showSuccessToast(strings.users.table.delete(deletedClinic.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getTotalAmountOfClinics = async () => {
		try {
			const clinics = await api.getTotalAmountOfClinics();
			this.totalClinics.setValue(clinics);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}
	}

	public changeClinicBlockStatus = async (id: string, blockedAt: Date | null) => {
		this.loader.tryStart();
		try {
			if (blockedAt) {
				await api.changeClinicBlockStatus(id, false);
				showSuccessToast(strings.clinics.table.statusClinic(false));
			} else {
				await api.changeClinicBlockStatus(id, true);
				showSuccessToast(strings.clinics.table.statusClinic(true));
			}
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
