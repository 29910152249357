import { makeAutoObservable } from "mobx";
import { LoaderShelf, AttributeShelf } from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import * as mapsTypes from "~/declarations/maps";

const pageStrings = strings.clinics.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		phone: "",
		serviceDays: "",
		description: "",
		whatsapp: "",
		zipcode: "",
		street: "",
		streetNumber: "",
		complementary: "",
		neighborhood: "",
		city: "",
		technicalManager: "",
		crm: "",
	});

	public formShelfBankAccount = new FormShelf({
		bankCode: "",
		agency: "",
		agencyDv: "",
		account: "",
		accountDv: "",
	});

	public type = new AttributeShelf(api.BankAccountType.contaCorrente);
	public stateUF = new AttributeShelf(api.StateUF.AC);
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public specialities: api.Speciality[] = [];
	public loader = new LoaderShelf();
	public lat: AttributeShelf<number | null> = new AttributeShelf(null);
	public lng: AttributeShelf<number | null> = new AttributeShelf(null);

	public id = new AttributeShelf("");

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getClinic(id);
		}
	}

	private getField(
		address: mapsTypes.IPlaceDetailsResult,
		fieldName: mapsTypes.AddressType,
	): string | null {
		const foundValues = address.address_components
			.filter((component) => component.types.includes(fieldName))
			.map((component) => component.short_name);
		return foundValues.length > 0 ? foundValues[0] : null;
	}

	public addSpeciality = (speciality: api.Speciality) => {
		this.specialities.push(speciality);
	};

	public removeSpeciality = (speciality: api.Speciality) => {
		this.specialities.splice(this.specialities.indexOf(speciality), 1);
	};

	public setStateEnum = async (stateGooglePlace: string) => {
		const allStateEnum = api.allValuesStateUF();
		const stateFiltered = allStateEnum.find((state: api.StateUF) => state === stateGooglePlace);
		this.stateUF.setValue(stateFiltered || api.StateUF.AC);
	};

	public getClinic = async (id: string) => {
		this.loader.tryStart();
		try {
			const clinic = await api.getClinic(id);
			this.setInitValues(clinic);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public onSelectAddress = async (placeSelected: mapsTypes.IPlaceDetailsResult) => {
		const data = this.formShelf.getValues();
		data.city = this.getField(placeSelected, "administrative_area_level_2") || "";
		data.street = this.getField(placeSelected, "route") || "";
		data.neighborhood = this.getField(placeSelected, "sublocality_level_1") || "";
		data.zipcode = this.getField(placeSelected, "postal_code") || "";
		data.streetNumber = this.getField(placeSelected, "street_number") || "";
		this.setStateEnum(this.getField(placeSelected, "administrative_area_level_1") || "");
		this.lat.setValue(JSON.parse(JSON.stringify(placeSelected.geometry.location)).lat);
		this.lng.setValue(JSON.parse(JSON.stringify(placeSelected.geometry.location)).lng);
	};


	public setInitValues = (clinic: api.Clinic) => {
		this.formShelf = new FormShelf({
			name: clinic.name,
			phone: clinic.phone,
			serviceDays: clinic.serviceDays,
			description: clinic.description,
			whatsapp: clinic.whatsapp,
			complementary: clinic.address.complementary || "",
			neighborhood: clinic.address.neighborhood,
			city: clinic.address.city,
			street: clinic.address.street,
			streetNumber: clinic.address.streetNumber,
			zipcode: clinic.address.zipcode,
			crm: clinic.crm,
			technicalManager: clinic.technicalManager,
		});
		this.lat.setValue(clinic.address.latLng.lat);
		this.lng.setValue(clinic.address.latLng.lng);
		if (clinic.bankAccount) {
			this.formShelfBankAccount = new FormShelf({
				account: clinic.bankAccount?.account,
				accountDv: clinic.bankAccount?.accountDv || "",
				agency: clinic.bankAccount?.agency,
				agencyDv: clinic.bankAccount?.agencyDv || "",
				bankCode: clinic.bankAccount?.bankCode,
			});
		}
		clinic.specialities.map((specialty) => this.addSpeciality(specialty));
		this.stateUF.value = clinic.address.state;
		if (clinic.bankAccount) {
			this.type.setValue(clinic.bankAccount.type);
		}
		if (clinic.image) {
			this.imageShelf.getPickerFields().setUploadedImage(clinic.image);
		}
	};

	public createOrEditClinic = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {

			const data = this.formShelf.getValues();
			const {
				name,
				phone,
				serviceDays,
				description,
				whatsapp,
				technicalManager,
				crm,
			} = data;

			if (this.id.value) {

				await api.editClinic(this.id.value, {
					name,
					phone,
					serviceDays,
					description,
					whatsapp,
					technicalManager,
					crm,
					address: {
						neighborhood: data.neighborhood,
						city: data.city,
						state: this.stateUF.value,
						street: data.street,
						streetNumber: data.streetNumber,
						zipcode: data.zipcode,
						complementary: data.complementary,
						countryCode: "BR",
						latLng: {
							lat: this.lat.value || 0,
							lng: this.lng.value || 0,
						},
					},
					image: this.imageShelf.uncertainfiedImage,
					specialities: this.specialities,
				});
			} else {
				await api.createClinic({
					name,
					phone: strings.removeCharactersExceptLetterNumber(phone),
					serviceDays,
					description,
					whatsapp: strings.removeCharactersExceptLetterNumber(whatsapp),
					technicalManager,
					crm,
					address: {
						neighborhood: data.neighborhood,
						city: data.city,
						state: this.stateUF.value,
						street: data.street,
						streetNumber: data.streetNumber,
						zipcode: data.zipcode,
						complementary: data.complementary,
						countryCode: "BR",
						latLng: {
							lat: this.lat.value || 0,
							lng: this.lng.value || 0,
						},
					},
					image: this.imageShelf.uncertainfiedImage,
					specialities: this.specialities,
				});
			}

			showSuccessToast(pageStrings.success(!!this.id));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
