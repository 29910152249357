import React from "react";
import {
	Flex,
	useRadioGroup,
	Text,
} from "@chakra-ui/react";
import { RadioCard } from "~/components";

interface IProps {
	items: Array<{
		title: string;
		description: string;
		onClick: () => void;
	}>;
}

export const PeriodGroup: React.FC<IProps> = (props) => {

	const { items } = props;

	const { getRootProps, getRadioProps } = useRadioGroup({
		defaultValue: items[0].title,
	});

	const group = getRootProps();

	return (
		<Flex
			overflowX={{ base: "auto", xl: "unset" }}
			{...group}
		>
			{items.map((item, index) => {
				const radioProps = getRadioProps({ value: item.title });
				return (
					<RadioCard
						key={`${index}${item.title}`}
						radioProps={radioProps}
						onClick={item.onClick}
						boxProps={{
							maxW: 180,
							mr: 2,
							mt: 2,
							borderRadius: 6,
						}}
					>
						<Text
							fontSize="md"
							color="gray.600"
							width="100%"
							textAlign="initial"
						>
							{item.title}
						</Text>
						<Text
							fontSize="md"
							fontWeight="bold"
							textAlign="center"
							mt={4}
							color="gray.600"
						>
							{item.description}
						</Text>
					</RadioCard>
				);
			})}
		</Flex>
	);
};
