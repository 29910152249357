import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class PendingOrderShelf extends AbstractPaginatedListStore<api.Order> {

	public loader = new LoaderShelf();

	public clinicId = new AttributeShelf("");

	constructor() {
		super();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Order[]> {
		return api.getAllPendingOrdersByClinic(this.clinicId.value, page);
	}
}
