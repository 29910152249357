import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class OrderShelf extends AbstractPaginatedListStore<api.Order> {

	public loader = new LoaderShelf();

	public clinicId = new AttributeShelf("");

	constructor() {
		super();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Order[]> {
		if (this.clinicId.value) {
			return api.getAllOrdersByClinicWithoutPending(this.clinicId.value, page);
		}
		return api.getAllOrders(page);
	}
}
