import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Button,
	Box,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	ImagePicker,
	Label,
	Loading,
	DetailsRow,
} from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import Store from "./store";
import { EditIcon } from "@chakra-ui/icons";
import api from "~/resources/api";

interface IParams {
	id?: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.packages.details;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();
	const { authStore } = useGlobalStore();

	const store = useLocalObservable(() => new Store(id));

	const onGoToEditPackage = (packageId: string) => history.push(`/dashboard/packages/edit/${packageId}`);
	const goBack = () => history.goBack();

	const isAdminClinicUser = authStore.currentAdminUser && authStore.currentAdminUser.clinic;

	return (
		<CentralizedCard
			title={{
				text: !isAdminClinicUser ? commonStrings.editTitle : commonStrings.detailsTitle,
				helper: !isAdminClinicUser ? (
					<Tooltip label={strings.common.edit}>
						<IconButton
							variant="icon"
							aria-label="Edit"
							size="lg"
							icon={<EditIcon w="24px" h="24px" />}
							onClick={() => onGoToEditPackage(id || "")}
						/>
					</Tooltip>
				): undefined,
			}}
			button={(
				<Button
					variant="outline"
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={goBack}
				>
					{strings.actions.back}
				</Button>
			)}
		>
			{store.modelShelf.initialLoader.isLoading ?
				(
					<Loading />
				) : (
					<>
						<Box>
							<Label fontWeight="bold" marginBottom={1}>
								{commonStrings.fields.photo}
							</Label>
							<ImagePicker
								src={store.modelShelf._model.image?.url ? store.modelShelf._model.image.url : null}
							/>
						</Box>
						<DetailsRow
							value={store.modelShelf._model.medicalProcedures.flatMap((x) => [x.name])}
							label={pageStrings.packages}
						/>
						<DetailsRow
							value={store.modelShelf._model.name}
							label={commonStrings.fields.name}
						/>
						<DetailsRow
							label={strings.common.fields.speciality}
							value={api.translateSpeciality(store.modelShelf.model.speciality)}
						/>
					</>
				)}
		</CentralizedCard>
	);
};

export default observer(Details);
