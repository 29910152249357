import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Loading,
} from "~/components";
import Store from "./store";
import { ClinicDetails } from "./components/ClinicDetails";
import { BankAccountDetails } from "./components/BankAccountDetails";
import AdminClinicUsers from "./Tables/AdminClinicUsers";
import ClinicPackages from "./Tables/ClinicPackages";
import ClinicOrders from "./Tables/ClinicOrders";
import ClinicProfessionals from "./Tables/ClinicProfessionals";

interface IParams {
	id?: string;
}

const Details: React.FC = () => {
	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id));

	const onGoToEditClinic = (clinicId: string) => history.push(`/dashboard/clinics/edit/${clinicId}`);

	return (
		<>
			{store.modelShelf.initialLoader.isLoading ?
				(
					<Loading />
				) : (
					<>
						<AdminClinicUsers id={id} adminClinicUserShelf={store.adminClinicUserShelf} />
						<ClinicDetails
							clinic={store.modelShelf._model}
							onGoToEditClinic={() => onGoToEditClinic(id || "")}
						/>
						{store.modelShelf.model.bankAccount && (
							<BankAccountDetails
								bankAccount={store.modelShelf.model.bankAccount}
								onGoToEditClinic={() => onGoToEditClinic(id || "")}
							/>
						)}
						<ClinicPackages id={id} clinicPackageShelf={store.clinicPackageShelf} />
						<ClinicOrders id={id} clinicOrderShelf={store.clinicOrderShelf} />
						<ClinicProfessionals id={id} clinicProfessionalShelf={store.clinicProfessionalShelf} />
					</>
				)}
		</>
	);
};

export default observer(Details);
