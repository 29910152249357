import { makeAutoObservable, reaction } from "mobx";
import { LoaderShelf, AttributeShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.adminUsers.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		email: "",
		name: "",
		password: "",
	});

	public blocked = new AttributeShelf(false)
	public loader = new LoaderShelf();
	public searchClinic = new AttributeShelf("");
	public clinic: AttributeShelf<api.Clinic | null> = new AttributeShelf(null);
	public clinicId = new AttributeShelf("");
	public userId = new AttributeShelf("");

	private autoCompleteReaction = reaction(() => this.searchClinic.value,
		() => this.autoCompleteClinic.refresh(),
	);

	public autoCompleteClinic = new PaginatedListShelf(
		async (page: number) => await api.autocompleteClinic(page, this.searchClinic.value),
	);

	public dispose = () => {
		this.autoCompleteReaction();
	};

	constructor(clinicId?: string, id?: string) {
		makeAutoObservable(this);

		if (clinicId) {
			this.clinicId.setValue(clinicId);
		}
		if (id) {
			this.getAdminClinicUser(id);
		}
	}

	public getAdminClinicUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const adminClinicUser = await api.getAdminClinicUser(id);
			this.userId.setValue(adminClinicUser.id);
			this.setInitValues(adminClinicUser);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (adminClinicUser: api.AdminUser) => {
		this.formShelf = new FormShelf({
			email: adminClinicUser.email,
			name: adminClinicUser.name,
			password: "",
		});
		this.clinic.setValue(adminClinicUser.clinic);
	};

	public createOrEditAdminClinicUser = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const {
				email,
				name,
				password,
			} = data;

			if (this.userId.value !== ""){
				await api.editAdminUser(this.userId.value,{
					email,
					name,
				});
			} else {
				await api.createAdminUser({
					email,
					name,
					password,
					clinicId: this.clinicId.value !== "" ? this.clinicId.value : this.clinic.value ? this.clinic.value.id : null,
				});
			}

			showSuccessToast(pageStrings.success(!!this.userId));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
