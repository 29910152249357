import React from "react";
import {
	IconButton,
	Tooltip,
	Box,
	Image,
	Flex,
	Switch,
	HStack,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Label,
	ExpertiseSelect,
	DetailsRow,
	DetailsRowMultiple,
} from "~/components";
import strings from "~/resources/strings";
import { EditIcon } from "@chakra-ui/icons";
import imagePlaceholder from "../../../../../../../static/pick_image.svg";
import api from "~/resources/api";

interface IProps {
	clinic: api.Clinic;
	onGoToEditClinic: () => void;
}

export const ClinicDetails: React.FC<IProps> = (props) => {
	const { clinic, onGoToEditClinic } = props;
	const commonStrings = strings.common;

	return (
		<CentralizedCard
			title={{
				text: strings.clinics.details.title,
				helper: (
					<Tooltip label={strings.common.edit}>
						<IconButton
							variant="icon"
							aria-label="Edit"
							size="lg"
							icon={<EditIcon w="24px" h="24px" />}
							onClick={onGoToEditClinic}
						/>
					</Tooltip>
				),
			}}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{commonStrings.fields.photo}
				</Label>
				<Image
					width={120}
					height={120}
					p={0}
					m={0}
					src={clinic.image ? clinic.image.url : imagePlaceholder}
					fallbackSrc={imagePlaceholder}
					rounded="lg"
				/>
			</Box>
			<DetailsRow
				label={commonStrings.fields.name}
				value={clinic.name}
			/>
			<DetailsRow
				label={commonStrings.fields.description}
				value={clinic.description}
			/>
			{clinic.specialities.length > 0 && (
				<ExpertiseSelect
					isDisabled
					expertises={clinic.specialities}
				/>
			)}
			<DetailsRowMultiple
				items={[
					{
						label: commonStrings.fields.serviceDays,
						value: clinic.serviceDays,
					},
					{
						label: commonStrings.fields.technicalManager,
						value: clinic.technicalManager,
					},
					{
						label: commonStrings.fields.crm,
						value: clinic.crm,
					},
					{
						label: commonStrings.fields.telephone,
						value: clinic.phone,
					},
					{
						label: commonStrings.fields.whatsapp,
						value: clinic.whatsapp,
					},
				]}
			/>
			{clinic.address.neighborhood && (
				<>
					<DetailsRowMultiple
						items={[
							{
								label: commonStrings.fields.state,
								value: api.translateStateUF(clinic.address.state),
							},
							{
								label: commonStrings.fields.city,
								value: clinic.address.city,
							},
							{
								label: commonStrings.fields.neighborhood,
								value: clinic.address.neighborhood,
							},
						]}
					/>
					<HStack>
						<DetailsRow
							label={commonStrings.fields.street}
							value={clinic.address.street}
						/>
						<DetailsRow
							label={commonStrings.fields.streetNumber}
							value={clinic.address.streetNumber}
						/>
					</HStack>
				</>
			)}
			{clinic.address.complementary && (
				<DetailsRow
					label={commonStrings.fields.complementary}
					value={clinic.address.complementary}
				/>
			)}
			<Flex alignItems="center">
				<Label fontWeight="bold" marginRight={2}>
					{commonStrings.fields.blocked}
				</Label>
				<Switch
					disabled
					isChecked={!!clinic.blockedAt}
					size="lg"
				/>
			</Flex>
		</CentralizedCard>
	);
};
