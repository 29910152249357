import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";

import api from "~/resources/api";
import moment from "moment";

const TableView: React.FC = () => {
	const pageStrings = strings.packages.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const { dialog , authStore} = useGlobalStore();

	const onGoToEditPackage = (id: string) => history.push(`packages/edit/${id}`);
	const onGoToDetails = (id: string) => history.push(`packages/details/${id}`);
	const onGoToCreatePackage = () => history.push("packages/create");

	const isAdminClinicUser = authStore.currentAdminUser && authStore.currentAdminUser.clinic;

	const onDeletePackage = (id: string) => {
		store.deletePackage(id);
		dialog.closeDialog();
	};

	const openDialog = (packages: api.Package) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(packages.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeletePackage(packages.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<TotalInfo
				total={store.numberOfPackages.value}
				text={pageStrings.totalText}
				w="100%"
			/>
			<Table
				data={store._items}
				headers={pageStrings.header}
				onAdd={!isAdminClinicUser ? onGoToCreatePackage : undefined}
				addButtonText={pageStrings.create}
				addButtonLoading={store._loading}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onEdit={!isAdminClinicUser ? () => onGoToEditPackage(item.id) : undefined}
							onView={()=>onGoToDetails(item.id)}
							onDelete={!isAdminClinicUser ? () => openDialog(item) : undefined}
						/>
						<Td>
							<Text>{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
						</Td>
						<Td>
							<Text isTruncated>{item.name}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
