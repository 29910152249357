import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";
import Store from "./store";
import moment from "moment";

const TableView: React.FC = () => {
	const pageStrings = strings.clinics.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteClinic = (id: string) => {
		store.deleteClinic(id);
		dialog.closeDialog();
	};

	const onGoToClinicDetails = (id: string) => history.push(`clinics/details/${id}`);
	const onGoToEditClinic = (id: string) => history.push(`clinics/edit/${id}`);
	const onGoToCreateClinic = () => history.push("clinics/create/");

	const openDialog = (clinic: API.Clinic) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(clinic.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteClinic(clinic.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const openDialogBlockedClinic = (clinic: API.Clinic) => {
		const modalBlockedClinic = strings.clinics.modal;
		dialog.showDialog({
			title: modalBlockedClinic.title,
			closeOnOverlayClick: true,
			description: modalBlockedClinic.description(clinic.name, !!clinic.blockedAt),
			buttons: [
				{
					title: clinic.blockedAt ?  modalBlockedClinic.button.enable :  modalBlockedClinic.button.disable,
					onPress: () => {
						store.changeClinicBlockStatus(clinic.id, clinic.blockedAt);
						dialog.closeDialog();
					},
					buttonProps: { bg: clinic.blockedAt ? "green.500" : "red.500" },
				},
				{
					title: modalBlockedClinic.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16"}}>
			<TotalInfo
				width="100%"
				total={store.totalClinics.value}
				text={pageStrings.totalText}
			/>
			<Table
				data={store._items}
				headers={pageStrings.header}
				onAdd={onGoToCreateClinic}
				addButtonText={pageStrings.addButtonText}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onView={() => onGoToClinicDetails(item.id)}
							onEdit={() => onGoToEditClinic(item.id)}
							onDelete={() => openDialog(item)}
							onBlock={() => openDialogBlockedClinic(item)}
							isBlocked={!!item.blockedAt}
						/>
						<Td>
							<Text>{moment(item.createdAt).format(strings.moment.date)}</Text>
						</Td>
						<Td>
							<Text>{item.name}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
