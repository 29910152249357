import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		enum: {
			StateUF: {
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},
			Speciality: {
				cardiology: "Cardiologia",
				generalSurgery: "Cirurgia Geral",
				vascularSurgery: "Cirurgia Vascular",
				coloproctologist: "Coloproctologista",
				dermatology: "Dermatologia",
				endocrinology: "Endocrinologia",
				gastroenterology: "Gastroenterologia",
				gynecology: "Ginecologia",
				mastology: "Mastologia",
				obstetrics: "Obstetrícia",
				orthopedics: "Ortopedia",
				pneumology: "Pneumologia",
				urology: "Urologia",
			},
			BankAccountType: {
				contaCorrente: "Conta Corrente",
				contaCorrenteConjunta: "Conta Corrente Conjunta",
				contaPoupanca: "Conta Poupança",
				contaPoupancaConjunta: "Conta Poupança Conjunta",
			},
			OrderPaymentStatus: {
				waitingPayment: "Aguardando pagamento",
				pending: "Pendente",
				scheduled: "Agendado",
				finished: "Finalizado",
				canceled: "Cancelado",
			},
			GraphicType: {
				price: "Valor",
				amount: "Quantidade",
			},
			HomeGraphicType: {
				date: "Data",
				hour: "Hora",
				dateHour: "Data e Hora",
			},
		},
		components: {
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			autoCompleteModal: {
				back: "Voltar",
			},
			expertiseSelect: {
				title: "Especialidade:",
			},
			periodCard: {
				until: " até ",
				customized: "Customizado",
				choosePeriod: "Escolher período",
				chooseButton: "Escolher",
			},
			graphic: {
				error: "Houve um erro, e não foi possível gerar o gráfico",
				value: "Valor",
				hour: "Hora",
			},
			editProfile: {
				success: "Editado com sucesso",
			},
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			documentAttach: {
				attachDocument: "Anexar Documento",
			},
			addressForm: {
				title: "Endereço:",
				street: "Rua:",
				neighborhood: "Bairro:",
				number: "Número:",
				complement: "Complemento:",
				addressPlaceholder: "Digite um endereço...",
			},
		},
		nav: {
			home: "Home",
			adminUsers: "Usuários Adminstrativos",
			adminClinicUsers: "Usuários da Clínica",
			users: "Usuários",
			clinics: "Clínicas",
			professionals: "Profissionais",
			clinicProfessionals: "Profissionais da Clínica",
			medicalProcedures: "Procedimentos Médicos",
			packages: "Pacotes",
			orders: "Solicitações",
			clinicPackages: "Pacotes da Clínica",
			clinicUsers: "Usuários Clínica",
			clinicProfile: "Perfil da clínica",
			clinicWallet: "Meus Ganhos",
			logout: "Sair",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			send: "Enviar",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
		},
		common: {
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			edit: "Clique para editar",
			cancel: "Cancelar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				backButton: "Voltar",
			},
			empty: "Vazio",
			fields: {
				name: "Nome:",
				email: "E-mail:",
				password: "Senha:",
				cpf: "CPF:",
				phone: "Celular:",
				address: "Endereço:",
				disable: "Desabilitar:",
				photo: "Foto:",
				street: "Rua:",
				neighborhood: "Bairro:",
				streetNumber: "Número:",
				complementary: "Complemento:",
				city: "Cidade:",
				countryCode: "Código do País:",
				state: "Estado:",
				zipcode: "CEP:",
				description: "Descrição:",
				type: "Tipo:",
				speciality: "Especialidade:",
				serviceDays: "Atendimento",
				crm: "CRM:",
				technicalManager: "Responsável Técnico:",
				whatsapp: "WhatsApp:",
				telephone: "Telefone:",
				bankAccount: "Conta:",
				typeAccount: "Tipo da Conta:",
				bankCode: "Código do Banco",
				accountDv: "Dígito da conta:",
				bank: "Banco:",
				agency: "Agência:",
				agencyDigit: "Dígito da Agência:",
				accountNumber: "Número da Conta:",
				accountDigit: "Dígito da Conta:",
				cnpj: "CNPJ:",
				IdentificationNumber: "Número de identificação:",
				blocked: "Bloqueado:",
				resume: "Currículo:",
				cnes: "CNES:",
				ccmOrIss: "CCM ou ISS junto à prefeitura:",
				value: "Valor:",
				package: "Pacote:",
				date: "Data:",
				hour: "Hora:",
				suggestedDate: "Datas sugeridas:",
				suggestedHour: "Horas sugeridas:",
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionRemove: (itemName: string) => `Tem certeza que deseja remover ${itemName}?`,
				button: {
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
				},
			},
		},
		clinicWallet: {
			withdrawButton: "Sacar",
			title: "Saldo",
			bankAccountTitle: "Conta Bancária",
			transferred: "Transferido",
			pending: "Pendente",
			available: "Disponível",
			success: "Saldo retirado",
			withdrawError: "Erro ao tentar retirar saldo",
			modalHeader: "Informe o valor que deseja retirar. A transação está sujeita a uma taxa de R$ 3,67.",
		},
		home: {
			totalClinics: "Clínicas Cadastradas",
			totalPackages: "Pacotes Cadastrados",
			totalProfessionals: "Profissionais Cadastrados",
			mostSoldPackageHeaders: ["", "Serviços mais vendidos", "Quantidade"],
			mostBillingPackageHeaders: ["", "Serviços com maior faturamento", "Valor"],
			mostSalesClinicHeaders: ["", "Clínicas com mais vendas", "Quantidade"],
			mostBillingClinicHeaders: ["", "Clínicas com maior faturamento", "Valor"],
			totalBilling: "Faturamento Total",
			totalTicketAvg: "Ticket Médio Total",
			newUsers: "Novos Usuários",
			filters: {
				title: "Filtrar",
				yesterday: "Ontem",
				today: "Hoje",
				sevenDaysBefore: "7 dias atrás",
				fifteenDaysBefore: "15 dias atrás",
				thirtyDaysBefore: "30 dias atrás",
				custom: "Custom",
				selectPeriod: "Escolher período",
				betweenDates: (startDate: string, hourDate: string) => `${startDate} até ${hourDate}`,
				startedDate: "Data de início",
				endDate: "Data final",
			},
		},
		users: {
			table: {
				title: "Lista de Usuários",
				header: ["", "Usuários", "Email"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				statusUser: (isUserBlocked: boolean) => `O Usuário ${isUserBlocked ? "foi bloqueado" : "foi desbloaqueado"}`,
				totalText: "Usuários cadastrados",
			},
			modal: {
				title: "Desabilitar Usuário",
				description: (itemName: string, isUserBlocked: boolean) => `Tem certeza que deseja ${isUserBlocked ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
			edit: {
				title: "Edição de Usuário",
				success: "Usuário editado com sucesso",
			},

		},
		professionals: {
			table: {
				title: "Lista de Profissionais",
				header: ["", "Profissionais", "CPF/CNPJ"],
				delete: (userName: string) => `Profissional ${userName} foi deletado!`,
				addButtonText: "Adicionar novo profissional",
				totalText: "Profissionais cadastrados",
				statusProfessional: (isProfessionalBlocked: boolean) => `O Profissional ${isProfessionalBlocked ? "foi bloqueado" : "foi desbloaqueado"}`,
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Profissional" : "Cadastro de Profissional",
				success: (isEdit?: boolean) => isEdit ? "Profissional editado com sucesso" : "Profissional criado com sucesso",
			},
			common: {
				cnesFileName: "Documento CNES",
				resumeFileName: "Currículo",
				ccmOrIssFileName: "Documento CCM/ISS",
			},
			modal: {
				title: "Desabilitar Profissional",
				description: (itemName: string, isProfissionalBlocked: boolean) => `Tem certeza que deseja ${isProfissionalBlocked ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
		},
		clinicProfessionals: {
			table: {
				title: "Lista de Profissionais da Clínica",
				header: ["", "Profissionais", "CPF/CNPJ"],
				removed: (userName: string) => `Profissional ${userName} foi removido!`,
				added: (userName: string) => `Profissional ${userName} foi adicionado!`,
				addButtonText: "Adicionar novo profissional",
				totalText: "Profissionais cadastrados",
				professionalPlaceholder: "Digite o nome de um profissional...",
				modal: {
					title: "Remover",
					description: (itemName: string) => `Tem certeza que deseja remover ${itemName}?`,
					button: {
						remove: "Remover",
					},
				},
			},
		},
		clinics: {
			autocompleteLabel: "Clínica:",
			autocompletePlaceholder: "Pesquisar clínicas",
			totalText: "Clínicas cadastradas",
			table: {
				title: "Lista de Clínicas",
				header: ["", "Data de Cadastro", "Clínicas"],
				delete: (userName: string) => `Clínica ${userName} foi deletada!`,
				statusClinic: (isUserBlocked: boolean) => `A Clínica ${isUserBlocked ? "foi bloqueada" : "foi desbloaqueada"}`,
				addButtonText: "Adicionar nova clínica",
				totalText: "Clínicas cadastradas",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Clínica" : "Cadastro de Clínica",
				success: (isEdit?: boolean) => isEdit ? "Clínica editada com sucesso" : "Clínica criada com sucesso",
				address: "Endereço",
				helperServiceDays: "(dias da semana e horário):",
				errorAddress: "Erro no preenchimento do endereço.",
				documents: [
					"Contrato Social",
					"CNPJ Atualizado",
					"CCM ou ISS junto à prefeitura",
					"CNES",
					"Comprovante do pagamento do ISS",
					"Comprovante do pagamento da taxa de fiscalização do estabelecimento",
					"Alvará de funcionamento e vigilância sanitária atualizados",
					"Currículo completo de todos os profissionais que trabalham na clínica (com o título de especialista)",
					"Comprovante de conta bancária",
				],
				documentsTitle: "Documentos",
				bankAccountTitle: (hasBankAccount: boolean) => hasBankAccount ? "Editar Conta Bancária" : "Cadastro de Conta",
				banckAccount: "Conta Bancária:",
				successBankAccount: (isEdit?: boolean) => isEdit ? "Conta editada com sucesso" : "Conta Bancária criada com sucesso",
			},
			modal: {
				title: "Desabilitar Clínica",
				description: (itemName: string, isClinicBlocked: boolean) => `Tem certeza que deseja ${isClinicBlocked ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
			details: {
				users: {
					tableHeaders: ["", "Usuários da Clínica"],
					tableTitle: "Usuários da clínica",
					tableAddButton: "Adicionar novo usuário",
				},
				clinicPackages: {
					tableHeaders: ["", "Pacotes", "Valor R$"],
					tableTitle: "Pacotes da clínica",
					tableAddButton: "Adicionar novo Pacote",
					delete: (clinicName: string, clinicPackageName: string) => `Pacote ${clinicPackageName} da clínica ${clinicName} foi deletado!`,
					createOrEdit: {
						title: (id?: string) => id ? "Edição de Pacote" : "Cadastro de Pacote",
						autoCompleteLabel: "Pesquisar pacote:",
						success: (id?: string) => id ? "Pacote da clínica editado com sucesso" : "Pacote da clínica criado com sucesso",
						titlePackages: "Pacotes:",
					},
				},
				clinicOrders: {
					tableHeaders: ["", "Solicitações"],
					tableTitle: "Solicitações da clínica",
					cancel: (clinicName: string, clinicOrderName: string) => `Solicitação ${clinicOrderName} da clínica ${clinicName} foi cancelada!`,
					details: {
						user: "Usuário:",
						userPhone: "Contato do usuário:",
						service: "Serviço:",
						clinic: "Clínica:",
						professional: "Profissional:",
						date: "Data confirmada:",
						hour: "Hora confirmada:",
						payment: "Método de pagamento:",
						noConfirmedDate: "__/__/____",
					},
					modalDescription: (itemName: string) => `Tem certeza que deseja cancelar ${itemName}?`,
					alreadyCanceled: "Solicitação já cancelada",
				},
				clinicProfessionals: {
					tableHeaders: ["", "Profissionais"],
					tableTitle: "Profissionais da clínica",
					tableAddButton: "Adicionar novo Profissional",
					delete: (clinicProfessionalName: string) => `Profissional ${clinicProfessionalName} foi desvinculado!`,
					createOrEdit: {
						title: "Vincular Profissional",
						autoCompleteLabel: "Pesquisar profissional:",
						success: "Profissional vinculado com sucesso",
						titleProfessionals: "Profissionais:",
					},
				},
				title: "Detalhes da clínica",
			},
		},
		adminClinicUsers: {
			autocompleteLabel: "Clínica:",
			autocompletePlaceholder: "Pesquisar clínicas",
			totalText: "Clínicas cadastradas",
			table: {
				title: "Lista Usuários da Clínica",
				header: (isAdminClinicUser: boolean) => isAdminClinicUser ? ["", "Usuários Admin da Clínica", "Data de Cadastro"] : ["", "Usuários Admin da Clínica", "Clínica", "Data de Cadastro"],
				totalText: "Usuários da Clínica",
				tableHeaders: ["", "Usuários da Clínica"],
				tableTitle: "Usuários da clínica",
				tableAddButton: "Adicionar novo usuário",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Clínica" : "Cadastro de Clínica",
				success: (isEdit?: boolean) => isEdit ? "Clínica editada com sucesso" : "Clínica criada com sucesso",
			},
			modal: {
				title: "Desabilitar Clínica",
				description: (itemName: string, isClinicBlocked: boolean) => `Tem certeza que deseja ${isClinicBlocked ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
		},
		adminUsers: {
			table: {
				title: "Lista de Usuários Admin",
				header: ["", "Usuários Admin", "Email"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				addButtonText: "Adicionar novo usuário admin",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Usuário Admin" : "Cadastro de Usuário Admin",
				titleClinicUser: (isEdit?: boolean) => isEdit ? "Edição de Usuário Clínica" : "Cadastro de Usuário Clínica",
				success: (isEdit?: boolean) => isEdit ? "Usuário editado com sucesso" : "Usuário criado com sucesso",
			},
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			success: (userName: string) => `Bem vindo ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		orders: {
			table: {
				ordersTitle: "Solicitações",
				pendingOrdersTitle: "Solicitações Pendentes",
				cancelMessage: "A solicitação foi cancelada com sucesso!",
				header: ["", "Data de Cadastro", "Usuário"],
				finished: "Esta solicitação já foi finalizada",
				canceled: "Esta solicitação já foi cancelada",
				totalText: "Solicitações Cadastradas",
			},
			edit: {
				title: "Solicitação",
				success: "Enviado com sucesso!",
				successUpdateStatus: "Status atualizado com sucesso!",
				successCancel: "Cancelado com sucesso!",
				fields: {
					status: "Status:",
					user: "Usuário:",
					service: "Serviço:",
					clinic: "Clínica:",
					confirmedDate: "Data confirmada:",
					confirmedHour: "Hora confirmada:",
					professional: "Profissional:",
					professionalPlaceholder: "Digite o nome de um profissional...",
				},
			},
			modal: {
				title: "Cancelar Solicitação",
				description: "Tem certeza que deseja cancelar a solicitação?",
				button: {
					yes: "Sim",
					no: "Não",
				},
			},
		},
		medicalProcedure: {
			table: {
				title: "Lista de Procedimentos Médicos",
				header: ["", "Procedimentos Médicos", "Descrição"],
				totalText: "Procedimentos cadastrados",
				delete: (medicalProcedure: string) => `O procedimento ${medicalProcedure} foi deletado!`,
				create: "Adicionar novo procedimento",
			},
			edit: {
				title: (id?: string) => id ? "Edição de Procedimento Médico" : "Cadastro de Procedimento Médico",
				editSuccess: "Procedimento editado com sucesso",
				createSuccess: "Procedimento criado com sucesso",
			},
		},
		packages: {
			table: {
				title: "Lista de Pacotes",
				header: ["", "Data de Cadastro", "Pacotes"],
				totalText: "Pacotes Cadastrados",
				delete: (packages: string) => `O pacote ${packages} foi deletado!`,
				create: "Adicionar novo pacote",
			},
			edit: {
				title: (id?: string) => id ? "Edição de Pacote" : "Cadastro de Pacote",
				autoCompleteLabel: (id?: string) => id ? "Editar procedimentos médicos: " : "Pesquisar procedimentos médicos:",
				success: (id?: string) => id ? "Procedimento editado com sucesso" : "Procedimento criado com sucesso",
				medicalProcedures: "Procedimentos Médicos:",
				specialities: "Especialidades:",
				packages: "Pacotes",
				details: "Detalhes",
			},
			details: {
				title: "Detalhes",
				packages: "Pacotes:",
			},
		},
		clinicPackages: {
			table: {
				title: "Lista de Pacotes da Clínica",
				header: ["", "Pacotes"],
				totalText: "Pacotes Cadastrados da Clínica",
				delete: "O pacote foi deletado!",
				create: "Adicionar novo pacote",
			},
			createOrEdit: {
				title: (id?: string) => id ? "Edição de Pacote" : "Cadastro de Pacote",
				autoCompleteLabel: (id?: string) => id ? "Trocar Pacote: " : "Pesquisar por Pacotes:",
				success: (id?: string) => id ? "Pacote editado com sucesso" : "Pacote criado com sucesso",
				bankAccount: "É necessário uma conta bancária para registrar um pacote da clínica.",
				redirectToClinicProfile: "Clique aqui para criar uma conta bancária.",
				packages: "Pacotes",
				error: {
					package: "É necessário selecionar um pacote",
					clinic: "Clinica não foi encontrada",
				},
			},
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			email: "Email",
			token: "Token",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
	},

});

export default strings;
