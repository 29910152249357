import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export default class ClinicPackageShelf extends AbstractPaginatedListStore<api.Order>{

	public loader = new LoaderShelf();
	public clinicId = new AttributeShelf("");

	constructor(clinicId: string) {
		super();
		this.clinicId.setValue(clinicId);
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.Order[]> {
		return api.getAllOrdersByClinic(this.clinicId.value, page);
	}

	public cancelClinicOrder = async (id: string, clinicName: string) => {
		this.loader.tryStart();
		try {

			const canceledClinicOrder = await api.cancelOrder(id);

			showSuccessToast(strings.clinics.details.clinicOrders.cancel(clinicName, canceledClinicOrder.user.name));
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
