import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Table, TableCellWithActionButtons, TotalInfo } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";

import api from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.clinicPackages.table;
	const modal = strings.common.modal;

	const { dialog , authStore } = useGlobalStore();

	const store = useLocalObservable(() => new Store());
	const history = useHistory();

	const onGoToEdit = (id: string) => history.push(`clinicPackages/edit/${id}`);
	const onGoToDetails = (id: string) => history.push(`clinicPackages/details/${id}`);
	const onGoToCreate = () => history.push("clinicPackages/create");

	const onDeleteClinicPackage = (id: string) => {
		store.deleteClinicPackage(id);
		dialog.closeDialog();
	};

	const openDialog = (clinicPackage: api.ClinicPackage) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(""),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteClinicPackage(clinicPackage.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	React.useEffect(() => {
		if (authStore.currentAdminUser && authStore.currentAdminUser.clinic){
			store.clinicId.setValue(authStore.currentAdminUser.clinic.id);
			store.fetchPage(0);
			store.getTotalClinicPackages();
		}
	}, [authStore.currentAdminUser]);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<TotalInfo
				width="100%"
				total={store.numberOfClinicPackages.value}
				text={pageStrings.totalText}
			/>
			<Table
				data={store._items}
				headers={pageStrings.header}
				onAdd={onGoToCreate}
				addButtonText={pageStrings.create}
				addButtonLoading={store._loading}
				renderRow={(item) => (
					<Tr key={item.id} >
						<TableCellWithActionButtons
							onEdit={() => onGoToEdit(item.id)}
							onView={() => onGoToDetails(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>
							<Text isTruncated>{item.package.name}</Text>
						</Td>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
