import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
	Td,
	Tr,
	Text,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import ClinicOrderShelf from "../../Shelves/ClinicOrderShelf";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { showErrorToast } from "~/resources/toast";

interface IProps {
	id?: string;
	clinicOrderShelf: ClinicOrderShelf;
}

const ClinicOrders: React.FC<IProps> = (props) => {
	const { id, clinicOrderShelf } = props;
	const clinicOrderStrings = strings.clinics.details.clinicOrders;
	const modal = strings.common.modal;

	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onCancelClinicOrder = (clinicName: string, clinicOrderId: string) => {
		clinicOrderShelf.cancelClinicOrder(clinicOrderId, clinicName);
	};

	const onGoToDetailsClinicOrder = (clinicOrderId: string) => history.push(`/dashboard/clinics/${id}/detailsClinicOrder/${clinicOrderId}`);

	const openClinicOrderDialog = (clinicOrder: api.Order, isBlocked: boolean) => {
		if (isBlocked) {
			showErrorToast(clinicOrderStrings.alreadyCanceled);
		} else {
			dialog.showDialog({
				title: modal.button.cancel,
				closeOnOverlayClick: true,
				description: clinicOrderStrings.modalDescription(clinicOrder.clinicPackage.package.name),
				buttons: [
					{
						title: modal.button.cancel,
						onPress: () => onCancelClinicOrder(clinicOrder.clinicPackage.clinic.name, clinicOrder.id),
						buttonProps: { bg: "red.500" },
					},
					{
						title: strings.common.buttons.backButton,
						onPress: () => dialog.closeDialog(),
						outlined: true,
					},
				],
			});
		}
	};

	return (
		<CentralizedCard
			title={{
				text: clinicOrderStrings.tableTitle,
			}}
			isTable
		>
			<Table
				isCard
				data={clinicOrderShelf._items}
				headers={clinicOrderStrings.tableHeaders}
				renderRow={(item) => (
					<>
						<Tr key={item.id} >
							<>
								<TableCellWithActionButtons
									onView={() => onGoToDetailsClinicOrder(item.id)}
									onBlock={() => openClinicOrderDialog(item, !!(item.paymentStatus === "canceled"))}
									isBlocked={!!(item.paymentStatus === "canceled")}
								/>
								<Td>
									<Text>{item.clinicPackage.package.name}</Text>
								</Td>
							</>
						</Tr>
					</>
				)}
				loading={clinicOrderShelf._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={clinicOrderShelf.page}
				prevPage={clinicOrderShelf.previousPage}
				nextPage={clinicOrderShelf.nextPage}
				hasNextPage={clinicOrderShelf._isHaveNextPage}
			/>
		</CentralizedCard>
	);
};

export default observer(ClinicOrders);
