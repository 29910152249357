import { makeAutoObservable } from "mobx";
import {AttributeShelf, LoaderShelf, ModelShelf} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

export default class Store {

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.Clinic>;
	public balance: AttributeShelf<api.Balance | null> = new AttributeShelf(null);
	public withdrawAmount = new AttributeShelf(0);

	constructor(id: string, onBalanceError?: () => void) {
		makeAutoObservable(this);
		this.modelShelf = new ModelShelf(id, api.getClinicForAdminClinicUser);
		this.getBalance(onBalanceError);
	}

	public getBalance = async(onError?: () => void) => {
		this.loader.tryStart();
		try {
			const clinicBalance = await api.getClinicBalance();
			this.balance.setValue(clinicBalance);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
			if (onError) {
				onError();
			}
		} finally {
			this.loader.end();
		}
	}

	public withdrawEarnings = async() => {
		this.loader.tryStart();
		try {
			const transferredBalance = await api.createTransfer(this.withdrawAmount.value);
			this.balance.setValue(transferredBalance);
			showSuccessToast(strings.clinicWallet.success);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	}

}
