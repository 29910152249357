import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Users from "./Users";
import AdminUsers from "./AdminUsers";
import Clinics from "./Clinics";
import Professionals from "./Professionals";
import MedicalProcedures from "./MedicalProcedures";
import Packages from "./Packages";
import Orders from "./Orders";
import ComponentsScreen from "./ComponentsScreen";
import AdminClinicUsers from "./AdminClinicUsers";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import { MainLayout } from "../../layout";
import ClinicProfile from "./ClinicProfile";
import ClinicProfessionals from "./ClinicProfessionals";
import ClinicPackages from "./ClinicPackages";
import ClinicWallet from "~/pages/Dashboard/ClinicWallet";

const Dashboard: React.FC = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route
					exact
					path="/"
					component={Home}
				/>
				<Route
					exact
					path="/dashboard"
					component={Home}
				/>
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/clinics" component={Clinics} />
				<Route path="/dashboard/professionals" component={Professionals} />
				<Route path="/dashboard/medicalProcedures" component={MedicalProcedures} />
				<Route path="/dashboard/packages" component={Packages} />
				<Route path="/dashboard/componentsScreen" component={ComponentsScreen} />
				<Route path="/dashboard/adminClinicUsers" component={AdminClinicUsers} />
				<Route path="/dashboard/clinicProfile/:id" component={ClinicProfile} />
				<Route path="/dashboard/clinicProfessionals" component={ClinicProfessionals} />
				<Route path="/dashboard/orders" component={Orders} />
				<Route path="/dashboard/clinicPackages" component={ClinicPackages} />
				<Route path="/dashboard/clinicWallet/:id" component={ClinicWallet} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
