import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	HStack,
	useDisclosure,
	VStack,
	Text,
} from "@chakra-ui/react";
import {
	AutoCompleteModal,
	CentralizedCard,
	DatePicker,
	DetailsRow,
	Loading,
	TextInput,
	TimePicker,
} from "~/components";
import strings from "~/resources/strings";
import { useDebounce } from "~/hooks/useDebounce";
import Store from "./store";
import api from "../../../../resources/api";
import format from "../../../../resources/format";

interface IParams {
	id: string;
}

const CreateOrEdit: React.FC = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const debounce = useDebounce();
	const pageStrings = strings.orders.edit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSubmit = () => {
		store.createOrEditUser(onSuccess);
	};

	const onSelectProfessional = (procedure: api.Professional) => {
		store.professional.setValue(procedure);
		onClose();
	};

	const closedStatus = [api.OrderPaymentStatus.finished, api.OrderPaymentStatus.canceled];

	const sendDisable = [...closedStatus, api.OrderPaymentStatus.pending];

	return (
		store.modelShelf._initialLoader.isLoading ? (<Loading />): (
			<CentralizedCard
				title={{
					text: pageStrings.title,
				}}
				button={!sendDisable.includes(store.modelShelf._model.paymentStatus) ? (
					<Button
						minW={{ base: "100%", md: 280}}
						size="lg"
						mt={10}
						isLoading={store.loader.isLoading}
						onClick={onSubmit}
					>
						{commonStrings.send}
					</Button>
				) : undefined}
			>
				<>
					<DetailsRow
						label={pageStrings.fields.user}
						value={store.modelShelf._model.user.name}
					/>
					<DetailsRow
						label={commonStrings.fields.phone}
						value={store.modelShelf._model.user.phone}
					/>
					<DetailsRow
						label={pageStrings.fields.service}
						value={store.modelShelf._model.clinicPackage.package.name}
					/>
					<DetailsRow
						label={pageStrings.fields.clinic}
						value={store.modelShelf._model.clinicPackage.clinic.name}
					/>
					{!closedStatus.includes(store.modelShelf._model.paymentStatus) ? (
						<TextInput
							labelText={pageStrings.fields.professional}
							onClick={onOpen}
							labelProps={{ fontWeight: "bold" }}
							value={store.professional.value?.name || ""}
							isReadOnly
						/>
					):(
						<DetailsRow
							label={pageStrings.fields.professional}
							value={store.modelShelf._model.professional?.name || ""}
						/>
					)}
					<AutoCompleteModal
						isOpen={isOpen}
						onClose={onClose}
						header={
							<TextInput
								placeholder={pageStrings.fields.professionalPlaceholder}
								my={2}
								onKeyUp={(e) => {
									const input = e.target as HTMLInputElement;
									debounce.clearTimer();
									debounce.setTimer(setTimeout(() => store.searchProfessionals.setValue(input.value), 500));
								}}
							/>
						}
						listProps={{
							data: store.autoCompleteProfessionals.items,
							loading: store.autoCompleteProfessionals.loader.isLoading,
							renderItem: (item) => (
								<Text
									key={item.id}
									onClick={() => onSelectProfessional(item)}
									cursor="pointer"
								>
									{item.name}
								</Text>
							),
							paginantionProps: {
								currentPage: store.autoCompleteProfessionals.page,
								nextPage: store.autoCompleteProfessionals.nextPage,
								prevPage: store.autoCompleteProfessionals.previousPage,
								hasNextPage: store.autoCompleteProfessionals.hasNextPage,
							},
						}}
					/>
					<HStack spacing={2} alignItems="start">
						<VStack w="100%" spacing={3}>
							<DetailsRow
								label={commonStrings.fields.suggestedDate}
								value={
									store.modelShelf._model.suggestedDates.length > 0 ?
										format.date(store.modelShelf._model.suggestedDates[0]) : commonStrings.mask.date
								}
							/>
							<DetailsRow
								label=""
								value={
									store.modelShelf._model.suggestedDates.length > 1 ?
										format.date(store.modelShelf._model.suggestedDates[1]) : commonStrings.mask.date
								}
							/>
							<DetailsRow
								label=""
								value={
									store.modelShelf._model.suggestedDates.length > 2 ?
										format.date(store.modelShelf._model.suggestedDates[2]) : commonStrings.mask.date
								}
							/>
						</VStack>
						<VStack w="100%" spacing={3}>
							<DetailsRow
								label={commonStrings.fields.suggestedHour}
								value={
									store.modelShelf._model.suggestedHours.length > 0 ?
										format.hour(store.modelShelf._model.suggestedHours[0]) : commonStrings.mask.time
								}
							/>
							<DetailsRow
								label=""
								value={
									store.modelShelf._model.suggestedHours.length > 1 ?
										format.hour(store.modelShelf._model.suggestedHours[1]) : commonStrings.mask.time
								}
							/>
							<DetailsRow
								label=""
								value={
									store.modelShelf._model.suggestedHours.length > 2 ?
										format.hour(store.modelShelf._model.suggestedHours[2]) : commonStrings.mask.time
								}
							/>
						</VStack>
					</HStack>
					<HStack spacing={2} alignItems="start">
						{!closedStatus.includes(store.modelShelf._model.paymentStatus) ? (
							<DatePicker
								label={pageStrings.fields.confirmedDate}
								selectedDate={store.confirmedDate.value}
								onChangeDate={store.confirmedDate.setValue}
								isDisabled={store.loader.isLoading}
								minDate={new Date()}
							/>
						):(
							<DetailsRow
								label={pageStrings.fields.confirmedDate}
								value={store.confirmedDate.value ? format.date(store.confirmedDate.value) : commonStrings.mask.date}
							/>
						)}
						{!closedStatus.includes(store.modelShelf._model.paymentStatus) ? (
							<TimePicker
								label={pageStrings.fields.confirmedHour}
								selectedDate={store.confirmedHour.value}
								onChangeDate={store.confirmedHour.setValue}
								isDisabled={store.loader.isLoading}
							/>
						):(
							<DetailsRow
								label={pageStrings.fields.confirmedHour}
								value={store.confirmedHour.value ? format.hour(store.confirmedHour.value) : commonStrings.mask.time}
							/>
						)}
					</HStack>
					<DetailsRow
						label={pageStrings.fields.status}
						value={api.translateOrderPaymentStatus(store.modelShelf._model.paymentStatus)}
					/>
					{store.modelShelf._model.paymentStatus === api.OrderPaymentStatus.pending && (
						<Button
							colorScheme="green"
							isLoading={store.loader.isLoading}
							onClick={onSubmit}
						>
							{commonStrings.confirm}
						</Button>
					)}
					{store.modelShelf._model.paymentStatus === api.OrderPaymentStatus.scheduled && (
						<Button
							colorScheme="green"
							isLoading={store.loader.isLoading}
							onClick={store.updateOrderStatus}
						>
							{commonStrings.finish}
						</Button>
					)}
					{!closedStatus.includes(store.modelShelf._model.paymentStatus) && (
						<Button
							colorScheme="red"
							isLoading={store.loader.isLoading}
							onClick={store.cancelOrder}
						>
							{commonStrings.cancel}
						</Button>
					)}
				</>
			</CentralizedCard>
		)
	);
};

export default observer(CreateOrEdit);
